import { Grid, Typography, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import React, { } from "react";
import { FILE_HOST } from "../../../../../../common/constants";
import { useSelector } from "react-redux";
import { makeStyles } from '@mui/styles';
import CommonInitials from "../../../../../../common/components/CommonInitials";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";

const useStyles = makeStyles((theme) => ({
    TitleGrid: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        justifyContent: 'center',
    },
    headingID: {
        display: 'flex',
        fontSize: '16px',
        fontWeight: '600',
        textAlign: 'justify',
        color: '#333333',
    },
    Typography: {
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
    },
    ID: {
        color: theme.palette.success.light,
        fontSize: '14px !important',
        fontStyle: 'normal !important',
        fontWeight: '400 !important',
        lineHeight: '16px !important',
    },
    BrandName: {
        fontSize: '16px !important',
        fontWeight: '700 !important',
        color: theme.palette.success.dark,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '160px',
        wordBreak: 'break-word',
        textAlign: 'left',
        marginLeft: 'auto',
    },

    Avatar: {
        padding: '10px 20px 0px 20px',
    },

    AvatarTypo: {
        paddingLeft: '16px !important',
        color: '#0775BA !important',
        fontWeight: '700 !important',
        fontSize: '20px !important',
        lineHeight: 'normal !important',
    },

}));


const EventFooter = ({ eventData }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const users = useSelector((state) => { return state.users; })
    const { users: { userData, userType, myNetworks }, form: { eventForm }, master: { connectionTypes } } = useSelector((state) => { return state; })
    const { brand_name, followers } = userData;
    const EventCreate = location.pathname.startsWith('/events/create-event')


    // const searchObject = 'dummy';
    const searchUser = 'userTyp';
    // const searchUserManager = 'summy2';
    const searchUserType = 'sea';

    // const searchObject = userType && userType?.find((data) => data?.id === eventData?.company_details.company_type_id);
    // const searchUser = userType && userType.find((data) => data.id === events?.eventData?.company_details?.user_type_id);
    // const searchUserManager = userType && userType.find((data) => data.id === eventData?.partner_details?.company_type_id);
    // const searchUserType = searchObject && users?.userType.find((data) => data.user_type === searchObject?.user_type).id;

    const searchCompanyType = connectionTypes && connectionTypes.find((data) => data.key === eventData?.company_details?.company_type)?.value
    const searchPartnerType = connectionTypes && connectionTypes.find((data) => data.key === eventData?.partner_details?.company_type)?.value
    // console.log(searchCompanyType, searchPartnerType, companyTypes)
    const admin_company_logo = userData?.data?.admin_company_logo;
    return (
        <>
            <Grid container columnSpacing={2} pb={3}>
                <Grid item xs={12} sm={eventData?.management_type_id !== 1 ? 6 : 12}>
                    <Box className={classes.TitleGrid} gap={2}>
                        <Typography sx={{ fontSize: '20px', fontWeight: '600', color: '(gray-800-dark-text, #333)' }}>
                            {eventData?.management_type_id !== 1 ? 'Sponsored By' : 'Sponsored & Implemented By'}
                        </Typography>
                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} gap={2}>
                            {eventData?.management_type_id !== "" || null && (eventData?.company_details?.logo || eventData?.company_details?.initials || admin_company_logo) ? (
                                eventData?.management_type_id !== 1 ? (
                                    (eventData?.company_details?.logo || eventData?.company_details?.initials) ? <CommonInitials name={eventData?.company_details?.initials} divStyle={{ maxHeight: '55.7px', maxWidth: eventData?.management_type_id !== 1 ? '55.7px' : '55.7px', minHeight: '55.7px', minWidth: eventData?.management_type_id !== 1 ? '55.7px' : '55.7px', }} typoStyle={{ fontSize: '24px', lineHeight: '55px' }} bgColor={eventData?.company_details?.color_code} imgStyle={{ maxHeight: '60px', maxWidth: eventData?.management_type_id !== 1 ? '55.7px' : '55.7px', objectFit: 'contain' }} imgSrc={`${eventData?.company_details?.logo}`} companyLogo={eventData?.company_details?.logo} avatar={false} /> : <Skeleton animation="wave" variant="circular" height='60px' width='60px' />
                                ) : (
                                    <CommonInitials name={eventData?.company_details?.initials} divStyle={{ maxHeight: '55.7px', maxWidth: eventData?.management_type_id !== 1 ? '55.7px' : '55.7px', minHeight: '55.7px', minWidth: eventData?.management_type_id !== 1 ? '55.7px' : '55.7px', }} typoStyle={{ fontSize: '24px', lineHeight: '55px' }} bgColor={eventData?.company_details?.color_code} imgStyle={{ maxHeight: '60px', maxWidth: eventData?.management_type_id !== 1 ? '55.7px' : '55.7px', objectFit: 'contain' }} imgSrc={`${eventData?.company_details?.logo}`} companyLogo={eventData?.company_details?.logo} avatar={false} />
                                )
                            ) : (
                                <Skeleton animation="wave" variant="circular" height='60px' width='60px' />
                            )}
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '4px' }}>
                                <Typography className={classes.BrandName}>
                                    {eventData?.management_type_id ? (
                                        eventData?.management_type_id !== 1 ? (
                                            eventData?.company_details ? eventData?.company_details?.name : <Skeleton animation="wave" variant="rounded" height='16px' width='120px' />
                                        ) : (
                                            eventData?.company_details && eventData?.company_details?.name
                                        )
                                    ) : (
                                        <Skeleton animation="wave" variant="rounded" height='16px' width='120px' />
                                    )}
                                </Typography>
                                <Typography className={classes.ID}>
                                    {eventData?.management_type_id ? (
                                        eventData?.management_type_id !== 1 ? (
                                            !eventData?.company_details?.company_type ? <Skeleton animation="wave" variant="rounded" height='14px' width='100px' /> : searchCompanyType
                                        ) : (
                                            !eventData?.company_details?.company_type ? searchCompanyType : searchCompanyType
                                        )
                                    ) : (
                                        <Skeleton animation="wave" variant="rounded" height='14px' width='100px' />
                                    )}
                                </Typography>
                                <Typography className={classes.ID}>
                                    {eventData?.management_type_id ? (
                                        eventData?.management_type_id !== 1 ? (
                                            eventData?.company_details?.followers ? `${eventData?.company_details?.followers || 0} Followers` : <Skeleton animation="wave" variant="rounded" height='14px' width='60px' />
                                        ) : (
                                            `${eventData?.company_details?.followers || followers || 0} Followers`
                                        )
                                    ) : (
                                        <Skeleton animation="wave" variant="rounded" height='14px' width='60px' />
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                {eventData?.management_type_id !== 1 && (
                    <Grid item xs={12} sm={6}>
                        <Box className={classes.TitleGrid} gap={2}>
                            <Typography sx={{ fontSize: '20px', fontWeight: '600', color: '(gray-800-dark-text, #333)' }}>Implemented By</Typography>
                            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} gap={2}>
                                {(eventData?.partner_details?.logo || eventData?.partner_details?.initials) && eventData?.management_type_id !== "" || null && eventData?.management_type_id !== 1 ? (
                                    <CommonInitials name={eventData?.partner_details?.initials} divStyle={{ maxHeight: '55.7px', maxWidth: '55.7px', minHeight: '55.7px', minWidth: '55.7px', }} typoStyle={{ fontSize: '24px', lineHeight: '50px' }} bgColor={eventData?.partner_details?.color_code} imgStyle={{ maxHeight: '60px', maxWidth: '55.7px', objectFit: 'contain' }} imgSrc={`${eventData?.partner_details?.logo}`} companyLogo={eventData?.partner_details?.logo} avatar={false} />
                                ) : (
                                    <Skeleton animation="wave" variant="circular" height='60px' width='60px' />
                                )}
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '2px' }}>
                                    <Typography className={classes.BrandName}>
                                        {eventData?.management_type_id && (eventData?.partner_details?.name) ? (
                                            eventData?.management_type_id !== 1 ? (
                                                eventData?.partner_details ? eventData?.partner_details?.name : <Skeleton animation="wave" variant="rounded" height='16px' width='120px' />
                                            ) : (
                                                eventData?.partner_details ? eventData?.partner_details?.name : brand_name
                                            )
                                        ) : (
                                            <Skeleton animation="wave" variant="rounded" height='16px' width='120px' />
                                        )}
                                    </Typography>
                                    <Typography className={classes.ID}>
                                        {eventData?.management_type_id ? (
                                            eventData?.management_type_id !== 1 ? (
                                                !eventData?.partner_details?.company_type ? <Box padding="1.5px 0px 1.5px 0px"><Skeleton animation="wave" variant="rounded" height='16px' width='100px' /></Box> : searchPartnerType
                                            ) : (
                                                !eventData?.partner_details?.company_type ? searchPartnerType : searchPartnerType
                                            )

                                        ) : (
                                            <Skeleton animation="wave" variant="rounded" height='16px' width='100px' />
                                        )}
                                    </Typography>
                                    <Typography className={classes.ID}>
                                        {eventData?.management_type_id && (eventData?.partner_details?.followers || followers) ? (
                                            eventData?.management_type_id !== 1 ? (
                                                eventData?.partner_details?.followers ? `${eventData?.partner_details?.followers || 0} Followers` : <Skeleton animation="wave" variant="rounded" height='16px' width='60px' />
                                            ) : (
                                                `${eventData?.partner_details?.followers || followers || 0} Followers`)
                                        ) : (
                                            <Skeleton animation="wave" variant="rounded" height='16px' width='60px' />
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>)}
            </Grid>
        </>
    )
}

export default EventFooter;