import CatchmentArea from '../modules/Proposal/Common/CatchmentArea';
import * as clientValidations from './validations';
export const PAGE_LIMIT = 10;
export const httpMethod = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const TOKEN_TYPE = "Bearer";

export const LOCAL_STORAGE_KEYS = {
  USER_DATA: 'userData',
}

export const FILE_HOST = "https://ginni-impact-dev-bucket.s3.ap-south-1.amazonaws.com/" //"http://devapi.ginniimpact.com/files/";

export const COMMON_MSG = {
  GENERIC_ERROR_MSG: 'Please try again. some error occured.',
  PAASWORD_CHANGED_MSG: 'Your account password has been reset successfully.',
  ACCOUNT_CREATION: 'Your account has been created succesfully.',
  TOKEN_EXPIRED: 'Your token has been expired, please login again.',
  ADDRESS_DELETED: 'Address deleted succesfully.',
  PROJECT_DELETED: 'Project deleted succesfully.',
  PROJECT_LIMIT: "Maximum 20 projects allowed.",
  WORKS_IN_ERROR: 'Please submit at least one works in',
  CONTACT_PERSON_ERROR: 'Please submit at least one contact person',
  CATCHMENT_AREA_ERROR: 'Please submit at least one Catchment Area',
  SAME_STATE: "Please don't select same state",
  PROJECT_LOCATION_ERROR: 'Please submit at least one Project location',
  EVENT_COVER_PHOTO_ERROR: 'Please upload the cover photo.',
  TABLE_ERROR: 'Please fill all the fields and make sure the table name is unique.',
  EDITOR_ERROR: 'Please fill all the fields and make sure the editor name is unique.',
  LOCATION_ERROR: 'Please submit at least one Geographic Location.',
  PROPOSAL_SEND_EMAIL: 'Please Select at least one person from network.',
  TEXT_EDITOR_LENGTH: 'Text length should be 2000 characters or less.',
  DATA_CREATED: 'Data submitted successfully.',
  OUTBOX_PROJECT: 'Please select only three past projects.',
  RFP_SEND_EMAIL: 'Please Select at least one person from network.',
  SUGGESTED_QUESTION_MSG: 'Please select or add up to 7 questions.',
  RECORD_DELETED: 'Data deleted successfully.',
  EVENT_PUBLISHED: 'Event published successfully.',
  EVENT_PUBLISHED_REPORT: 'Event report has been published successfully.',
  EVENT_CANCEL: 'Event canceled successfully.',
  TABLE_DELETED: 'Table deleted successfully.'
}
export const GOOGLE_MAPS_SCRIPT_LINK = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCyL20r6Lze5OdTNTJ_gAcfkul6iF6wBi4&libraries=places`;

export const ADDRESS_LENGTH = 10;
export const PAST_PROJECT_LENGTH = 30;
export const PROFILE_FLOW_KEYS = {
  INDIVIDUAL: 'individual',
}

export const UPLOAD_KEYS = {
  LOGO: "companyLogo",
  COVER: "coverPhoto",
  PROJECT: "pastProjects",
  COMPLIANCE: "companyCompliance",
  ANNEXURE: "annexure",
  EVENT: "socialevents",
  PROPOSAL: "proposals",
  RFP: "rfp",
};

export const UPLOAD_DATA = {
  LOGO: {
    KEY: UPLOAD_KEYS.LOGO,
    FIELD_NAME: "company_logo",
  },
  COVER_PHOTO: {
    KEY: UPLOAD_KEYS.COVER,
    FIELD_NAME: "cover_photo",
  },
  PROJECT_PHOTO: {
    KEY: UPLOAD_KEYS.PROJECT,
    FIELD_NAME: "project_photos",
  },
  PROJECT_DOC: {
    KEY: UPLOAD_KEYS.PROJECT,
    FIELD_NAME: "project_documents",
  },
  REGISTRATION_CERTIFICATE: {
    KEY: UPLOAD_KEYS.COMPLIANCE,
    FIELD_NAME: "registration_certificate",
  },
  NUMBER_12A: {
    KEY: UPLOAD_KEYS.COMPLIANCE,
    FIELD_NAME: "twelve_a_certificate",
  },
  NUMBER_80G: {
    KEY: UPLOAD_KEYS.COMPLIANCE,
    FIELD_NAME: "eight_g_certificate",
  },
  FCRA: {
    KEY: UPLOAD_KEYS.COMPLIANCE,
    FIELD_NAME: "fcra_certificate",
  },
  CSR: {
    KEY: UPLOAD_KEYS.COMPLIANCE,
    FIELD_NAME: "csr_form",
  },
  PAN: {
    KEY: UPLOAD_KEYS.COMPLIANCE,
    FIELD_NAME: "pan_copy",
  },
  GST: {
    KEY: UPLOAD_KEYS.COMPLIANCE,
    FIELD_NAME: "gst_certificate",
  },
  APPRECIATION_LETTER: {
    KEY: UPLOAD_KEYS.COMPLIANCE,
    FIELD_NAME: "appreciation_letter",
  },
  CERTIFICATION: {
    KEY: UPLOAD_KEYS.COMPLIANCE,
    FIELD_NAME: "certificate_copy",
  },
  CAR_POLICY: {
    KEY: UPLOAD_KEYS.COMPLIANCE,
    FIELD_NAME: "car_policy_file",
  },
  MCA_REPORT: {
    KEY: UPLOAD_KEYS.COMPLIANCE,
    FIELD_NAME: "mca_report_file",
  },
  ANNEXURE: {
    KEY: UPLOAD_KEYS.ANNEXURE,
    FIELD_NAME: "annexure_file",
  },
  EVENT: {
    KEY: UPLOAD_KEYS.EVENT,
    FIELD_NAME: "cover_photo",
  },
  EVENT_ACTUAL_ONE: {
    KEY: UPLOAD_KEYS.EVENT,
    FIELD_NAME: "picture_one",
  },
  EVENT_ACTUAL_TWO: {
    KEY: UPLOAD_KEYS.EVENT,
    FIELD_NAME: "picture_two",
  },
  EVENT_ACTUAL_THREE: {
    KEY: UPLOAD_KEYS.EVENT,
    FIELD_NAME: "picture_three",
  },
  PROPOSAL_SUGGEST_DOC: {
    KEY: UPLOAD_KEYS.PROPOSAL,
    FIELD_NAME: "recomend_document",
  },
  RFP_SUGGEST_DOC: {
    KEY: UPLOAD_KEYS.RFP,
    FIELD_NAME: "document_path",
  },

  UPLOAD_PROPOSAL_DOC: {
    KEY: UPLOAD_KEYS.PROPOSAL,
    FIELD_NAME: "upload_proposal_path",
  },
  BENGAGE: {
    KEY: UPLOAD_KEYS.BENGAGE,
    FIELD_NAME: "banner",
  },
};

export const EVENT_FLOW_KEYS = {
  INDIVIDUAL: 'individual',
}
export const INDIVIDUAL_FLOW_KEYS = {
  INDIVIDUAL: 'individual',
}

export const COMPLIANCE_DOCUMENTS = [
  {
    compliance_type_id: 1,
    icon: 'CompRegIcon',
    primaryInputTitle: ' Company Registration Number',
    primaryInputName: "regNumber",
    primaryInputType: 'text',
    secondaryInputTitle: 'Company Registration Date',
    secondaryInputName: "regDate",
    secondaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: 'Company Registration Certificate',
    fileName: UPLOAD_DATA.REGISTRATION_CERTIFICATE.FIELD_NAME,
    placeholder: 'Enter Company Registration number',
    primaryValidate: [clientValidations.required, clientValidations.maxLength25, clientValidations.noSpecialCharacters, clientValidations.onlyTextandNumber],
    secondaryValidate: [clientValidations.required, clientValidations.pastAndCurrentDate, clientValidations.dateAfter1900],
    fileValidate: [clientValidations.maxSize2000],
    uploadKey: 'REGISTRATION_CERTIFICATE',
    maxLength: "25",
  },
  {
    compliance_type_id: 2,
    icon: 'Form80GIcon',
    primaryInputTitle: '80G Number',
    primaryInputName: "g80Number",
    primaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: '80G Number',
    fileName: UPLOAD_DATA.NUMBER_80G.FIELD_NAME,
    placeholder: 'Enter 80G number',
    primaryValidate: [clientValidations.required, clientValidations.maxLength25, clientValidations.noSpecialCharacters, clientValidations.onlyTextandNumber],
    fileValidate: [clientValidations.maxSize2000],
    uploadKey: 'NUMBER_80G',
    maxLength: "25",
  },
  {
    compliance_type_id: 3,
    icon: 'Form80GIcon',
    primaryInputTitle: '12-A Number',
    primaryInputName: "a12Number",
    primaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: '12-A Number',
    fileName: UPLOAD_DATA.NUMBER_12A.FIELD_NAME,
    placeholder: 'Enter 12-A Number',
    primaryValidate: [clientValidations.required, clientValidations.maxLength25, clientValidations.noSpecialCharacters, clientValidations.onlyTextandNumber],
    fileValidate: [clientValidations.maxSize2000],
    uploadKey: 'NUMBER_12A',
    maxLength: "25",
  },

  {
    compliance_type_id: 4,
    icon: 'FCRAIcon',
    primaryInputTitle: 'FCRA Number',
    primaryInputName: "fcraNumber",
    primaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: 'FCRA Number',
    fileName: UPLOAD_DATA.FCRA.FIELD_NAME,
    primaryValidate: [clientValidations.required, clientValidations.maxLength25, clientValidations.noSpecialCharacters, clientValidations.onlyTextandNumber],
    fileValidate: [clientValidations.maxSize2000],
    placeholder: 'Enter FCRA Number',
    uploadKey: 'FCRA',
    maxLength: "25",
  },
  {
    compliance_type_id: 5,
    icon: 'CSRIcon',
    primaryInputTitle: 'CSR Form-1 Number',
    primaryInputName: "csrNumber",
    primaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: 'CSR Form-1 Number',
    fileName: UPLOAD_DATA.CSR.FIELD_NAME,
    placeholder: 'Enter CSR Form-1 Number',
    primaryValidate: [clientValidations.required, clientValidations.maxCharacter25, clientValidations.noSpecialCharacters, clientValidations.onlyTextandNumber],
    fileValidate: [clientValidations.maxSize2000],
    uploadKey: 'CSR',
    maxLength: "25",
  },
  {
    compliance_type_id: 6,
    icon: 'PANIcon',
    primaryInputTitle: 'PAN Card Number',
    primaryInputName: "panNumber",
    primaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: 'PAN Card',
    fileName: UPLOAD_DATA.PAN.FIELD_NAME,
    placeholder: 'Enter PAN Number',
    primaryValidate: [clientValidations.required, clientValidations.pannumber, clientValidations.onlyUppercase],
    fileValidate: [clientValidations.maxSize2000],
    uploadKey: 'PAN',
    maxLength: "10",
  },
  {
    compliance_type_id: 7,
    icon: 'GSTIcon',
    primaryInputTitle: 'GST Number',
    primaryInputName: "gstNumber",
    primaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: 'GST Number',
    fileName: UPLOAD_DATA.GST.FIELD_NAME,
    primaryValidate: [clientValidations.required, clientValidations.gstinumber, clientValidations.onlyUppercase],
    fileValidate: [clientValidations.maxSize2000],
    placeholder: 'Enter GST Number',
    uploadKey: 'GST',
    maxLength: "25",
  },

  {
    compliance_type_id: 8,
    icon: 'AuditIcon',
    primaryInputTitle: ' Audit report',
    InputTitle: '(min 3 years)',
    primaryInputName: "certificationNumber",
    primaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: 'Certification',
    fileName: UPLOAD_DATA.CERTIFICATION.FIELD_NAME,
    primaryValidate: [clientValidations.required, clientValidations.maxLength100, clientValidations.noHtmlTags, clientValidations.onlyTextandNumber],
    fileValidate: [clientValidations.maxSize2000],
    placeholder: 'Enter title',
    uploadKey: 'CERTIFICATION',
    maxLength: "100",
  },
  // {
  //   icon: 'CertificateIcon',
  //   primaryInputTitle: 'Certification',
  //   InputTitle: '(if any)',
  //   primaryInputName: "certificationNumber",
  //   primaryInputType: 'text',
  //   fileType: '.pdf, .jpg, .jpeg or .png',
  //   fileTitle: 'Certification',
  //   fileName: UPLOAD_DATA.CERTIFICATION.FIELD_NAME,
  //   primaryValidate: [clientValidations.required, clientValidations.maxLength100, clientValidations.noHtmlTags, clientValidations.onlyTextandNumber],
  //   fileValidate: [clientValidations.maxSize2000],
  //   placeholder: 'Title',
  //   uploadKey: 'CERTIFICATION',
  //   maxLength: "100",
  // },
  {
    compliance_type_id: 9,
    icon: 'CertificateIcon',
    primaryInputTitle: 'Appreciation Letter/Certificate',
    InputTitle: '(min 3 years)',
    primaryInputName: "appreciationLetter",
    primaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: 'Appreciation Letter',
    fileName: UPLOAD_DATA.APPRECIATION_LETTER.FIELD_NAME,
    primaryValidate: [clientValidations.required, clientValidations.maxLength50, clientValidations.noHtmlTags],
    fileValidate: [clientValidations.maxSize2000],
    placeholder: 'Enter title',
    uploadKey: 'APPRECIATION_LETTER',
    maxLength: "50",
  },
];
export const COMPLIANCE_DOCUMENTS_PROFIT = [
  {
    compliance_type_id: 1,
    icon: 'CompRegIcon',
    primaryInputTitle: ' Company Registration Number',
    primaryInputName: "regNumber",
    primaryInputType: 'text',
    secondaryInputTitle: 'Company Registration Date',
    secondaryInputName: "regDate",
    secondaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: 'Company Registration Certificate',
    fileName: UPLOAD_DATA.REGISTRATION_CERTIFICATE.FIELD_NAME,
    placeholder: 'Enter Company Registration number',
    primaryValidate: [clientValidations.required, clientValidations.maxLength25, clientValidations.noSpecialCharacters, clientValidations.onlyTextandNumber],
    secondaryValidate: [clientValidations.required, clientValidations.pastAndCurrentDate, clientValidations.dateAfter1900],
    fileValidate: [clientValidations.maxSize2000],
    uploadKey: 'REGISTRATION_CERTIFICATE',
    maxLength: "25",
  },
  {
    compliance_type_id: 3,
    icon: 'Form80GIcon',
    primaryInputTitle: '12-A Number',
    primaryInputName: "a12Number",
    primaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: '12-A Number',
    fileName: UPLOAD_DATA.NUMBER_12A.FIELD_NAME,
    placeholder: 'Enter 12-A Number',
    primaryValidate: [clientValidations.required, clientValidations.maxLength25, clientValidations.noSpecialCharacters, clientValidations.onlyTextandNumber],
    fileValidate: [clientValidations.maxSize2000],
    uploadKey: 'NUMBER_12A',
    maxLength: "25",
  },
  {
    compliance_type_id: 2,
    icon: 'Form80GIcon',
    primaryInputTitle: '80G Number',
    primaryInputName: "g80Number",
    primaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: '80G Number',
    fileName: UPLOAD_DATA.NUMBER_80G.FIELD_NAME,
    placeholder: 'Enter 80G number',
    primaryValidate: [clientValidations.required, clientValidations.maxLength25, clientValidations.noSpecialCharacters, clientValidations.onlyTextandNumber],
    fileValidate: [clientValidations.maxSize2000],
    uploadKey: 'NUMBER_80G',
    maxLength: "25",
  },
  {
    compliance_type_id: 4,
    icon: 'FCRAIcon',
    primaryInputTitle: 'FCRA Number',
    primaryInputName: "fcraNumber",
    primaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: 'FCRA Number',
    fileName: UPLOAD_DATA.FCRA.FIELD_NAME,
    primaryValidate: [clientValidations.required, clientValidations.maxLength25, clientValidations.noSpecialCharacters, clientValidations.onlyTextandNumber],
    fileValidate: [clientValidations.maxSize2000],
    placeholder: 'Enter FCRA Number',
    uploadKey: 'FCRA',
    maxLength: "25",
  },
  {
    compliance_type_id: 5,
    icon: 'CSRIcon',
    primaryInputTitle: 'CSR Form-1 Number',
    primaryInputName: "csrNumber",
    primaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: 'CSR Form-1 Number',
    fileName: UPLOAD_DATA.CSR.FIELD_NAME,
    placeholder: 'Enter CSR Form-1 Number',
    primaryValidate: [clientValidations.required, clientValidations.maxCharacter25, clientValidations.noSpecialCharacters, clientValidations.onlyTextandNumber],
    fileValidate: [clientValidations.maxSize2000],
    uploadKey: 'CSR',
    maxLength: "25",
  },
  {
    compliance_type_id: 6,
    icon: 'PANIcon',
    primaryInputTitle: 'PAN Card Number',
    primaryInputName: "panNumber",
    primaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: 'PAN Card',
    fileName: UPLOAD_DATA.PAN.FIELD_NAME,
    placeholder: 'Enter PAN Number',
    primaryValidate: [clientValidations.required, clientValidations.pannumber, clientValidations.onlyUppercase],
    fileValidate: [clientValidations.maxSize2000],
    uploadKey: 'PAN',
    maxLength: "10",
  },
  {
    compliance_type_id: 7,
    icon: 'GSTIcon',
    primaryInputTitle: 'GST Number',
    primaryInputName: "gstNumber",
    primaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: 'GST Number',
    fileName: UPLOAD_DATA.GST.FIELD_NAME,
    primaryValidate: [clientValidations.required, clientValidations.gstinumber, clientValidations.onlyUppercase],
    fileValidate: [clientValidations.maxSize2000],
    placeholder: 'Enter GST Number',
    uploadKey: 'GST',
    maxLength: "25",
  },
  {
    compliance_type_id: 8,
    icon: 'AuditIcon',
    primaryInputTitle: ' Audit report',
    InputTitle: '(min 3 years)',
    primaryInputName: "certificationNumber",
    primaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: 'Certification',
    fileName: UPLOAD_DATA.CERTIFICATION.FIELD_NAME,
    primaryValidate: [clientValidations.required, clientValidations.maxLength100, clientValidations.noHtmlTags, clientValidations.onlyTextandNumber],
    fileValidate: [clientValidations.maxSize2000],
    placeholder: 'Enter title',
    uploadKey: 'CERTIFICATION',
    maxLength: "100",
  },
  {
    compliance_type_id: 9,
    icon: 'CertificateIcon',
    primaryInputTitle: 'Appreciation Letter',
    InputTitle: '(min 3 years)',
    primaryInputName: "appreciationLetter",
    primaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: 'Appreciation Letter',
    fileName: UPLOAD_DATA.APPRECIATION_LETTER.FIELD_NAME,
    primaryValidate: [clientValidations.required, clientValidations.maxLength50, clientValidations.noHtmlTags],
    fileValidate: [clientValidations.maxSize2000],
    placeholder: 'Enter title',
    uploadKey: 'APPRECIATION_LETTER',
    maxLength: "50",
  },
];
export const COMPLIANCE_DOCUMENTS_GOVT = [
  {
    compliance_type_id: 1,
    icon: 'CompRegIcon',
    primaryInputTitle: ' Company Registration Number',
    primaryInputName: "regNumber",
    primaryInputType: 'text',
    secondaryInputTitle: 'Company Registration Date',
    secondaryInputName: "regDate",
    secondaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: 'Company Registration Certificate',
    fileName: UPLOAD_DATA.REGISTRATION_CERTIFICATE.FIELD_NAME,
    placeholder: 'Enter Company Registration number',
    primaryValidate: [clientValidations.required, clientValidations.maxLength25, clientValidations.noSpecialCharacters, clientValidations.onlyTextandNumber],
    secondaryValidate: [clientValidations.required, clientValidations.pastAndCurrentDate, clientValidations.dateAfter1900],
    fileValidate: [clientValidations.maxSize2000],
    uploadKey: 'REGISTRATION_CERTIFICATE',
    maxLength: "25",
  },
  // {
  //   primaryInputTitle: '12-A Number',
  //   primaryInputName: "a12Number",
  //   primaryInputType: 'text',
  //   fileType: '.pdf, .jpg, .jpeg or .png',
  //   fileTitle: '12-A Number',
  //   fileName: UPLOAD_DATA.NUMBER_12A.FIELD_NAME,
  //   placeholder: 'Enter 12-A Number',
  //   primaryValidate: [clientValidations.maxLength25, clientValidations.noSpecialCharacters],
  //   fileValidate: [clientValidations.maxSize2000],
  //   uploadKey: 'NUMBER_12A',
  //   maxLength: "25",
  // },
  // {
  //   primaryInputTitle: '80G Number',
  //   primaryInputName: "g80Number",
  //   primaryInputType: 'text',
  //   fileType: '.pdf, .jpg, .jpeg or .png',
  //   fileTitle: '80G Number',
  //   fileName: UPLOAD_DATA.NUMBER_80G.FIELD_NAME,
  //   placeholder: 'Enter 80G number',
  //   primaryValidate: [clientValidations.maxLength25, clientValidations.noSpecialCharacters],
  //   fileValidate: [clientValidations.maxSize2000],
  //   uploadKey: 'NUMBER_80G',
  //   maxLength: "25",
  // },
  // {
  //   primaryInputTitle: 'FCRA Number',
  //   primaryInputName: "fcraNumber",
  //   primaryInputType: 'text',
  //   fileType: '.pdf, .jpg, .jpeg or .png',
  //   fileTitle: 'FCRA Number',
  //   fileName: UPLOAD_DATA.FCRA.FIELD_NAME,
  //   primaryValidate: [clientValidations.maxLength25, clientValidations.noSpecialCharacters],
  //   // fileValidate:[clientValidations.maxSize2000],
  //   placeholder: 'Enter FCRA Number',
  //   uploadKey: 'FCRA',
  //   maxLength: "25",
  // },
  // {
  //   primaryInputTitle: 'CSR Form-1 Number',
  //   primaryInputName: "csrNumber",
  //   primaryInputType: 'text',
  //   fileType: '.pdf, .jpg, .jpeg or .png',
  //   fileTitle: 'CSR Form-1 Number',
  //   fileName: UPLOAD_DATA.CSR.FIELD_NAME,
  //   placeholder: 'Enter CSR Form-1 Number',
  //   primaryValidate: [ clientValidations.maxCharacter25, clientValidations.noSpecialCharacters],
  //   fileValidate: [ clientValidations.maxSize2000],
  //   uploadKey: 'CSR',
  //   maxLength: "25",
  // },
  {
    compliance_type_id: 6,
    icon: 'PANIcon',
    primaryInputTitle: 'PAN Card Number',
    primaryInputName: "panNumber",
    primaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: 'PAN Card',
    fileName: UPLOAD_DATA.PAN.FIELD_NAME,
    placeholder: 'Enter PAN Number',
    primaryValidate: [clientValidations.required, clientValidations.pannumber, clientValidations.onlyUppercase],
    fileValidate: [clientValidations.maxSize2000],
    uploadKey: 'PAN',
    maxLength: "10",
  },
  {
    compliance_type_id: 7,
    icon: 'GSTIcon',
    primaryInputTitle: 'GST Number',
    primaryInputName: "gstNumber",
    primaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: 'GST Number',
    fileName: UPLOAD_DATA.GST.FIELD_NAME,
    primaryValidate: [clientValidations.required, clientValidations.gstinumber, clientValidations.onlyUppercase],
    fileValidate: [clientValidations.maxSize2000],
    placeholder: 'Enter GST Number',
    uploadKey: 'GST',
    maxLength: "25",
  },
  // {
  //   compliance_type_id: 8,
  //   icon: 'AuditIcon',
  //   primaryInputTitle: 'Certification',
  //   InputTitle: '(if any)',
  //   primaryInputName: "certificationNumber",
  //   primaryInputType: 'text',
  //   fileType: '.pdf, .jpg, .jpeg or .png',
  //   fileTitle: 'Certification',
  //   fileName: UPLOAD_DATA.CERTIFICATION.FIELD_NAME,
  //   primaryValidate: [clientValidations.required, clientValidations.maxLength100, clientValidations.noHtmlTags, clientValidations.onlyTextandNumber],
  //   fileValidate: [clientValidations.maxSize2000],
  //   placeholder: 'Enter title',
  //   uploadKey: 'CERTIFICATION',
  //   maxLength: "25",
  // },
  {
    compliance_type_id: 10,
    icon: 'CARIcon',
    primaryInputTitle: 'CAR policy',
    // InputTitle:'(if any)',
    primaryInputName: "carPolicy",
    primaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: 'CAR policy',
    fileName: UPLOAD_DATA.CAR_POLICY.FIELD_NAME,
    primaryValidate: [clientValidations.required, clientValidations.isValidWebsiteURL, clientValidations.maxCharacter80],
    fileValidate: [clientValidations.maxSize2000],
    placeholder: 'Website Link',
    uploadKey: 'CAR_POLICY',
    maxLength: "50",
  },
  {
    compliance_type_id: 11,
    icon: 'MCAIcon',
    primaryInputTitle: 'MCA report',
    // InputTitle:'(if any)',
    primaryInputName: "mcaReport",
    primaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: ' MCA report',
    fileName: UPLOAD_DATA.MCA_REPORT.FIELD_NAME,
    primaryValidate: [clientValidations.required, clientValidations.maxLength200, clientValidations.noHtmlTags, clientValidations.onlyTextandNumber],
    fileValidate: [clientValidations.maxSize2000],
    placeholder: 'Enter title',
    uploadKey: 'MCA_REPORT',
    maxLength: "200",
  }, 
  {
    compliance_type_id: 9,
    icon: 'CertificateIcon',
    primaryInputTitle: 'Letter Certificate',
    InputTitle: '(min 3 years)',
    primaryInputName: "appreciationLetter",
    primaryInputType: 'text',
    fileType: '.pdf, .jpg, .jpeg or .png',
    fileTitle: 'Letter Certificate',
    fileName: UPLOAD_DATA.APPRECIATION_LETTER.FIELD_NAME,
    primaryValidate: [clientValidations.required, clientValidations.maxLength50, clientValidations.noHtmlTags],
    fileValidate: [clientValidations.maxSize2000],
    placeholder: 'Enter title',
    uploadKey: 'APPRECIATION_LETTER',
    maxLength: "50",
  },
];

export const UPLOAD_FORMAT = {
  LOGO: ['.png', '.jpeg', '.jpg', '.webp', '.PNG', '.JPG', '.WEBP', '.JPEG'],
  PROJECT_PHOTO: ['.png', '.jpeg', '.jpg', '.webp', '.PNG', '.JPG', '.JPEG', '.WEBP'],
  PROJECT_DOC: ['.pdf', '.jpeg', '.jpg', '.png', '.doc', '.docx', '.PNG', '.JPG', '.JPEG', '.PDF', '.DOC', '.DOCX'],
  COMPLIANCE_DOC: ['.pdf', '.jpeg', '.jpg', '.png', '.PNG', '.JPG', '.JPEG', '.PDF'],
  EVENT: ['.png', '.PNG', '.jpeg', '.JPEG', '.JPG', '.jpg'],
  BENGAGE: ['.png', '.PNG', '.jpeg', '.JPEG', '.JPG', '.jpg'],
  PROPOSAL: ['.pdf', '.doc', '.docx', '.DOCX', '.jpeg', '.jpg', '.JPG', '.JPEG', '.PDF'],
  RFP: ['.pdf', '.doc', '.docx', '.PDF', '.DOC', '.DOCX', '.jpeg', '.JPEG', '.JPG', '.jpg'],
  UPLOAD_PROPOSAL: ['.pdf', '.PDF'],
  UPLOAD_RFP: ['.pdf', '.PDF'],
}


export const EVENT_MODE = {
  INPERSON: 1,
  ONLINE: 2,
}

export const STATUS_CODE = {
  ACTIVE: {
    CODE: 1001,
    STATUS_TEXT: 'Active'
  },
  INACTIVE: {
    CODE: 1002,
    STATUS_TEXT: 'In-Active'
  },
  INCOMPLETE: {
    CODE: 1003,
    STATUS_TEXT: 'In-Complete'
  },
  COMPLETE: {
    CODE: 1004,
    STATUS_TEXT: 'completed'
  },
  DRAFT: {
    CODE: 1005,
    STATUS_TEXT: 'draft'
  },
  RECIEVED: {
    CODE: 1006,
    STATUS_TEXT: 'Received'
  },
  SENT: {
    CODE: 1007,
    STATUS_TEXT: 'Sent'
  },
  PUBLISH: {
    CODE: 1008,
    STATUS_TEXT: 'published'
  },
  OPEN: {
    CODE: 1009,
    STATUS_TEXT: 'Open'
  },
  CLOSED: {
    CODE: 1010,
    STATUS_TEXT: 'closed'
  },
  CANCEL: {
    CODE: 1011,
    STATUS_TEXT: 'cancelled'
  },
  UPCOMING: {
    CODE: 1012,
    STATUS_TEXT: 'Upcoming'
  },
  ONGOING: {
    CODE: 1013,
    STATUS_TEXT: 'ongoing'
  },
  DELETED: {
    CODE: 1014,
    STATUS_TEXT: 'Deleted'
  },
  SAVED: {
    CODE: 1022,
    STATUS_TEXT: 'Saved'
  },
}

export const bENGAGE_SECTIONS = {
  DELEGATES: 'delegates',
  SPEAKER: 'speakers',
  PARTNER: 'sponsors',
  NOMINIE: 'awards',
}

export const DASHBOARD_SECTIONS = {
  INBOX: 'inbox',
  SENT: 'sent',
  DRAFT: 'draft',
  SAVED: 'saved',
  STARRED: 'starred',
}
export const EVENT_DASHBOARD = {
  PUBLISHED: 'published',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  DRAFTS: 'draft',
  ONGOING: 'ongoing',
  COMPANYEVENTS: 'company_events',
  OPEN: 'open',
  CLOSED: 'closed',
  EVENTSCLOSED: 'closed-events',
  MYTEAMS: 'my-teams',
  MYVOLUNTEER: 'my-volunteers',
  MYEVENTS: 'my-events',
  EVENT_DAIRY: 'event-diary',
  PARTNER: 'partner',
  VOLUNTEER: 'volunteer-managenment',
  ALLVOLUNTEER: 'all',
  PRESENTVOLUNTEER: 'present',
  ABSENTVOLUNTEER: 'absent',
  VOLUNTEERINGINVITE: 'volunteering-invite',
  INVITE: 'invite',
  CERTIFICATE: 'certificate',
  // VOLUNTEER:'volunteer'
}

export const PROPOSAL_SECTIONS = {
  BRIEF: 'proposalBrief',
  GOALS: 'goals',
  BENEFICIARIES: 'beneficiaries',
  SUSTAINIBILITY: 'sustainability',
  MILEAGE: 'mileage',
  EXPECTED_IMPACT: 'expectedImpact',
  COVER_LETTER: 'coverLetter',
  TABLE: 'table',
  TEXT_EDITOR: 'textEditor',
  SUGGESTED_BY: 'suggestedby',
  UPLOAD_PROPOSAL: "uploadPropoal"
}
export const RFP_SECTION = {
  PREFFEREDRESPONSE: 'preffered-response',
  GENERALRESPONSE: 'general-response',
}

export const PROPOSAL_DASHBOARD_MSG = {
  inbox: {
    line1: "There are no proposal request at the moment.",
    line2: "Please check back later or.",
    line3: "Create your own proposal now!",
  },
  sent: {
    line1: "There are no sent proposal at the moment.",
    line2: "Please go to draft or choose a proposal to send or",
    line3: "Create your own proposal now!",
  },
  draft: {
    line1: "There are no items in Draft at the moment.",
    line2: "Create a New Proposal Now.",
  },
  response: {
    line1: "There are no Proposal Response at the moment.",
    line2: "Please check back later.",
  }
}

export const RFP_DASHBOARD_MSG = {
  inbox: {
    line1: "There are no RFP request at the moment.",
    line2: "Please check back later or.",
    line3: "Create your own RFP now!",
  },
  sent: {
    line1: "There are no sent RFP at the moment.",
    line2: "Please go to draft or choose a RFP to send or",
    line3: "Create your own RFP now!",
  },
  draft: {
    line1: "There are no items in Draft at the moment.",
    line2: "Create a New RFP Now.",
  },
  saved: {
    line1: "There are no saved RFP's at the moment.",
    line2: "Please go to social media and choose a RFP to save",
    line3: "Create your own RFP now!",
  },
  starred: {
    line1: "There are no starred RFP's at the moment.",
    // line2: "Please go to choose a RFP to save",
    // line3: "Create your own RFP now!",
  },
}
export const offset = 10

export const RFP_RESPONSE_MSG = {
  'preffered-response': {
    line1: "There are no Preffered RFP Response at the moment.",
    line2: "Please check back later.",
    // line3: "Create your own RFP now!",
  },
  'general-response': {
    line1: "There are no General RFP Response at the moment.",
    line2: "Please check back later.",
    // line3: "Create your own RFP now!",
  },
}

export const MODULE_NAME = {
  PROPOSAL: "proposal",
  RFP: "rfp",
  EVENT: "event",
  VOLUNTEERING: "volunteering",
}

export const COUPON_CODE = "BVOKAL100";
export const LOGIN_DEVICE = 'web';

export const SEO = {
  EVENT_DASHBOARD: {
    TITLE: "Empowering Communities: Social Work Event for Positive Change",
    Description: 'Join us for a transformative social work event focused on creating positive change. Connect with like-minded professionals and gain valuable insights.'
  },
  PROPOSAL_DASHBOARD: {
    TITLE: "Enhancing Corporate Social Responsibility: Innovative Proposals",
    Description: 'Join us for a transformative social work event focused on creating positive change. Connect with like-minded professionals and gain valuable insights.'
  },
  RFP_DASHBOARD: {
    TITLE: "bvokal: Corporate Social Responsibility Request for Proposal",
    Description: 'Looking for a professional RFP for Corporate Social Responsibility? Get the best proposals from experts in the field.'
  },
  PROFILE: {
    TITLE: "Social Sector Connect: A Professional Social Platform for CSR",
    Description: 'Discover a professional social platform for CSR projects, connecting like-minded individuals and organizations. Join us today!'
  },
};

export const RFP_QUESTION_TYPE_LIST = [{
  value: "textfield",
  name: "Text Field"
},
{
  value: "multiSelect",
  name: "Multi Select"
},
{
  value: "singleSelect",
  name: "Single Select"
},
];

export const RFP_QUESTION_TYPE = {
  TEXTFIELD: "textfield",
  MULTISELECT: "multiSelect",
  SINGLESELECT: "singleSelect"
}
export const RFP_QUESTION_LENGTH = {
  multiSelect: 10,
  singleSelect: 5
}
export const USER_SELECTION_TYPE = {
  RFP: "rfp",
  VOLUNTEERING: "events",
  PROPOSAL: "proposal",
  bENGAGE: "bengage",
}
export const MAX_ALLOWED_COUNT = 10
export const EVENT_TYPE = {
  PUBLIC: 1,
  PRIVATE: 2,
}
export const MODE_EVENT = {
  INPERSON: "In-person",
  ONLINE: "Online",
}

export const organisers = [
  {
    id: 1,
    name: '1 organisers'
  },
  {
    id: 2,
    name: '2 organisers'
  },
  {
    id: 3,
    name: '3 organisers'
  },
  {
    id: 4,
    name: '4 organisers'
  },
  {
    id: 5,
    name: '5 organisers'
  },
]

export const status = [
  {
    id: 'draft',
    name: 'Draft'
  },
  {
    id: 'in-progress',
    name: 'In-Progress'
  },
  {
    id: 'closed',
    name: 'Finished'
  },
  {
    id: 'completed',
    name: 'Completed'
  },
  {
    id: 'cancelled',
    name: 'Cancelled'
  },
]
export const Confstatus = [
  {
    id: 'draft',
    name: 'Draft'
  },
  {
    id: 'upcoming',
    name: 'Upcoming'
  },
  {
    id: 'ongoing',
    name: 'Ongoing'
  },
  {
    id: 'completed',
    name: 'Completed',
  },
  {
    id: 'cancelled',
    name: 'Cancelled',
  },
]
export const sortBy = [
  {
    id: 'A-Z',
    name: 'A-Z'
  },
  {
    id: 'self_event',
    name: 'Self events'
  },
  {
    id: 'partner_event',
    name: 'Partners events'
  },
]
export const location = [
  {
    id: 'online',
    name: 'Online'
  },
  {
    id: 'in-person',
    name: 'In-Person'
  },
]
export const ETicketSortBy = [
  {
    id: 'all',
    name: 'All'
  },
  {
    id: 'delegates',
    name: 'Delegates'
  },
  {
    id: 'speakers',
    name: 'Speakers'
  },
  {
    id: 'partners',
    name: 'Partners'
  },
  {
    id:'awards',
    name:"Nominations"
  }
]
export const Eticketstatus = [
  {
    id: 'all',
    name: 'All'
  },
  {
    id: 'scanned',
    name: 'Scanned'
  },
  {
    id: 'pending',
    name: 'Pending'
  },
  
]
export const privacy = [
  {
    id: 'public',
    name: 'Public'
  },
  {
    id: 'private',
    name: 'Private'
  },
]

export const SORTBY = [
  {
    id: 'all',
    name: 'All'
  },
  {
    id: 'upcoming',
    name: 'Upcoming'
  },
  {
    id: 'in_progress',
    name: 'In-Progress'
  },
  {
    id: 'completed',
    name: 'Completed'
  },
]

export const EVENTS = [
  {
    id: 'company_events',
    name: 'Company events'
  },
  {
    id: 'my_events',
    name: 'My events'
  },
  {
    id: 'completed',
    name: 'Completed'
  }
]

export const TIME_ERROR_TEXT = "Start time should be greater than current time. Please change your schdule.";
export const DATE_ERROR_TEXT = "Start date & time should be greater than current date & time. Please change your schdule.";
export const END_TIME_ERROR_TEXT = "End time should be greater than start time.";

export const INVITE_TABS = {
  RECIEVED: 'Recieved',
  SENT: 'Sent'
};

export const SPEKAER_TABS = {
  RECIEVED: 'Received',
  ACCEPTED: 'Accepted',
  MISSED: 'Missed',
  DECLINED: 'Declined',
  SENT: 'Sent',
};

export const VOL_INVITE_TABS = {
  RECIEVED: 'Requested',
  ACCEPTED: 'Accepted'
};

export const CONNECTION_TAB_LIST = [
  {
    key: 'personal',
    value: 'Personal',
    count: 0
  },
  {
    key: 'csr',
    value: 'CSR | ESG',
    count: 0
  },
  {
    key: 'ngo',
    value: 'Non profit',
    count: 0
  },
  {
    key: 'companies',
    value: 'Companies',
    count: 0
  },
  {
    key: 'companies',
    value: 'Companies',
    count: 0
  },
  {
    key: 'academia',
    value: 'Academia',
    count: 0
  },
  {
    key: 'agency',
    value: 'Govt. Agency',
    count: 0
  },
]

export const CONNECTION_TABS = {
  CSR: 'csr',
  FOUNDATION: 'foundation',
  INDIPENDENT_NGO: 'independent_ngo',
  SECTION8: 'section8',
  PUBLIC: 'public_company',
  PRIVATE: 'private',
  AGENCY: 'agency',
  ACADEMIA: 'academia',
  COMPANIES: 'companies',
  NGO: 'ngo'
};

export const PROFILE_TABS = {
  ABOUT: 'About',
  CONTACT_INFO: 'Contact Info',
  PROJECTS: 'Projects',
  WORKS_IN: 'Works In',
  COMPLIANCES: 'Compliances',
  ANNEXURES: 'Annexures',
  CATCHMENT_AREA: 'Catchment Area',
};

export const PROFILE_TYPE = {
  COMPANY: "company",
  PERSONAL: "personal"
}

export const SINGUP_SCREEN = {
  CHOOSE: "choose",
  COMPANY_TYPE: "company_type",
  CREATE: "create"
}

export const SOCIAL_TYPE = {
  EVENT: 'Event',
  RFP: "RFP"
}
export const REACTION_LIST = {
  ALL:'all',
  LIKE: 'like',
  CLAP: 'clap',
  PRAISE: 'praise',
  LETGO: 'letgo',
  LIT: 'lit',
  HEART: 'heart',
  BULB: 'bulb',
}

export const USER_CHAT_STATUS = {
  AVAILABLE: "available",
  BUSY: "busy",
  AWAY: "away"
}
