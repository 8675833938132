import { Divider, Grid, Skeleton, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from "react-router";
import Car from "../../../../../../assets/icons/Car.svg";
import icon_facebook from '../../../../../../assets/icons/icon _facebook.svg';
import icon_bovokal from '../../../../../../assets/icons/icon_bovokal.svg';
import icon_linkedin from '../../../../../../assets/icons/icon_linkedin.svg';
import icon_mail from '../../../../../../assets/icons/icon_mail.svg';
import icon_twitter from '../../../../../../assets/icons/icon_twitter.svg';
import lang from '../../../../../../assets/icons/lang.svg';
import CommonProgress from '../../../../../../common/components/CommonProgress';
import ReduxForm from '../../../../../../common/components/ReduxForm';
import { STATUS_CODE } from '../../../../../../common/constants';
import { calculateEventDuration, calculateTimeRemaining, calculateTimeRemainingToEnd } from '../../../../../../common/utils';
import { getLanguageNameByIds, getTransportationModeNameByIds } from '../../actions';
import EventsCSS from '../EventDetailsCSS/Events.module.css';
import _ from 'lodash-es';
import { SharePopup } from "../../../Popup/SharePopup";
import { EmailShareButton, FacebookShareButton, LinkedinShareButton } from "react-share";
import { Helmet } from "react-helmet";

const EventDatePreview = ({ data, selectedTab }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();
    const shareUrl = window.location.href;
    const eventState = useSelector((state) => { return state; })
    // const { events: { eventData } } = eventState;
    const { socialMedia, master, users, form } = useSelector((state) => { return state; })
    const progress = data?.actual_volunteer ? data?.actual_volunteer : 0;
    const createEvent = location.pathname === '/events/preview'
    const previewUnq = location.pathname === `/events/create-event/${data?.gid}`
    const previewEvent = location.pathname.startsWith('/events/create-event');
    const prevEvent = location.pathname.startsWith('/events/preview');


    const [joinDisabled, setJoinDisabled] = useState(true)
    const [likeDisabled, setLikeDisabled] = useState(false)
    const [shareDisabled, setShareDisabled] = useState(false)
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const [postGid, setPostGid] = useState(false);

    const handleShareDialogOpen = (gid) => {
        setShareDialogOpen(true);
        setPostGid(gid);
    };

    const handleShareDialogClose = () => {
        setShareDialogOpen(false);
    };
    // const [metaTags, setMetaTags] = useState([]);
    // useEffect(() => {
    //     // Define meta tags
    //     const metaTags = [
    //         { property: "og:title", content: data?.name || "" },
    //         { property: "og:description", content: data?.description || "" },
    //         { property: "og:image", content: data?.cover_photo || "" },
    //         { property: "og:url", content: shareUrl || "" },
    //         { property: "og:type", content: "website" },
    //         { name: "twitter:card", content: "summary_large_image" },
    //         { name: "twitter:title", content: data?.name || "" },
    //         { name: "twitter:description", content: data?.description || "" },
    //         { name: "twitter:image", content: data?.cover_photo || "" },
    //         { name: "description", content: data?.description || "" }
    //     ];

    //     // Function to update meta tags
    //     // const updateMetaTags = (tags) => {
    //     //     tags.forEach(({ property, name, content }) => {
    //     //         let meta = document.querySelector(`meta[${property ? `property="${property}"` : `name="${name}"`}]`);
    //     //         if (meta) {
    //     //             meta.setAttribute("content", content);
    //     //             console.log(`Updating meta tag: ${property || name} = ${content}`);
    //     //         } else {
    //     //             meta = document.createElement("meta");
    //     //             if (property) {
    //     //                 meta.setAttribute("property", property);
    //     //             } else {
    //     //                 meta.setAttribute("name", name);
    //     //             }
    //     //             meta.setAttribute("content", content);
    //     //             document.head.appendChild(meta);
    //     //             console.log(`Adding meta tag: ${property || name} = ${content}`);
    //     //         }
    //     //     });
    //     // };

    //     // console.log("Setting meta tags with:", metaTags);
    //     // updateMetaTags(metaTags);
    //     setMetaTags(metaTags);
    // }, [data, shareUrl]);
    // useEffect(() => {
    //     // Update description meta tag
    //     const descriptionMetaTag = document.querySelector('meta[name="description"]');
    //     if (descriptionMetaTag) {
    //         descriptionMetaTag.setAttribute("content", data?.description || "");
    //     }

    //     // Other meta tags
    //     const metaTags = [
    //         { property: "og:title", content: data?.name || "" },
    //         { property: "og:description", content: data?.description || "" },
    //         { property: "og:image", content: data?.cover_photo || "" },
    //         { property: "og:url", content: shareUrl || "" },
    //         { property: "og:type", content: "website" },
    //         { name: "twitter:card", content: "summary_large_image" },
    //         { name: "twitter:title", content: data?.name || "" },
    //         { name: "twitter:description", content: data?.description || "" },
    //         { name: "twitter:image", content: data?.cover_photo || "" },
    //     ];

    //     // Update or add other meta tags dynamically
    //     metaTags.forEach(({ property, name, content }) => {
    //         let meta = property
    //             ? document.querySelector(`meta[property="${property}"]`)
    //             : document.querySelector(`meta[name="${name}"]`);

    //         if (meta) {
    //             meta.setAttribute("content", content);
    //         } else {
    //             meta = document.createElement("meta");
    //             if (property) {
    //                 meta.setAttribute("property", property);
    //             } else {
    //                 meta.setAttribute("name", name);
    //             }
    //             meta.setAttribute("content", content);
    //             document.head.appendChild(meta);
    //         }
    //     });
    // }, [data, shareUrl]);
    // useEffect(() => {
    //     console.log("Current document head:", document.head.innerHTML);
    // }, []);

    const handleXShare = (title) => {
        const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}`;
        window.open(twitterUrl, '_blank');
    };
    const handleLinkedInShare = () => {
        const linkedInUrl = `https://www.linkedin.com/shareArticle/?mini=true&url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(data?.name)}&summary=${encodeURIComponent(data?.description)}&source=LinkedIn`;
        window.open(linkedInUrl, '_blank');
    };
    const handleFacebookShare = (title) => {
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
        window.open(facebookUrl, '_blank');
    }
    useEffect(() => {
        if (data?.is_join === true) {
            setJoinDisabled(false);
        } else {
            setJoinDisabled(true);
        }
    }, [data?.is_join]);

    const startTime = data?.start_date;
    const EventstartDate = !_.isEmpty(data?.event_schedule) && data?.event_schedule[0]?.event_date;
    const EventstartTime = !_.isEmpty(data?.event_schedule) && data?.event_schedule[0]?.start_time
    const combinedDateTime = `${EventstartDate} ${EventstartTime}`
    const EventCreate = location.pathname.startsWith('/events/create-event')
    const EventPreview = location.pathname.startsWith('/events/preview')

    const endTime = data?.end_date
    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining(combinedDateTime));
    const [timeEndRemaining, setTimeEndRemaining] = useState(calculateTimeRemainingToEnd(endTime));
    const { days, hours, minutes, seconds } = calculateEventDuration(startTime, endTime);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining(combinedDateTime));
            setTimeEndRemaining(calculateTimeRemainingToEnd(endTime));
        }, 1000);

        return () => clearInterval(interval);
    }, [combinedDateTime, endTime]);

    useEffect(() => {
        const createEvent = location.pathname === '/events/preview' || location.pathname === `/events/preview/${data?.unique_id || params?.uniqueId}`
        const draftEvent = location.pathname.startsWith('/events/preview/draft')

        if (createEvent) {
            setLikeDisabled(true);
            setShareDisabled(true);
        } else if (draftEvent) {
            setLikeDisabled(true);
            setShareDisabled(true);
        }
    }, [])

    return (
        <>
            {/* <Helmet>
            <title>{data?.name}</title>
                {metaTags.map((tag, index) => {
                    const key = tag.property ? `property:${tag.property}` : `name:${tag.name}`;
                    return tag.property ? (
                        <meta key={key} property={tag.property} content={tag.content} />
                    ) : (
                        <meta key={key} name={tag.name} content={tag.content} />
                    );

                })}
                <h1>{data?.name}</h1> <p>{data?.name}</p>
            </Helmet> */}
            <ReduxForm>
                <Grid container columnSpacing={2} sx={{ alignItems: 'stretch', mt: 0 }}>
                    <Grid item xs={12} lg={4} alignSelf='stretch'>
                        <Box className={`${EventsCSS.eventDateDisplay} ${EventsCSS.f} ${EventsCSS.fd_column}`} p={2} rowGap={EventCreate ? 1 : "9.9px"}>
                            <Box className={`${EventsCSS.f} ${EventsCSS.jc_space_between}`}>
                                {!EventCreate && !EventPreview && (
                                    <>
                                        <Box className={EventsCSS.f}>
                                            <Typography className={EventsCSS.eventDateTypo}>Volunteers Required:</Typography>&nbsp;
                                            <Typography className={EventsCSS.eventDateTypo}>
                                                {data?.volunteers_count ? (
                                                    data.volunteers_count
                                                ) : (
                                                    <>
                                                        {/* This one is static just for UI perspective and can be removed */}
                                                        {/* 500 */}
                                                        {/* This one is static just for UI perspective and can be removed */}

                                                        {/* Below Commented Section Should be uncommented */}
                                                        <Skeleton animation="wave" variant="rounded" height='1.2rem' width='1.5rem' />
                                                    </>

                                                )}
                                            </Typography>
                                        </Box>
                                        <Box className={EventsCSS.f}>
                                            {data?.volunteers_count ? (
                                                <>
                                                    <Typography className={EventsCSS.eventDateTypo}>{progress} /</Typography>
                                                    <Typography className={EventsCSS.eventDateTypo}>{data.volunteers_count}</Typography>
                                                </>
                                            ) : (
                                                <>
                                                    {/* This one is static just for UI perspective and can be removed */}
                                                    {/* <Typography className={EventsCSS.eventDateTypo}>350 /</Typography>
                                                    <Typography className={EventsCSS.eventDateTypo}>500</Typography> */}
                                                    {/* This one is static just for UI perspective and can be removed */}

                                                    {/* Below Commented Section Should be uncommented */}
                                                    <Skeleton animation="wave" variant="rounded" height='1.2rem' width='3rem' />
                                                </>

                                            )}
                                        </Box>
                                    </>)}
                                {(EventCreate || EventPreview) && (
                                    <>
                                        <Box className={EventsCSS.f}>
                                            {EventPreview && (
                                                <>
                                                    <Typography className={EventsCSS.eventDateTypo2}>Volunteers Required :</Typography> &nbsp;
                                                </>
                                            )}
                                            {EventCreate && (
                                                <>
                                                    <Typography className={EventsCSS.eventDateTypo2}> Vol. Req. :</Typography> &nbsp;
                                                </>
                                            )}
                                            <Typography className={EventsCSS.eventDateTypo}>
                                                {data?.volunteers_count ? (
                                                    <Box className={EventsCSS.f}>
                                                        <Typography className={EventsCSS.eventDateTypo}>{progress}/</Typography>
                                                        <Typography className={EventsCSS.eventDateTypo}>{data.volunteers_count}</Typography>
                                                    </Box>
                                                ) : (
                                                    <Skeleton animation="wave" variant="rounded" height='1.2rem' width='1.5rem' />
                                                )}
                                            </Typography>
                                        </Box>
                                        <Box className={`${EventsCSS.f} ${EventsCSS.ai_center}`}>
                                            {data?.volunteers_count ? (
                                                <>
                                                    <Typography className={EventsCSS.eventDateTypo3}>Request:</Typography>&nbsp;
                                                    <Typography className={EventsCSS.eventDateTypo3}>0</Typography>
                                                </>
                                            ) : (
                                                <Skeleton animation="wave" variant="rounded" height='1.2rem' width='3rem' />
                                            )}
                                        </Box>
                                    </>)}
                            </Box>
                            <Box sx={{ height: '8px' }}>
                                {data?.volunteers_count ? (
                                    <CommonProgress max={data?.volunteers_count} min={0} value={progress} />
                                ) : (
                                    <>
                                        {/* This one is static just for UI perspective and can be removed */}
                                        {/* <CommonProgress max='500' min={0} value='350' /> */}
                                        {/* This one is static just for UI perspective and can be removed */}

                                        {/* Below Commented Section Should be uncommented */}
                                        <Skeleton animation="wave" variant="rectangular" height='0.4rem' width='100%' />
                                    </>
                                )}
                            </Box>

                            <Divider orientation="horizontal" flexItem />
                            <Box className={EventsCSS.f} gap={EventCreate ? 2 : 4}>
                                <Tooltip title="Volunteer Transportation" placement="bottom" slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [4, -10], }, },], }, tooltip: { style: { backgroundColor: 'black', fontSize: '14px', lineHeight: '21px', fontWeight: 500 } } }} >
                                    <Box className={`${EventsCSS.f} ${EventsCSS.ai_center}`} gap={1}>
                                        <img src={Car} />
                                        <Typography className={EventsCSS.eventDateTypo4}>
                                            {data?.event_mode_id ? (
                                                data?.event_mode_id ? (
                                                    data?.event_mode_id === 2 ? "NA" : data?.transportation_mode_id ? dispatch(getTransportationModeNameByIds(data?.transportation_mode_id)) : <Skeleton animation="wave" variant="rounded" height='1.2rem' width='4rem' />
                                                ) : (
                                                    <Skeleton animation="wave" variant="rounded" height='1.2rem' width='4rem' />
                                                )
                                            ) : (
                                                <>
                                                    {/* This one is static just for UI perspective and can be removed */}
                                                    {/* Company */}
                                                    {/* This one is static just for UI perspective and can be removed */}

                                                    {/* Below Commented Section Should be uncommented */}
                                                    <Box paddingBottom="12px">
                                                        <Skeleton animation="wave" variant="rounded" height='0.9rem' width='4rem' />
                                                    </Box>
                                                </>
                                            )}

                                        </Typography>
                                    </Box>
                                </Tooltip>
                                <Tooltip title="Volunteer Languages" placement="bottom" slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [4, -10], }, },], }, tooltip: { style: { backgroundColor: 'black', fontSize: '14px', lineHeight: '21px', fontWeight: 500 } } }} >
                                    <Box className={`${EventsCSS.f} ${EventsCSS.ai_center}`} gap={1}>
                                        <img src={lang} />
                                        <Typography className={EventsCSS.eventDateTypo5} sx={{ textOverflow: previewEvent ? 'ellipsis' : 'clip', maxWidth: previewEvent ? '125px' : '400px' }} title={previewEvent ? dispatch(getLanguageNameByIds(data?.volunteer_languages)) : null}>
                                            {data?.volunteer_languages && data?.volunteer_languages.length > 0 ? (
                                                dispatch(getLanguageNameByIds(data?.volunteer_languages))
                                            ) : (
                                                <>
                                                    {/* This one is static just for UI perspective and can be removed */}
                                                    {/* Hindi, English */}
                                                    {/* This one is static just for UI perspective and can be removed */}

                                                    {/* Below Commented Section Should be uncommented */}
                                                    <Box paddingBottom="12px">
                                                        <Skeleton animation="wave" variant="rounded" height='0.9rem' width='4rem' />
                                                    </Box>
                                                </>

                                            )}
                                        </Typography>
                                    </Box>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Grid>
                    {/* {(selectedTab === STATUS_CODE.DRAFT.CODE || createEvent || previewUnq || prevEvent || previewEvent) && (
                        <Grid item xs={12} lg={4} alignSelf='stretch'>
                            <Box className={EventsCSS.eventDateDisplay} p={2}>
                                <Box className={EventsCSS.eventDateBox}>
                                    <Typography className={EventsCSS.eventDateTypo6}>Event Starts In :</Typography>&nbsp;
                                </Box>
                                <Box className={EventsCSS.eventDateBox2}>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTypo7} sx={{ fontSize: EventCreate ? "14px" : '16px' }}>
                                            DD
                                        </Typography>
                                        <Typography className={EventsCSS.eventDateTypo8} sx={{ fontSize: EventCreate ? "14px !important" : '16px !important' }}>Days</Typography>
                                    </Box>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTypo7} sx={{ fontSize: EventCreate ? "14px" : '16px' }}>
                                            HH
                                        </Typography>
                                        <Typography className={EventsCSS.eventDateTypo8} sx={{ fontSize: EventCreate ? "14px !important" : '16px !important' }}>Hours</Typography>
                                    </Box>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTypo7} sx={{ fontSize: EventCreate ? "14px" : '16px' }}>
                                            MM
                                        </Typography>
                                        <Typography className={EventsCSS.eventDateTypo8} sx={{ fontSize: EventCreate ? "14px !important" : '16px !important' }}>Minutes</Typography>
                                    </Box>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTypo7} sx={{ fontSize: EventCreate ? "14px" : '16px' }}>
                                            SS
                                        </Typography>
                                        <Typography className={EventsCSS.eventDateTypo8} sx={{ fontSize: EventCreate ? "14px !important" : '16px !important' }} >Second</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>)} */}
                    {(data?.status_code === STATUS_CODE.PUBLISH.CODE) && (
                        <Grid item xs={12} lg={4} alignSelf='stretch'>
                            <Box className={EventsCSS.eventDateDisplay} p={2}>
                                <Box className={EventsCSS.eventDateBox}>
                                    <Typography className={EventsCSS.eventDateTypo6}>Event Starts In :</Typography>&nbsp;
                                </Box>
                                <Box className={EventsCSS.eventDateBox2}>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTypo9}>
                                            {timeRemaining.days}
                                        </Typography>
                                        <Typography className={EventsCSS.eventDateTime}>Days</Typography>
                                    </Box>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTypo9}>
                                            {timeRemaining.hours}
                                        </Typography>
                                        <Typography className={EventsCSS.eventDateTime}>Hours</Typography>
                                    </Box>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTypo9}>
                                            {timeRemaining.minutes}
                                        </Typography>
                                        <Typography className={EventsCSS.eventDateTime}>Minutes</Typography>
                                    </Box>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTypo9}>
                                            {timeRemaining.seconds}
                                        </Typography>
                                        <Typography className={EventsCSS.eventDateTime} >Second</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>)}
                    {data?.status_code === STATUS_CODE.ONGOING.CODE && (
                        <Grid item xs={12} lg={4} alignSelf='stretch'>
                            <Box className={EventsCSS.eventDateDisplay} p={2}>
                                <Box className={EventsCSS.eventDateBox}>
                                    <Typography className={EventsCSS.eventDateTypo6}>Event Ends In :</Typography>&nbsp;
                                </Box>
                                <Box className={EventsCSS.eventDateBox2}>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTimeValue}>{timeEndRemaining.days}</Typography>
                                        <Typography className={EventsCSS.eventDateTime}>Days</Typography>
                                    </Box>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTimeValue}>{timeEndRemaining.hours}</Typography>
                                        <Typography className={EventsCSS.eventDateTime}>Hours</Typography>
                                    </Box>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTimeValue}>{timeEndRemaining.minutes}</Typography>
                                        <Typography className={EventsCSS.eventDateTime}>Minutes</Typography>
                                    </Box>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTypo9}>{timeEndRemaining.seconds}</Typography>
                                        <Typography className={EventsCSS.eventDateTime} >Second</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>)}
                    {(data?.status_code === STATUS_CODE.CLOSED.CODE || data?.status_code === STATUS_CODE.COMPLETE.CODE) && (
                        <Grid item xs={12} lg={4} alignSelf='stretch'>
                            <Box className={EventsCSS.eventDateDisplay} p={2}>
                                <Box className={EventsCSS.eventDateBox}>
                                    <Typography className={EventsCSS.eventDateTypo6}>Event Duration :</Typography>&nbsp;
                                </Box>
                                <Box className={EventsCSS.eventDateBox2}>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTimeValue}>{days}</Typography>
                                        <Typography className={EventsCSS.eventDateTime}>Days</Typography>
                                    </Box>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTimeValue}>{hours}</Typography>
                                        <Typography className={EventsCSS.eventDateTime}>Hours</Typography>
                                    </Box>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTimeValue}>{minutes}</Typography>
                                        <Typography className={EventsCSS.eventDateTime}>Minutes</Typography>
                                    </Box>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTypo9}>{seconds}</Typography>
                                        <Typography className={EventsCSS.eventDateTime} >Second</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>)}
                    {data?.status_code === STATUS_CODE.CANCEL.CODE && (
                        <Grid item xs={12} lg={4} alignSelf='stretch'>
                            <Box className={EventsCSS.eventDateDisplay} p={2}>
                                <Box className={EventsCSS.eventDateBox}>
                                    <Typography className={EventsCSS.eventDateTypo6}>Event Has Cancelled :</Typography>&nbsp;
                                </Box>
                                <Box className={EventsCSS.eventDateBox2}>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTimeValue}>00</Typography>
                                        <Typography className={EventsCSS.eventDateTime}>Days</Typography>
                                    </Box>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTimeValue}>00</Typography>
                                        <Typography className={EventsCSS.eventDateTime}>Hours</Typography>
                                    </Box>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTimeValue}>00</Typography>
                                        <Typography className={EventsCSS.eventDateTime}>Minutes</Typography>
                                    </Box>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTypo9}>00</Typography>
                                        <Typography className={EventsCSS.eventDateTime} >Second</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>)}

                    {/* This one is static just for UI perspective and can be removed */}
                    {/* {selectedTab === undefined && (
                        <Grid item xs={12} lg={4} alignSelf='stretch'>
                            <Box className={EventsCSS.eventDateDisplay} p={2}>
                                <Box className={EventsCSS.eventDateBox}>
                                    <Typography className={EventsCSS.eventDateTypo6}>Event Starts In :</Typography>
                                </Box>
                                <Box className={EventsCSS.eventDateBox2}>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTimeValue}>18</Typography>
                                        <Typography className={EventsCSS.eventDateTime}>Days</Typography>
                                    </Box>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTimeValue}>10</Typography>
                                        <Typography className={EventsCSS.eventDateTime}>Hours</Typography>
                                    </Box>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTimeValue}>15</Typography>
                                        <Typography className={EventsCSS.eventDateTime}>Minutes</Typography>
                                    </Box>
                                    <Box className={EventsCSS.eventDateBox3}>
                                        <Typography className={EventsCSS.eventDateTypo9}>30</Typography>
                                        <Typography className={EventsCSS.eventDateTime} >Second</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>)} */}
                    {/* This one is static just for UI perspective and can be removed */}

                    <Grid item xs={12} lg={4}>
                        <Box className={EventsCSS.eventDateDisplay} p={2} alignSelf='stretch'>
                            <Box className={`${EventsCSS.f} ${EventsCSS.jc_center}`} sx={{ paddingBottom: EventCreate ? "22px" : '16px' }}>
                                <Typography className={EventsCSS.eventDateTypo6}>Share this event</Typography>&nbsp;
                            </Box>
                            <Box className={EventsCSS.eventDateDisplayIcon} >
                                {data.management_type_id ? (
                                    <>
                                        <img src={icon_bovokal} style={{ width: 34, height: 34, cursor: 'pointer' }} onClick={() => handleShareDialogOpen(data?.gid)} />
                                        {/* <FacebookShareButton url={shareUrl} quote={data?.name} hashtag="#example" image={data?.cover_photo}> */}
                                        <Box onClick={handleFacebookShare}>
                                            <img src={icon_facebook} style={{ width: 34, height: 34, cursor: 'pointer' }} />
                                        </Box>
                                        {/* </FacebookShareButton> */}
                                        <img src={icon_twitter} style={{ width: 34, height: 34, cursor: 'pointer' }} onClick={() => handleXShare(data?.name)} />
                                        {/* <LinkedinShareButton url={shareUrl} title={data?.name} summary={data?.description} source="LinkedIn" image={data?.cover_photo}> */}
                                        {/* </LinkedinShareButton> */}

                                        <Box onClick={handleLinkedInShare}>
                                            <img src={icon_linkedin} style={{ width: 34, height: 34, cursor: 'pointer' }} />
                                        </Box>

                                        {/* <a
                                            href={`https://www.linkedin.com/shareArticle/?mini=true&url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(data?.name)}&summary=${encodeURIComponent(data?.description)}&source=LinkedIn`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src={icon_linkedin} style={{ width: 34, height: 34, cursor: 'pointer' }} />
                                        </a> */}

                                        {/* <EmailShareButton url={shareUrl} subject={data?.name} body={data?.description}>
                                            <img src={icon_mail} style={{ width: 34, height: 34, cursor: 'pointer' }} />
                                        </EmailShareButton> */}
                                    </>
                                ) : (
                                    <>
                                        {/* This one is static just for UI perspective and can be removed */}
                                        {/* <img src={icon_bovokal} style={{ width: 34, height: 34 }} />
                                        <img src={icon_facebook} style={{ width: 34, height: 34 }} />
                                        <img src={icon_twitter} style={{ width: 34, height: 34 }} />
                                        <img src={icon_linkedin} style={{ width: 34, height: 34 }} />
                                        <img src={icon_mail} style={{ width: 34, height: 34 }} /> */}
                                        {/* This one is static just for UI perspective and can be removed */}

                                        {/* Below Commented Section Should be uncommented */}
                                        <Skeleton variant="circular" width={34} height={34} animation="wave" />
                                        <Skeleton variant="circular" width={34} height={34} animation="wave" />
                                        <Skeleton variant="circular" width={34} height={34} animation="wave" />
                                        <Skeleton variant="circular" width={34} height={34} animation="wave" />
                                        <Skeleton variant="circular" width={34} height={34} animation="wave" />
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </ReduxForm >
            {shareDialogOpen && <SharePopup
                open={shareDialogOpen}
                onClose={handleShareDialogClose}
                Title="Share to"
                postId={postGid}
                // post={post}
                eventData={data}
                master={master}
                form={form}
                user={users}
                isEvent={true}
            />}
        </>
    )
}

export default EventDatePreview;