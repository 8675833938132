import { reset } from "redux-form";
import {
  setFilterPreferenceData,
  setGlimpse,
  setDetails,
  addLocation,
  addScreeningQuestions,
  addRFPDistrict,
  setLocationFlag,
  updateLocation,
  addRfpUploadDoc,
  setRFPList,
  setDashboardTab,
  setRFPData,
  setRFPResponse,
  setPreviewData,
  setQuesAnswerResp,
  setUniqueId,
  setResponseFlag,
  setSideArr,
  setCustomAdded,
  setTotalQuestions,
  setUploadTab,
  setUpload,
  setLastSubmissionDate,
  setResponseList,
  setAssigneeList,
  setAssigneeComments,
  setUserAssigned,
  setGlimpseFormData,
  setGid,
  setSelectedSampleQuestion,
  setRfpCreatedAt,
  setDashboardInsight,
  setViewResponses,
  setIsRFPdataLoading,
} from "../../store/slices/RfpSlice";
import {
  ADD_DETAILS_FRP,
  CREATE_FRP,
  DISTRICT_URL,
  GET_RFP_LIST,
  GET_SCREENING_QUESTIONS,
  RFP_INSIGHT,
  SEND_RFP,
  STATE_URL,
  SUBMIT_FILTER_PREFERENCE,
  THEMATIC_AREAS,
  UPLOAD_RFP_FILE,
  GET_RFP_PREVIEW_BY_ID,
  RFP_RESPONSE_BY_ID,
  MAKE_FAVROUTE_RFP,
  DELETE_RFP,
  DELETE_FILE_RFP,
  RATING_AND_COMMENT,
  MARK_READ_RFP,
  SAVE_RFP_RESPONSE,
  UPDATE_FRP,
  RFP_DATE_UPDATE,
  RFP_RESPONSE_LIST,
  RFP_RESPONSE_DETAILS,
  RFP_NETWORKS,
  REQUEST_PROPOSAL,
  DELETE_SAVED_RFP,
  PUBLISH_RFP,
  GET_ASSIGNEE_LIST,
  RFP_ASSIGN_USER,
  RFP_UNASSIGN_USER,
  RFP_ASSIGN_TO_ME,
  RFP_UNASSIGN_USER_COMMENTS,
  RFP_GLIMPSE,
  RFP_DASHBOARD_LIST,
  RFP_ASSIGNEE_LIST_NEW,
  RFP_PREVIEW_SHOW,
  ASSIGN_USER_NEW,
  MARK_FAVOURITE_NEW,
  RFP_COMMON_URL,
  UNASSIGN_USER_NEW,
  ASSIGNEE_COMMENTS,
  RESPONSE_PREVIEW,
  RFP_DASHBOARD_INSIGHTS,
  RFP_CHILD_RESPONSES,
} from "../../http/apiUrls";
import {
  httpMethod,
  COMMON_MSG,
  STATUS_CODE,
  DASHBOARD_SECTIONS,
  PAGE_LIMIT,
  FILE_HOST,
} from "../../common/constants";
import { fetchAPI, getAllCountry, getAllState, getConnectionTypes, getLanguage, getNotificationCount, getPostTypes, getRfpQuestions, getThematicAreas, resetAllForms } from "../../common/actions";
import { setSnackbar } from "../../store/slices/MessageSlice";
import {
  addThematicAreas,
  addUserState,
  addUserDistrict,
  setIsLoad,
} from "../../store/slices/MasterSlice";
import { encodeHTMLEntities } from "../../common/utils";
import { setMyNetWorks } from "../../store/slices/UserSlice";

import {
  setCatchmentFlag,
  updateCatchmentAreas,
  addCatchmentAreas,
} from '../../store/slices/ProposalSlice';
import { getBeneficiaryTypes, getSchedule7 } from "../Proposal/actions";
import { getCompanyType } from "../Registration/actions";
import dayjs from "dayjs";

export const getMasterData = () => (dispatch, getState) => {
  dispatch(getAllState());
  dispatch(getRfpQuestions());
  dispatch(getAllDistrict());
  dispatch(getAllCountry());
  dispatch(getBeneficiaryTypes());
  dispatch(getSchedule7());
  dispatch(getLanguage());
  dispatch(getThematicAreas());
  dispatch(getCompanyType());
  dispatch(getConnectionTypes());
  dispatch(getPostTypes());
}

export const sumbitRfpGlimpse =
  (data, selectedTab, path, navigate) => (dispatch, getState) => {
    // console.log('submigt glimplse calling');
    const { rfp: { uniqueId, uploadTab } } = getState();
    const dataToPost = {
      title: data.title,
      budget: data.budget,
      submissionDate: data.submissionDate.split("/").reverse().join("/"),
      tenureMonths: data.tenureMonths,
      upload_rfp: uploadTab ? "Y" : "N",
      // show_budget: data.show_budget === "1" ? "true" : "false"
      //  show_budget: false
    };

    let apiURL = CREATE_FRP;
    if (uniqueId) { // for update glimpse
      apiURL = `${UPDATE_FRP}/${uniqueId}`;
    }
    dispatch(fetchAPI(apiURL, httpMethod.POST, dataToPost)).then(
      (response) => {
        if (response.success) {
          const show_budgetString = response.data.show_budget.toString();
          dispatch(setGlimpse({ ...response.data, show_budget: show_budgetString }));
          // dispatch(setGlimpse(response.data));
          //console.log('sd', response.data)
          dispatch(setUniqueId(response.data.uniqueId));
          uploadTab ? navigate(`/rfp/new/upload/${response.data.uniqueId}`) : navigate(`/rfp/new/doc/${response.data.uniqueId}`)
          dispatch(
            setSnackbar({
              flag: true,
              message: COMMON_MSG.DATA_CREATED,
              type: "success",
            })
          );
          dispatch(setSideArr(selectedTab));
        } else if (response.error) {
          dispatch(
            setSnackbar({
              flag: true,
              message: response.error.errorMsg,
              type: "error",
            })
          );
        }
      }
    );
  };

export const glimpseSubmit = (data, setTabValue, navigate) => (dispatch, getState) => {
  const { rfp: { gid, locations, selectedSampleQuestion, saveRfpData }, form } = getState();
  if (locations.length < 1) {
    dispatch(setSnackbar({ flag: true, message: COMMON_MSG.LOCATION_ERROR, type: "error", }));
    return;
  }
  if (!data?.customQuestion || data.customQuestion.length < 1) {
    dispatch(setSnackbar({ flag: true, message: "Please Add Questions to Proceed", type: "error", }));
    return;
  }
  if (!data?.project_Tenure_dropdown || data.project_Tenure_dropdown === '') {
    dispatch(setSnackbar({ flag: true, message: "Please select a project tenure duration", type: "error", }));
    return;
  }

  const getShowBudgetValue = (budgetPrivate) => {
    if (typeof budgetPrivate === 'object' && budgetPrivate !== null) {
      if (budgetPrivate.hasOwnProperty('target') && budgetPrivate.target.hasOwnProperty('value')) {
        return budgetPrivate.target.value;
      } else if (budgetPrivate.hasOwnProperty('value')) {
        return budgetPrivate.value;
      }
    }
    return false;
  };
  const textEditor = form?.RfpDetailsForm?.values?.text_editor && encodeHTMLEntities(form?.RfpDetailsForm?.values?.text_editor);
  const editorValue = encodeHTMLEntities(saveRfpData?.body);
  const dataToPost = {
    title: data.title,
    rfp_geographic_locations: locations,
    partner_experience: data.partnerExp,
    thematic_area_ids: data.thematicArea,
    tenure_type: data.project_Tenure_dropdown ? data.project_Tenure_dropdown : 'N/A',
    indicative_budget: data.budget,
    tenure: data.project_Tenure,
    submission_date: data.submissionDate.split("/").reverse().join("/"),
    show_budget: getShowBudgetValue(data?.budget_private),
    // show_budget: data?.budget_private ? data?.budget_private : data?.budget_private?.target.value ? data?.budget_private?.target.value : false,
    //body: textEditor ? textEditor : "",
    rfp_preselected_queries: selectedSampleQuestion.map(question => ({ gid: question.gid })),
    // rfp_screening_questions: data?.customQuestion
    //   .filter(question => question.title !== "rfp_quest_id")
    //   .map(({ rfp_quest_id, ...rest }) => rest),
    rfp_queries: data?.customQuestion
      .filter(question => question.title !== "rfp_quest_id")
      .map(({ rfp_quest_id, ...rest }) => ({
        query: { ...rest }
      })),
  };

  let apiURL = RFP_GLIMPSE;
  let method = httpMethod.POST;
  if (gid) { // for update glimpse
    apiURL = `${RFP_GLIMPSE}/${gid}`;
    method = httpMethod.PUT;
    dataToPost.body = textEditor ? textEditor : editorValue;
  }

  dispatch(fetchAPI(apiURL, method, dataToPost)).then(
    (response) => {
      if (response.success) {
        // const show_budgetString = response.data.show_budget.toString();
        // dispatch(setGlimpse({ ...response.data, show_budget: show_budgetString }));
        // dispatch(setGlimpseFormData(response.data))
        dispatch(setDataToStore(response?.data))
        dispatch(setGid(response?.data?.rfp?.gid));
        dispatch(setDetails({ description: response?.data?.rfp?.body }));
        dispatch(setRfpCreatedAt(response?.data?.rfp?.created_at));
        setTabValue(1);
        navigate(`/rfp/create/${response?.data?.rfp?.gid}`)
        dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success", }));
      } else if (response.error) {
        dispatch(setSnackbar({
          flag: true, message: response.error.errorMsg, type: "error",
        })
        );
      }
    }
  );
};

export const updateDescriptionSubmit = (setTabValue, path, navigate) => (dispatch, getState) => {
  const { rfp: { gid, locations, glimpseFormData, selectedSampleQuestion }, form } = getState();
  const data = form?.RfpGlimpseForm?.values
  const formatDateString = (dateString) => {
    if (dateString) {
      return dayjs(dateString, "YYYY-MM-DD HH:mm:ss Z").format("DD-MM-YYYY");
    }
    return "";
  };
  const submissionDate = formatDateString(data?.submissionDate);

  //console.log("reft", submissionDate)

  // const submissionDate = formatDateString(data.submissionDate);
  const textEditor = encodeHTMLEntities(form?.RfpDetailsForm?.values?.text_editor);
  //console.log("rfp", submissionDate)
  const dataToPost = {
    title: data.title,
    rfp_geographic_locations: locations,
    partner_experience: data.partnerExp,
    thematic_area_ids: data.thematicArea,
    tenure_type: data.project_Tenure_dropdown ? data.project_Tenure_dropdown : 'N/A',
    indicative_budget: data.budget,
    tenure: data.project_Tenure,
    // submission_date: data.submissionDate.split("/").reverse().join("/"),
    submission_date: submissionDate,
    //show_budget: data.show_budget === "1" ? "true" : "false",
    show_budget: data?.budget_private,
    body: textEditor ? textEditor : "",
    rfp_preselected_queries: selectedSampleQuestion.map(question => ({ gid: question.gid })),
    rfp_queries: data?.customQuestion
      .filter(question => question.title !== "rfp_quest_id")
      .map(({ rfp_quest_id, ...rest }) => ({
        query: { ...rest }
      })),
  };

  let apiURL = RFP_GLIMPSE;
  let method = httpMethod.POST;
  if (gid) { // for update glimpse
    apiURL = `${RFP_GLIMPSE}/${gid}`;
    method = httpMethod.PUT;
  }
  dispatch(fetchAPI(apiURL, method, dataToPost)).then(
    (response) => {
      if (response.success) {
        dispatch(setDataToStore(response.data))
        dispatch(setGid(response.data.rfp.gid));
        dispatch(setDetails(response.data.body));
        dispatch(setRfpCreatedAt(response.data.rfp.created_at));
        dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success", }));
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error", })
        );
      }
    }
  );
};
export const submitCatchmentAreas =
  (values, editFlag, index) => (dispatch, getState) => {
    let {
      proposal: { catchmentAreas },
    } = getState();
    dispatch(setCatchmentFlag(true));
    dispatch(reset("catchmentAreaForm"));
    if (editFlag) {
      let newArr = [...catchmentAreas];
      newArr[index] = values;
      dispatch(updateCatchmentAreas(newArr));
    } else {
      const stateExists = catchmentAreas.some(
        (area) => area.state === values.state
      );
      if (!stateExists) {
        dispatch(addCatchmentAreas(values));
      } else {
        dispatch(
          setSnackbar({
            flag: true,
            message: COMMON_MSG.SAME_STATE,
            type: "error",
          })
        );
      }
    }
    dispatch(setCatchmentFlag(false));
    dispatch(reset("catchmentAreaForm"));
  };
export const deleteCatchmentArea = (deleteIndex) => (dispatch, getState) => {
  const {
    proposal: { catchmentAreas },
  } = getState();
  const newArr = catchmentAreas.filter((data, index) => index != deleteIndex);
  dispatch(updateCatchmentAreas(newArr));
};

export const getStateNameById = (stateId) => (dispatch, getState) => {
  const {
    master: { state },
  } = getState();
  const stateList = state.find((state) => state.id == stateId);
  if (stateList) return stateList.value;
};

export const getDistrictNameByIds = (districtIds) => (dispatch, getState) => {
  const { master: { district } } = getState();
  // console.log("Reed",districtIds)
  if (districtIds?.length > 1 && Array.isArray(districtIds)) {
    const districtList =
      districtIds &&
      districtIds.map((id) => {
        const filterList = district.find((data) => data?.id == id);
        if (filterList) {
          return filterList.value;
        }
        // console.log("Reed",filterList)
      });
    // return districtList.toString(' ');
    return districtList.join(', ');
  } else {
    const filterList = district.find((data) => data.id == districtIds);
    if (filterList) {
      return filterList.value;
    }
  }
};


export const sumbitRfpDetails =
  (data, selectedTab) => (dispatch, getState) => {
    const state = getState();

    const dataToPost = {
      uniqueId: state.rfp.rfpGlimpseData.uniqueId,
      description: encodeHTMLEntities(data.text_editor),
    };

    dispatch(fetchAPI(ADD_DETAILS_FRP, httpMethod.POST, dataToPost)).then(
      (response) => {
        if (response.success) {
          dispatch(setDetails(response.data));
          dispatch(
            setSnackbar({
              flag: true,
              message: COMMON_MSG.DATA_CREATED,
              type: "success",
            })
          );
          dispatch(setSideArr(selectedTab));
          // setSelectedTab("Filter Preference");
        } else if (response.error) {
          dispatch(
            setSnackbar({
              flag: true,
              message: response.error.errorMsg,
              type: "error",
            })
          );
        }
      }
    );
  };

export const submitUploadedRfp = (data, selectedTab) => (dispatch, getState) => {
  dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
  dispatch(setSideArr(selectedTab));
};

export const sumbitFilterPreference =
  (values, selectedTab) => (dispatch, getState) => {
    let {
      rfp: { locations },
    } = getState();

    if (locations.length < 1) {
      dispatch(
        setSnackbar({
          flag: true,
          message: COMMON_MSG.LOCATION_ERROR,
          type: "error",
        })
      );
      return;
    }
    // const newQuestions = [];
    // for (const key in values.question) {
    //   if (values.question.hasOwnProperty(key) && values.question[key] === true) {
    //     newQuestions.push({
    //       question: key, //key.split("__")[0],
    //       answer: values.question[key],
    //     });
    //   }
    // }
    if (values.customQuestion.length > 10 || values.customQuestion.length < 1) {
      dispatch(
        setSnackbar({
          flag: true,
          message: COMMON_MSG.SUGGESTED_QUESTION_MSG,
          type: "error",
        })
      );
      return;
    }

    const dataToPost = {
      uniqueId: dispatch(getRFPId()),
      experience: values.experience,
      thematicArea: values.thematicArea,
      receiveProposal: values.receiveProposal,
      geoLocation: locations,
      // customQuestion:values.customQuestion,
      customQuestion: values.customQuestion
        .filter(question => question.title !== "rfp_quest_id")
        .map(({ rfp_quest_id, ...rest }) => rest),
    };

    //  console.log('dfdf', dataToPost.customQuestion);
    dispatch(
      fetchAPI(SUBMIT_FILTER_PREFERENCE, httpMethod.POST, dataToPost)
    ).then((response) => {
      if (response.success) {
        dispatch(setFilterPreferenceData(response.data));
        // setSelectedTab("preview");
        dispatch(
          setSnackbar({
            flag: true,
            message: COMMON_MSG.DATA_CREATED,
            type: "success",
          })
        );
        dispatch(setSideArr(selectedTab));
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    });
  };

export const getRFPId = () => (dispatch, getState) => {
  const {
    rfp: {
      uniqueId,
    },
  } = getState();
  return uniqueId;
};

export const getOrgById = (value) => (dispatch, getState) => {
  const { rfp: { rfpOrgExperience } } = getState();
  const { id, experience } = rfpOrgExperience.find(
    (item) => item.experience === value
  );

  return id;
};

export const submitLocation = (values, editFlag, index) => (dispatch, getState) => {
  const { rfp: { locations } } = getState();
  dispatch(setLocationFlag(true));
  dispatch(reset("locationForm"));
  if (editFlag) {
    let { rfp: { locations } } = getState();
    let newArr = [...locations];
    newArr[index] = values;
    dispatch(updateLocation(newArr));
  } else {
    const stateExists = locations && locations.some(area => area.state_id === values.state_id);
    if (!stateExists) {
      dispatch(addLocation(values));
    }
    else {
      dispatch(setSnackbar({ flag: true, message: COMMON_MSG.SAME_STATE, type: "error" }));
    }
  }
  dispatch(setLocationFlag(false));
  dispatch(reset("locationForm"));
};

export const deleteLocation = (values) => (dispatch, getState) => {
  const { rfp: { locations }, } = getState();
  const newArr = locations.filter((data, index) => index !== values);
  dispatch(updateLocation(newArr));
  dispatch(reset("RfpDetailsForm"));
};

export const submitScreeningQuestions =
  (values, navigate) => (dispatch, getState) => {
    dispatch(reset("RfpDetailsForm"));
  };

export const getScreeningQuestions = () => (dispatch, getState) => {
  dispatch(fetchAPI(GET_SCREENING_QUESTIONS, httpMethod.GET)).then(
    (response) => {
      if (response.success) {
        dispatch(addScreeningQuestions(response.data));
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};
export const getDataState = () => (dispatch, getState) => {
  dispatch(fetchAPI(STATE_URL, httpMethod.GET)).then((response) => {
    if (response.success) {
      dispatch(addUserState(response.data));
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};

export const getDataDistrict = (value) => (dispatch, getState) => {
  dispatch(fetchAPI(`${DISTRICT_URL}/${value}`, httpMethod.GET)).then(
    (response) => {
      if (response.success) {
        dispatch(addUserDistrict(response.data));
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};

export const getDistrict = (state) => (dispatch, getState) => {
  // const {master: { district }} = getState();
  // const newArr = district.filter((data) => data.state_id == state);
  // dispatch(addRFPDistrict(newArr));
  const { master: { district } } = getState();
  const stateArray = Array.isArray(state) ? state : [state];

  const newArr = district.filter((data) => stateArray.includes(data.state_id));
  dispatch(addRFPDistrict(newArr));
};
export const getAllDistrict = (state_id, action) => (dispatch) => {
  const APIUrl = state_id ? `${DISTRICT_URL}?state_id=${state_id}` : DISTRICT_URL;

  dispatch(fetchAPI(APIUrl, httpMethod.GET))
    .then((response) => {
      if (response.success) {
        if (state_id) {
          dispatch(addRFPDistrict(response?.data?.districts));
        } else {
          dispatch(addUserDistrict(response?.data?.districts));
        }
      } else if (response.error) {
        dispatch(setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        }));
      }
    })
};

export const uploadRFPdoc = (file, uploadInfo) => (dispatch, getState) => {
  let dataToPost = {
    filename: uploadInfo.FIELD_NAME,
    key: uploadInfo.KEY,
    file,
    uniqueId: dispatch(getRFPId()),
  };

  let formData = new FormData();
  for (const key in dataToPost) {
    formData.append(key, dataToPost[key]);
  }
  return dispatch(
    fetchAPI(UPLOAD_RFP_FILE, httpMethod.POST, formData, "", true)
  ).then((response) => {
    // console.log(response);
    if (response.success) {
      const uploadedData = {
        ...response.data,
        key: uploadInfo.FIELD_NAME,
      };
      dispatch(addRfpUploadDoc(uploadedData, uploadInfo.FIELD_NAME));

      return response.data;
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
      return false;
    }
  });
};

export const getUploadedDocURL = (uploadInfo) => (dispatch, getState) => {
  const {
    profile: { uploadedData },
  } = getState();
  const searchObject =
    uploadedData &&
    uploadedData.find((data) => data.key === uploadInfo.FIELD_NAME);
  if (searchObject) {
    let firlURL = `${FILE_HOST}${searchObject.filename}`;
    if (searchObject.filename) {
      return firlURL;
    }
  }
};

export const sendRFP =
  (emails, extraEmails = [], shareRfp, navigate, uniqueId) =>
    (dispatch, getState) => {
      if (emails.length < 1 && extraEmails.length < 1) {
        dispatch(
          setSnackbar({
            flag: true,
            message: COMMON_MSG.RFP_SEND_EMAIL,
            type: "error",
          })
        );
        return;
      }

      let dataToPost = {
        emails,
        extraEmails,
        uniqueId: uniqueId,
        is_rfp_shared: shareRfp,
      };
      dispatch(fetchAPI(SEND_RFP, httpMethod.POST, dataToPost)).then(
        (response) => {
          if (response.success) {
            navigate("/rfp/sent");
          } else if (response.error) {
            dispatch(
              setSnackbar({
                flag: true,
                message: response.error.errorMsg,
                type: "error",
              })
            );
          }
        }
      );
    };

export const ShareRFP = (selectedTabKeys, gid, navigate) => (dispatch, getState) => {
  let dataToPost = {
    company_type_keys: selectedTabKeys.includes("all") ? ["all"] : selectedTabKeys
  };
  let apiURL = `${RFP_PREVIEW_SHOW}/${gid}/${'send_proposal_request'}`;
  dispatch(fetchAPI(apiURL, httpMethod.PUT, dataToPost)).then(
    (response) => {
      if (response.success) {
        navigate("/rfp/sent");
        dispatch(setGid(''))
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
      }
    }
  );
};


export const getRFP =
  (section, offset = 0, PageLimit = PAGE_LIMIT) =>
    (dispatch, getState) => {
      dispatch(setIsLoad(true));
      let dataToPost = dispatch(getRFPListPayload(section));
      dataToPost = {
        ...dataToPost,
        offset,
        PageLimit,
      };
      dispatch(fetchAPI(GET_RFP_LIST, httpMethod.POST, dataToPost)).then(
        (response) => {
          dispatch(setIsLoad(false));
          if (response.success) {
            dispatch(setDashboardTab(section));
            dispatch(setRFPList(response.data));
            dispatch(getNotificationCount());
          } else if (response.error) {
            dispatch(
              setSnackbar({
                flag: true,
                message: response.error.errorMsg,
                type: "error",
              })
            );
          }
        }
      );
    };
export const getRfpDashboardList = (section, offset, PageLimit = PAGE_LIMIT) => (dispatch, getState) => {
  dispatch(setIsLoad(true));
  dispatch(setIsRFPdataLoading(true));

  // let dataToPost = dispatch(getRFPListPayload(section));
  //console.log("offset", offset)
  const page = Math.floor(offset / PageLimit) + 1;
  let dataToPost = {
    // ...dataToPost,
    // offset,
    status: section,
    // PageLimit,
    page: page ? page : 1
  };
  dispatch(fetchAPI(RFP_DASHBOARD_LIST, httpMethod.GET, dataToPost)).then(
    (response) => {
      dispatch(setIsLoad(false));
      if (response.success) {
        dispatch(setDashboardTab(section));
        dispatch(setRFPList(response.data));
        dispatch(setIsRFPdataLoading(false));
        dispatch(setIsLoad(false));
        // dispatch(getNotificationCount());
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error", })
        );
        dispatch(setIsRFPdataLoading(false));
        dispatch(setIsLoad(false));

      }
    }
  );
};
export const getRFPInsights =
  (uniqueId, statusType) => (dispatch, getState) => {
    let dataToPost = {
      uniqueId,
      statusType,
    };

    // console.log(dataToPost, "data to post");

    dispatch(fetchAPI(RFP_INSIGHT, httpMethod.POST, dataToPost)).then(
      (response) => {
        // console.log(response, "response");
        if (response.success) {
          dispatch(setRFPData(response.data));
        } else if (response.error) {
          // dispatch(setSnackbar({flag: true,message: response.error.errorMsg,type: "error",}));
        }
      }
    );
  };

export const getRFPInsightsPopover =
  (uniqueId, statusType) => (dispatch, getState) => {
    let dataToPost = {
      uniqueId,
      statusType,
    };
    dispatch(fetchAPI(RFP_INSIGHT, httpMethod.POST, dataToPost)).then(
      (response) => {
        if (response.success) {
          // console.log(response.data, "datares")
          dispatch(setLastSubmissionDate(response.data?.last_submission_date))
          // return response.data?.last_submission_date;
        } else if (response.error) {
          // dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
        }
      }
    );
  };

export const getRFPListPayload = (section) => (dispatch, getState) => {
  switch (section) {
    case DASHBOARD_SECTIONS.INBOX:
      return {
        isInbox: "inbox",
        statusCode: STATUS_CODE.SENT.CODE,
      };
    case DASHBOARD_SECTIONS.SENT:
      return {
        isInbox: "sent",
        statusCode: STATUS_CODE.SENT.CODE,
      };
    case DASHBOARD_SECTIONS.DRAFT:
      return {
        isInbox: "draft",
        statusCode: STATUS_CODE.DRAFT.CODE,
      };
    case DASHBOARD_SECTIONS.SAVED:
      return {
        isInbox: "saved",
        statusCode: STATUS_CODE.SAVED.CODE,
      };
    default:
      return {
        isInbox: "draft",
        statusCode: STATUS_CODE.DRAFT.CODE,
      };
  }
};

export const getRFPResponse = (uniqueId) => (dispatch, getState) => {
  let apiURL = `${RFP_RESPONSE_BY_ID}/${uniqueId}`;
  // let apiURL = `${RFP_RESPONSE_BY_ID}`;

  dispatch(fetchAPI(apiURL, httpMethod.GET)).then((response) => {
    if (response.success) {
      dispatch(setRFPResponse(response.data));
    } else if (response.error) {
      dispatch(setRFPResponse([]));
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};

export const getRFPPreview = (uniqueId) => (dispatch, getState) => {
  // console.log(uniqueId, "yyyyyyy");
  let apiURL = `${GET_RFP_PREVIEW_BY_ID}${uniqueId}`;
  dispatch(fetchAPI(apiURL, httpMethod.GET)).then((response) => {
    if (response.success) {
      dispatch(setPreviewData(response.data));
      dispatch(setUserAssigned(response.data.assigned_to_me))
      dispatch(setDataToStore(response.data));
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};
export const showRfpPreview = (gid,navigate) => (dispatch, getState) => {
  // console.log(uniqueId, "yyyyyyy");
  let apiURL = `${RFP_PREVIEW_SHOW}/${gid}`;
  dispatch(fetchAPI(apiURL, httpMethod.GET)).then((response) => {
    if (response.success) {
      dispatch(setPreviewData(response.data.rfp));
      dispatch(setUserAssigned(response.data.assigned_to_me))
      dispatch(setDataToStore(response.data));
    } else if (response.error) {
      if (response?.error?.errorCode === 422) {
        navigate('/page-not-found')
      }
      dispatch(setSnackbar({ flag: true, message: response?.error?.errorMsg, type: "error" })
      );
    }
  });
};


// export const setDataToStore = (data) => (dispatch, getState) => {
//   if (data) {
//     const {
//       budget,
//       submissionDate,
//       tenureMonths,
//       title,
//       unique_id,
//       description,
//       experience,
//       thematicArea,
//       rfpResponse,
//       geoLocation,
//       upload_rfp,
//       rfpDocument,
//       show_budget,
//     } = data.rfp_data;
//     // const showBudgetValue = show_budget === 1 ? "Yes" : "No";
//     // console.log('red',showBudgetValue)
//     dispatch(
//       setGlimpse({
//         budget: budget,
//         submissionDate: submissionDate,
//         tenureMonths: tenureMonths,
//         title: title,
//         uniqueId: unique_id,
//         show_budget: show_budget?.toString(),
//       })
//     );

//     dispatch(
//       setDetails({
//         description: description,
//       })
//     );

//     if (data.customQuestion && experience && thematicArea) {
//       dispatch(
//         setFilterPreferenceData({
//           experience: experience?.toString(),
//           thematicArea: thematicArea,
//           receiveProposal: rfpResponse,
//           geoLocation: JSON.parse(geoLocation),
//           customQuestion: data.customQuestion,
//         })
//       );
//     }

//     dispatch(setUniqueId(data.uniqueId));
//     dispatch(updateLocation(geoLocation ? JSON.parse(geoLocation) : []));
//     if (upload_rfp) {
//       let value = upload_rfp === "Y" ? true : false;
//       dispatch(setUploadTab(value));
//       dispatch(setUpload(value));
//     }

//     if (rfpDocument) { // update the values from upload rfp
//       let uploadedData = {
//         filename: rfpDocument,
//         key: 'document_path',
//       }
//       dispatch(addRfpUploadDoc(uploadedData));
//     }
//   }
// };
export const setDataToStore = (data) => (dispatch, getState) => {
  if (data) {
    const {
      indicative_budget,
      submission_date,
      tenureMonths,
      title,
      tenure,
      gid,
      body,
      partner_experience,
      rfp_thematic_areas,
      rfpResponse,
      prepared_by_name,
      prepared_by_address,
      responded_by_name,
      responded_by_address,
      created_at,
      tenure_type,
      show_budget,
      rfp_geographic_locations,
      rfp_queries,
      rfp_preselected_queries,
      rfp_query_response,
      responded,
      budget_private,
      proposal_invited,
    } = data?.rfp;
    //const showBudgetValue = show_budget === 1 ? "Yes" : "No";
    // console.log('red',showBudgetValue)
    const extractedQueries = [];
    const extractedPredefineQueries = [];
    const extractedRFPQueries = [];
    if (rfp_queries && Array.isArray(rfp_queries)) {
      rfp_queries?.forEach((queryItem) => {
        const { query_gid, query } = queryItem;
        if (query) {
          const { question, type, options } = query;
          extractedQueries.push({
            query_gid,
            question,
            type,
            options: Array.isArray(options) ? options : [],
          });
        }
      });
    }

    if (rfp_preselected_queries && Array.isArray(rfp_preselected_queries)) {
      rfp_preselected_queries.forEach((queryItem) => {
        const { query_gid, query, rfp_questionnaire_gid } = queryItem;
        const parsedQuery = JSON?.parse(query);
        const { question, type, options } = parsedQuery;
        extractedPredefineQueries.push({
          gid: rfp_questionnaire_gid,
          query_gid,
          question,
          type,
          options: Array.isArray(options) ? options : [],
        });
      });
    }
    if (rfp_query_response && Array.isArray(rfp_query_response)) {
      rfp_query_response.forEach((queryItem) => {
        const { response } = queryItem;
        const { question, type, options, response: queryResponse } = response;
        const processedOptions = Array.isArray(options) ? options : [];
        extractedRFPQueries.push({
          question,
          type,
          options: processedOptions,
          response: queryResponse
        });
      });
    }

    const thematicAreaIds = rfp_thematic_areas.map((area) => area.thematic_area_id);
    dispatch(
      setGlimpseFormData({
        thematicArea: thematicAreaIds,
        budget: indicative_budget,
        submissionDate: submission_date,
        project_Tenure: tenure,
        title: title,
        gid: gid,
        project_Tenure_dropdown: tenure_type,
        partnerExp: partner_experience,
        // budget_private: show_budget?.toString(),
        budget_private: show_budget,
        customQuestion: extractedQueries,
        geoLocation: rfp_geographic_locations,
        prepared_by: prepared_by_name,
        prepared_by_address: prepared_by_address,
        responded: responded,
        rfp_response: rfp_query_response,
        responded_by_name: responded_by_name,
        responded_by_address: responded_by_address,
        proposal_invited: proposal_invited,
      })
    );

    dispatch(setDetails({ description: body }));
    dispatch(setGid(gid));
    dispatch(setRfpCreatedAt(created_at));
    dispatch(updateLocation(rfp_geographic_locations || []));
    dispatch(setSelectedSampleQuestion(extractedPredefineQueries));

    // dispatch(updateLocation(geographic_locations? JSON.parse(geographic_locations) : []));

    // if (data.customQuestion && experience && thematicArea) {
    //   dispatch(
    //     setFilterPreferenceData({
    //       experience: experience?.toString(),
    //       thematicArea: thematicArea,
    //       receiveProposal: rfpResponse,
    //       geoLocation: JSON.parse(geoLocation),
    //       customQuestion: data.customQuestion,
    //     })
    //   );
    // }
  }
};

export const makeFavroute = (uniqueId, favourite, tab) => (dispatch, getState) => {
  const dataToPost = {
    uniqueId: uniqueId,
    favourite: favourite,
    statusType: tab,
  };
  dispatch(fetchAPI(MAKE_FAVROUTE_RFP, httpMethod.POST, dataToPost)).then(
    (response) => {
      if (response.success) {
        // console.log(response, "0000000");
        // dispatch(setRFPList(response.data));
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};
export const makeFavrouteNew = (gid, favourite) => (dispatch, getState) => {
  const dataToPost = {
    favourite: favourite,
    // statusType: tab,
  };
  let apiUrl = `${MARK_FAVOURITE_NEW}/${gid}/${'favourite_rfps'}`
  dispatch(fetchAPI(apiUrl, httpMethod.PUT, dataToPost)).then(
    (response) => {
      if (response.success) {
        // console.log(response, "0000000");
        // dispatch(setRFPList(response.data));
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error", }));
      }
    }
  );
};


export const DeleteRFP = (uniqueIds, statusCode, offset) => (dispatch, getState) => {
  const dataToPost = {
    uniqueId: uniqueIds,
    statusType: statusCode,
  };
  dispatch(fetchAPI(DELETE_RFP, httpMethod.POST, dataToPost)).then(
    (response) => {
      // console.log(response);
      if (response.success) {
        // dispatch(setRFPList(response.data));
        dispatch(
          setSnackbar({
            flag: true,
            message: response.data.message,
            type: "success",
          })
        );
        // dispatch()
        dispatch(getRFP(statusCode, offset));
        // dispatch(getRFPInsights("", statusCode));
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};
export const DeleteRFPNew = (gid, statusCode, offset) => (dispatch, getState) => {
  const { rfp: { dashboardTab, starredTabType } } = getState();
  const statusToPost = dashboardTab === 'starred' ? starredTabType : statusCode;
  const dataToPost = {
    // status: statusCode,
    status: statusToPost,
  };
  let apiUrl = `${RFP_COMMON_URL}/${gid}`
  dispatch(fetchAPI(apiUrl, httpMethod.DELETE, dataToPost)).then(
    (response) => {
      if (response.success) {
        // dispatch(setRFPList(response.data));
        dispatch(setSnackbar({ flag: true, message: response.data.message, type: "success", }));
        dispatch(getRfpDashboardList(statusCode, offset));
        // dispatch(getRFPInsights("", statusCode));
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error", }));
      }
    }
  );
};

export const DeleteSavedRFP = (uniqueIds, statusCode, offset) => (dispatch, getState) => {
  const dataToPost = {
    uniqueId: uniqueIds,
    // statusType: statusCode,
  };
  dispatch(fetchAPI(DELETE_SAVED_RFP, httpMethod.POST, dataToPost)).then(
    (response) => {
      // console.log(response);
      if (response.success) {
        // dispatch(setRFPList(response.data));
        dispatch(
          setSnackbar({
            flag: true,
            message: response.data.message,
            type: "success",
          })
        );
        // dispatch()
        dispatch(getRfpDashboardList(statusCode, offset));
        // dispatch(getRFPInsights("", statusCode));
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};
export const SocialPublishRFP = (gid, rfpShared, statusCode, selectedTab, offset,) => (dispatch, getState) => {
  const dataToPost = {
    publish: rfpShared,
  };
  let apiUrl = `${RFP_COMMON_URL}/${gid}/publish_unpublish`
  dispatch(fetchAPI(apiUrl, httpMethod.PUT, dataToPost)).then(
    (response) => {
      if (response.success) {

        // dispatch(setRFPList(response.data));
        dispatch(setSnackbar({ flag: true, message: response.data.message, type: "success", }));
        dispatch(getRfpDashboardList(selectedTab));
        // if (showInsightsSent) {
        //   dispatch(getRFPInsights(gid, statusCode));
        // }
      } else if (response.error) {
        dispatch(
          setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" })
        );
      }
    }
  );
};

export const PublishRFP = (uniqueIds, rfpShared, showInsightsSent, statusCode, offset) => (dispatch, getState) => {
  const dataToPost = {
    uniqueId: uniqueIds,
    is_rfp_shared: rfpShared,
    // statusType: statusCode,
  };
  dispatch(fetchAPI(PUBLISH_RFP, httpMethod.POST, dataToPost)).then(
    (response) => {
      // console.log(response);
      if (response.success) {
        // dispatch(setRFPList(response.data));
        dispatch(
          setSnackbar({
            flag: true,
            message: response.data.message,
            type: "success",
          })
        );
        // dispatch()
        dispatch(getRFP(statusCode, offset));
        if (showInsightsSent) {
          dispatch(getRFPInsights(uniqueIds, statusCode));
        }
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};

export const DeleteFileRFP = (uniqueId, doc) => (dispatch, getState) => {
  // console.log(uniqueId, doc);
  const dataToPost = {
    filename: doc.FIELD_NAME,
    key: doc.KEY,
    uniqueId: uniqueId,
  };
  dispatch(fetchAPI(DELETE_FILE_RFP, httpMethod.POST, dataToPost)).then(
    (response) => {
      // console.log(response);
      if (response.success) {
        dispatch(addRfpUploadDoc({}));
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};

export const RatinAndCommentRFP = (value, uniqueId) => (dispatch, getState) => {
  let dataToPost = {
    ...value,
    uniqueId,
    userRating: value.userRating || 0,
  };

  // console.log(value, uniqueId);
  dispatch(fetchAPI(RATING_AND_COMMENT, httpMethod.POST, dataToPost)).then(
    (response) => {
      if (response.success) {
        // console.log(response, "0000000");
        // dispatch(setRFPList(response.data));
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};

export const MarkReadRFP = (uniqueId) => (dispatch, getState) => {
  const { rfp: { dashboardTab } } = getState();
  // console.log("tab:",dashboardTab)
  const dataToPost = {
    uniqueId: uniqueId,
    isRead: true,
  };
  if (dashboardTab === 'saved') {
    dataToPost.type = 'saved';
  }
  if (dashboardTab === 'inbox') {
    dataToPost.type = 'inbox';
  }

  dispatch(fetchAPI(MARK_READ_RFP, httpMethod.POST, dataToPost)).then(
    (response) => {
      if (response.success) {
        dispatch(getNotificationCount());
        // dispatch(setRFPList(response.data));
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};

export const setClearForm = () => (dispatch, getState) => {
  // dispatch(reset("RfpFilterPreference", "screening_questions"));
  dispatch(reset("RfpGlimpseForm"));
  dispatch(reset("RfpDetailsForm"));
};

export const resetRfpData = () => (dispatch, getState) => {
  dispatch(setGlimpse({}));
  dispatch(setDetails({}));
  dispatch(setFilterPreferenceData({}));
  dispatch(updateLocation([]))
  dispatch(setUniqueId());
  dispatch(setPreviewData({}));
  dispatch(addRfpUploadDoc({}));
  dispatch(setCustomAdded(false));
  dispatch(setTotalQuestions([]));
  dispatch(setUploadTab(false));
  dispatch(setUpload(false));
  dispatch(resetAllForms());
};

export const rfpResponseQueAnsSave =
  (values, navigate) => (dispatch, getState) => {
    const {
      rfp: {
        saveRfpData: { uniqueId },
      },
    } = getState((state) => state);
    // console.log('Input values:', values);
    const convertedArray = Object.entries(values).map(([key, value]) => {
      const id = parseInt(key.split("_")[1]);
      return { id, answer: value };
    });
    // console.log('Converted array:', convertedArray);
    const datasave = {
      customQuestion: convertedArray,
      uniqueId: uniqueId,
    };

    dispatch(setQuesAnswerResp(datasave));

    navigate("../response-answer");
  };

export const sendQuestionAnswerResponse = () => (dispatch, getState) => {

  const {
    rfp: {
      saveQuesAnswerResp
    }
  } = getState((state) => state);

  const dataToPost = {
    ...saveQuesAnswerResp,
  };

  dispatch(fetchAPI(SAVE_RFP_RESPONSE, httpMethod.POST, dataToPost)).then(
    (response) => {
      if (response.success) {
        dispatch(setQuesAnswerResp(response.data));
        dispatch(setResponseFlag(true));
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};

export const submitQuestionAnswerResponse = (gid, navigate) => (dispatch, getState) => {
  const { rfp: { glimpseFormData, selectedSampleQuestion }, form } = getState();
  //console.log("redd",form?.responseForm)
  const respond_answers = [
    ...glimpseFormData.customQuestion.map(question => ({
      query_gid: question.query_gid,
      answer: {
        question: question.question,
        type: question.type,
        options: question.options,
        response: form?.responseForm?.values[`respond_answers${question.query_gid}`]
      }
    })),
    ...selectedSampleQuestion.map(question => ({
      query_gid: question.query_gid,
      answer: {
        question: question.question,
        type: question.type,
        options: question.options,
        response: form?.responseForm?.values[`respond_answers${question.query_gid}`]
      }
    }))
  ];

  const dataToPost = {
    respond_answers: respond_answers
  };
  // const hasEmptyResponse = respond_answers?.some(answerObj => !answerObj.answer.response);

  // if (hasEmptyResponse) {
  //   dispatch(setSnackbar({flag: true,message: "Please fill in all answers before submitting.",type: "error"}));
  //   return; 
  // }
  let apiUrl = `${RFP_GLIMPSE}/${gid}/respond_proposal_request`;

  dispatch(fetchAPI(apiUrl, httpMethod.PUT, dataToPost)).then(
    (response) => {
      if (response.success) {
        navigate(`/rfp/response-sent`)
        //dispatch(setQuesAnswerResp(response.data));
        // dispatch(setResponseFlag(true));
        dispatch(setSnackbar({ flag: true, message: response.data.message, type: "success", }));
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error", }));
      }
    }
  );
};


export const updateSubmissionDate = (values, handleClose, uniqueId) => (dispatch, getState) => {
  let dataToPost = {
    ...values,
    uniqueId
  };
  dispatch(fetchAPI(RFP_DATE_UPDATE, httpMethod.POST, dataToPost)).then(
    (response) => {
      if (response.success) {
        dispatch(
          setSnackbar({
            flag: true,
            message: COMMON_MSG.DATA_CREATED,
            type: "success",
          })
        );
        dispatch(reset('submissionDateForm'));
        dispatch(setUniqueId());
        handleClose(true)
        dispatch(getRFPPreview(uniqueId));
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
}
export const ExtendSubmissionDate = (values, handleClose, gid) => (dispatch, getState) => {
  let dataToPost = {
    // ...values,
    extended_date: values.submissionDate,
  };
  let apiUrl = `${RFP_COMMON_URL}/${gid}/extend_date`
  dispatch(fetchAPI(apiUrl, httpMethod.PUT, dataToPost)).then(
    (response) => {
      if (response.success) {
        dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success", }));
        dispatch(reset('submissionDateForm'));
        dispatch(setGid());
        handleClose(true)
        // dispatch(getRFPPreview(uniqueId));
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" })
        );
      }
    }
  );
}

export const getResponseList = (uniqueId) => (dispatch, getState) => {
  let apiURL = `${RFP_RESPONSE_LIST}/${uniqueId}`;
  return dispatch(fetchAPI(apiURL, httpMethod.GET)).then((response) => {
    if (response.success) {
      return response.data;
    } else if (response.error) {
      dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
    }
  });
};

export const getResponseDetails = (uniqueId, userId) => (dispatch, getState) => {
  let dataToPost = {
    uniqueId,
    respSenderID: userId,
  }
  return dispatch(fetchAPI(RFP_RESPONSE_DETAILS, httpMethod.POST, dataToPost)).then((response) => {
    if (response.success) {
      dispatch(getNotificationCount());
      return response.data;
    } else if (response.error) {
      dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
    }
  });
};

export const getMyNetworksRFP = (user_type, uniqueId) => (dispatch, getState) => {
  let dataToPost = {
    uniqueId,
    user_type
  }
  dispatch(fetchAPI(RFP_NETWORKS, httpMethod.POST, dataToPost)).then(
    response => {
      if (response.success) {
        dispatch(setMyNetWorks(response.data))
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
      }
    },
  );
}
export const proposalRequest = (uniqueId, receivedUserId) => (dispatch, getState) => {
  let dataToPost = {
    rfp_unq_id: uniqueId,
    received_by: receivedUserId,
  }
  dispatch(fetchAPI(REQUEST_PROPOSAL, httpMethod.POST, dataToPost)).then(
    response => {
      if (response.success) {
        dispatch(setSnackbar({ flag: true, message: response.data.message, type: "success" }));
        return response.data;
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
      }
    },
  );
}
// export const getAssigneeList = () => (dispatch) => {
//   let dataToPost = {
//     type: 'rfp'
//   }

//   dispatch(fetchAPI(GET_ASSIGNEE_LIST, httpMethod.POST, dataToPost)).then(
//     response => {
//       if (response.success) {
//         dispatch(setAssigneeList(response?.data))
//         // dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
//       } else if (response.error) {
//         dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
//       }
//     },
//   );
// }
export const getAssigneeList = () => (dispatch) => {
  let dataToPost = {
    name: ''
  }

  dispatch(fetchAPI(RFP_ASSIGNEE_LIST_NEW, httpMethod.GET, dataToPost)).then(
    response => {
      if (response.success) {
        dispatch(setAssigneeList(response?.data))
        // dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
      }
    },
  );
}
// export const updateAssignUser = (uniqueId, assignee_id, offset, selectedTab) => (dispatch, getState) => {
//   let dataToPost = {
//     uniqueId,
//     assignee_id,
//     PageLimit: PAGE_LIMIT,
//     offset,
//     isInbox: selectedTab,
//   }
//   dispatch(fetchAPI(RFP_ASSIGN_USER, httpMethod.POST, dataToPost)).then(
//     (response) => {
//       if (response.success) {
//         dispatch(setRFPList(response.data));
//         dispatch(setSnackbar({ flag: true, message: "User Assigned Successfully!!!", type: "success" }));
//       } else if (response.error) {
//         dispatch(
//           setSnackbar({
//             flag: true,
//             message: response.error.errorMsg,
//             type: "error",
//           })
//         );
//       }
//     }
//   );
// };
export const updateAssignUser = (gid, assignee_gid, selectedTab, page) => (dispatch, getState) => {
  let dataToPost = {
    rfp_gid: gid,
    assignee_gid: assignee_gid,
    // PageLimit: PAGE_LIMIT,
    // offset,
    // isInbox: selectedTab,
  }
  dispatch(fetchAPI(ASSIGN_USER_NEW, httpMethod.PUT, dataToPost)).then(
    (response) => {
      if (response.success) {
        dispatch(getRfpDashboardList(selectedTab, page));
        dispatch(setSnackbar({ flag: true, message: "User Assigned Successfully!!!", type: "success" }));
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};
export const rfpUnAssignUser = (values, selectedRowId, selectedAssigneeId, handleClose) => (dispatch, getState) => {
  const { users: { userData }, rfp: { rfpOffset, dashboardTab } } = getState();
  const { role_id } = userData?.data;
  let dataToPost = {
    comment: values?.comment,
    uniqueId: selectedRowId,
    // PageLimit: PAGE_LIMIT,
    // offset: rfpOffset,
    // isInbox: dashboardTab,
  }
  if (role_id !== 2) {
    dataToPost.assigneeId = selectedAssigneeId;
  }
  dispatch(fetchAPI(RFP_UNASSIGN_USER, httpMethod.POST, dataToPost)).then(
    (response) => {
      if (response.success) {
        dispatch(setRFPList(response.data));
        dispatch(setSnackbar({ flag: true, message: "User Unassigned Successfully!!!", type: "success" }));
        dispatch(reset("UnassinCommentForm"))
        // handleClose();
      } else if (response.error) {
        dispatch(
          setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" })
        );
      }
    }
  );
};
export const rfpRemoveAssignee = (values, selectedRowId, selectedAssigneeId) => (dispatch, getState) => {
  const { rfp: { dashboardTab } } = getState();
  // const { role_id } = userData?.data;
  let dataToPost = {
    unassign_comment: values?.comment,
    rfp_gid: selectedRowId,
    assignee_gid: selectedAssigneeId,
    // PageLimit: PAGE_LIMIT,
    // offset: rfpOffset,
    // isInbox: dashboardTab,
  }
  // if (role_id !== 2) {
  //   dataToPost.assigneeId = selectedAssigneeId;
  // }
  dispatch(fetchAPI(UNASSIGN_USER_NEW, httpMethod.PUT, dataToPost)).then(
    (response) => {
      if (response.success) {
        dispatch(getRfpDashboardList(dashboardTab));
        dispatch(setSnackbar({ flag: true, message: "User Unassigned Successfully!!!", type: "success" }));
        dispatch(reset("UnassinCommentForm"))
        // handleClose();
      } else if (response.error) {
        dispatch(
          setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" })
        );
      }
    }
  );
};

export const rfpAssignToMe = (rfpId, offset, selectedTab) => (dispatch, getState) => {
  let dataToPost = {
    uniqueId: rfpId,
    PageLimit: PAGE_LIMIT,
    offset,
    isInbox: selectedTab,
  }
  dispatch(fetchAPI(RFP_ASSIGN_TO_ME, httpMethod.POST, dataToPost)).then(
    (response) => {
      if (response.success) {
        // dispatch(setRFPList(response.data));
        dispatch(getRfpDashboardList(selectedTab, offset))
        dispatch(setSnackbar({ flag: true, message: "User Assigned Successfully!!!", type: "success" }));
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error", }));
      }
    }
  );
};
export const rfpUnAssignUserComments = (proposalId) => (dispatch, getState) => {
  let dataToPost = {
    rfp_gid: proposalId,
  }
  dispatch(fetchAPI(RFP_UNASSIGN_USER_COMMENTS, httpMethod.POST, dataToPost)).then(
    (response) => {
      if (response.success) {
        dispatch(setAssigneeComments(response?.data))
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};
export const rfpUnAssignComments = (proposalId) => (dispatch, getState) => {
  let dataToPost = {
    rfp_gid: proposalId,
  }
  dispatch(fetchAPI(ASSIGNEE_COMMENTS, httpMethod.GET, dataToPost)).then(
    (response) => {
      if (response.success) {
        dispatch(setAssigneeComments(response?.data))
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};
export const rfpDashboardInsights = () => (dispatch) => {

  dispatch(fetchAPI(RFP_DASHBOARD_INSIGHTS, httpMethod.GET)).then(
    (response) => {
      if (response.success) {
        dispatch(setDashboardInsight(response?.data?.data))
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error", }));
      }
    }
  );
};

export const InviteProposal = (responded_company_gid, parent_rfp_gid) => (dispatch, getState) => {
  let dataToPost = {
    receiver_company_gid: responded_company_gid,
  }
  let apiURL = `${RFP_COMMON_URL}/${parent_rfp_gid}/invite_proposal`;
  return dispatch(fetchAPI(apiURL, httpMethod.PUT, dataToPost)).then((response) => {
    if (response.success) {
      dispatch(setSnackbar({ flag: true, message: response.data.message, type: "success" }));
      return response.data;
    } else if (response.error) {
      dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
    }
  });
};

export const rfpResponsePreview = (responded_company_gid, parent_rfp_gid) => (dispatch, getState) => {
  let dataToPost = {
    responded_company_gid: responded_company_gid,
    rfp_gid: parent_rfp_gid,
  }
  dispatch(fetchAPI(RESPONSE_PREVIEW, httpMethod.GET, dataToPost)).then(
    (response) => {
      if (response.success) {
        dispatch(setDataToStore(response.data))
      } else if (response.error) {
        // dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" })
        //);
      }
    }
  );
};
export const viewALLResponsed = (gid) => (dispatch) => {
  let dataToPost = {
    rfp_gid: gid,
    page: 1,
  }
  dispatch(fetchAPI(RFP_CHILD_RESPONSES, httpMethod.GET, dataToPost)).then(
    (response) => {
      if (response.success) {
        dispatch(setViewResponses(response?.data))
      } else if (response.error) {
        // dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error", }));
      }
    }
  );
};

export const uploadEditorImages = (files) => (dispatch, getState) => {
  const { rfp: { gid } } = getState();
  let formData = new FormData();
  // formData.append('image[]', JSON.stringify(files));
  Array.from(files).forEach(image => {
    formData.append("image[]", image);
  });


  const APIUrl = `${RFP_GLIMPSE}/${gid}/upload_image`
  return dispatch(fetchAPI(APIUrl, httpMethod.POST, formData, '', true)).then(
    (response) => {
      //  let data = ["https://business-point-dev-bucket.s3.ap-south-1.amazonaws.com/uploads/profile/profile_pic/176/profile.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA22CONCEG2NDA5WAA%2F20240726%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240726T091111Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=1921127f1cc27219a4c32f71276d7cfeafd37887e8b947e4055fc178615e9767","https://business-point-dev-bucket.s3.ap-south-1.amazonaws.com/uploads/profile/profile_pic/544/profile.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA22CONCEG2NDA5WAA%2F20240726%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240726T104557Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=d9dc17609a5c68e44f44920eb19c6dcf6e21deb53d9bbde61039a9480ec5e866"];
      return response?.data?.image;
    }
  );
};
