import { Grid, Box, Typography, Avatar, Paper, Tooltip, Skeleton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from '@mui/styles';
import theme from '../../../../../../assets/material-ui/theme';
import { FILE_HOST } from '../../../../../../common/constants';
import { getInitials } from '../../../../../../common/utils';
import CopyWhite from '../../../../../../assets/icons/CopyWhite.svg';
import securityLock from '../../../../../../assets/icons/security-lock.svg';
import CommonInitials from '../../../../../../common/components/CommonInitials';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import EventsCSS from '../EventDetailsCSS/Events.module.css';

const PointOfContactPreview = ({ data }) => {
    const typographyRef = useRef(null);
    const location = useLocation();
    const pocRef = useRef(null);
    const [openTooltip, setOpenTooltip] = useState(false);
    const [openTooltipPoc, setOpenTooltipPoc] = useState(false);
    const { events } = useSelector((state) => state);
    const EventCreate = location.pathname.startsWith('/events/create-event')
    const handleCopyClick = () => {
        console.log("redd", typographyRef.current)
        if (typographyRef.current) {
            const textToCopy = typographyRef.current.innerText;
            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = textToCopy;
            tempTextArea.style.position = 'fixed';
            document.body.appendChild(tempTextArea);
            tempTextArea.focus();
            tempTextArea.select();
            try {
                const success = document.execCommand('copy');
                if (success) {
                    setOpenTooltip(true);
                } else {
                    console.error('Failed to copy text');
                }
            } catch (error) {
                console.error('Error copying text: ', error);
            } finally {
                document.body.removeChild(tempTextArea);
            }
        }
    };
    const handleCopyPoc = () => {
        console.log("poc", pocRef.current)
        if (pocRef.current) {
            const textToCopy = pocRef.current.innerText;
            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = textToCopy;
            tempTextArea.style.position = 'fixed';
            document.body.appendChild(tempTextArea);
            tempTextArea.focus();
            tempTextArea.select();
            try {
                const success = document.execCommand('copy');
                if (success) {
                    setOpenTooltipPoc(true);
                } else {
                    console.error('Failed to copy text');
                }
            } catch (error) {
                console.error('Error copying text: ', error);
            } finally {
                document.body.removeChild(tempTextArea);
            }
        }
    };

    return (
        <>
            <Grid container className={EventsCSS.eventPocRoot} columnSpacing={2}>
                <Grid item xs={12} lg={EventCreate ? 6 : 8}>
                    <Box className={EventsCSS.eventPocBox}>
                        {data?.name ? (
                            <Typography className={EventsCSS.eventPocTypo}>
                                {data?.name}
                            </Typography>
                        ) : (
                            <>
                                {/* This one is static just for UI perspective and can be removed */}
                                {/* <Typography className={EventsCSS.eventPocTypo}>
                                    Aim to plant 10000 trees in the land of Madhya Pradesh to improve air quality and weather for farmers
                                </Typography> */}
                                {/* This one is static just for UI perspective and can be removed */}

                                {/* Below Commented Section Should be uncommented */}
                                <Skeleton animation="wave" variant="rounded" height='1.25rem' sx={{ backgroundColor: theme.mainPalette.grey.grey500, width: '100%' }} />
                                <Skeleton animation="wave" variant="rounded" height='1.25rem' sx={{ backgroundColor: theme.mainPalette.grey.grey500, width: '75%', mb: '1rem' }} />
                            </>
                        )}
                        {data?.description ? (
                            <Typography className={EventsCSS.eventPocTextID}>
                                {data && data.description && data?.description.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </Typography>
                        ) : (
                            <>
                                {/* This one is static just for UI perspective and can be removed */}
                                {/* <Typography className={EventsCSS.eventPocTextID}>
                                    A warm and sincere thank you to all our incredible volunteers! Your selfless dedication and hard work have made a significant impact on our community. Your commitment to making a positive difference is truly inspiring. Together, we are creating positoA warm and sincere thank you to all our incredible volunteers! Your selfless dedication and hard work have made a significant impact on our community. Your commitment to making a positive difference is truly inspiring. Together, we are creating positoA warm and sincere thank you to all our incredible volunteers! Your selfless dedication and hard work have made a significant impact on our community. Your commitment to making a positive difference is truly inspiring. Together, we are creating pos
                                </Typography> */}
                                {/* This one is static just for UI perspective and can be removed */}

                                {/* Below Commented Section Should be uncommented */}
                                <Skeleton animation="wave" variant="rounded" height='1rem' width='100%' />
                                <Skeleton animation="wave" variant="rounded" height='1rem' width='100%' />
                                <Skeleton animation="wave" variant="rounded" height='1rem' width='100%' />
                                <Skeleton animation="wave" variant="rounded" height='1rem' width='100%' />
                                <Skeleton animation="wave" variant="rounded" height='1rem' width='100%' />
                                <Skeleton animation="wave" variant="rounded" height='1rem' width='40%' />
                            </>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} lg={EventCreate ? 6 : 4} alignSelf='stretch' sx={{ position: 'relative' }}>
                    {data && data?.event_type_id === 2 && <Box className={EventsCSS.privateEventPocBox}>
                        <Box className={EventsCSS.privateEventPocBox2}>
                            <img src={securityLock} alt="lock" width='60px' height='60px' />
                            <Typography className={EventsCSS.privateEventPocTypo}>Private Event</Typography>
                        </Box>
                    </Box>}
                    <Box className={EventsCSS.eventPocBox2}>
                        <Typography className={EventsCSS.eventPocTypo2}>Point of Contact (POC)</Typography>
                        {data && data?.poc?.length === 0 && (
                            <>
                                <Box className={EventsCSS.eventPocBox3}>
                                    <Skeleton animation="wave" variant="circular" height='60px' width='60px' className={EventsCSS.eventPocSkel} />
                                    <Box className={EventsCSS.eventPocBox4}>
                                        <Skeleton animation="wave" variant="rounded" height='1rem' width='90%' sx={{ backgroundColor: theme.palette.grey.neutral }} />
                                        <Skeleton animation="wave" variant="rounded" height='1rem' width='55%' sx={{ backgroundColor: theme.palette.grey.neutral }} />
                                        <Skeleton animation="wave" variant="rounded" height='1rem' width='75%' sx={{ backgroundColor: theme.palette.grey.neutral }} />
                                    </Box>
                                </Box>
                                <Box className={EventsCSS.eventPocBox5}>
                                    <Skeleton animation="wave" variant="circular" height='60px' width='60px' className={EventsCSS.eventPocSkel} />
                                    <Box className={EventsCSS.eventPocBox4}>
                                        <Skeleton animation="wave" variant="rounded" height='1rem' width='90%' sx={{ backgroundColor: theme.palette.grey.neutral }} />
                                        <Skeleton animation="wave" variant="rounded" height='1rem' width='55%' sx={{ backgroundColor: theme.palette.grey.neutral }} />
                                        <Skeleton animation="wave" variant="rounded" height='1rem' width='75%' sx={{ backgroundColor: theme.palette.grey.neutral }} />
                                    </Box>
                                </Box>
                            </>)}
                        <Box >
                            {data && data?.poc && data?.poc?.length > 0 && (
                                <Box className={EventsCSS.f}>
                                    {data?.poc[0]?.initials === null || data?.poc[0]?.name !== null && (data?.poc[0]?.hasOwnProperty('initials') && data?.poc[0]?.hasOwnProperty('logo')) ? (
                                        <Box className={EventsCSS.eventsPocBox6}>
                                            {data?.poc[0]?.initials !== null || data?.poc[0]?.logo !== null ? (
                                                <CommonInitials
                                                    name={data?.poc[0]?.initials}
                                                    divStyle={{ padding: '6px', maxHeight: '60px', maxWidth: '60px', minHeight: '60px', minWidth: '60px', borderRadius: '50%' }}
                                                    typoStyle={{ fontSize: '24px', lineHeight: '50px' }}
                                                    bgColor={data?.poc[0]?.color_code}
                                                    avatarStyle={{ width: '60px', height: '60px' }}
                                                    src={`${data?.poc[0]?.logo}`}
                                                    companyLogo={data?.poc[0]?.logo}
                                                    avatar={true}
                                                />
                                            ) : (
                                                <Avatar
                                                    alt={data?.poc[0]?.name}
                                                    variant="rounder"
                                                    className={EventsCSS.eventPocAvtar}
                                                >
                                                    <Typography className={EventsCSS.eventsPocTypo}>{getInitials(data?.poc[0]?.name)}</Typography>
                                                </Avatar>
                                            )}
                                        </Box>
                                    ) : (
                                        <Box className={EventsCSS.eventPocBox6}>
                                            <Avatar
                                                alt={data?.poc[0]?.name}
                                                variant="rounder"
                                                className={EventsCSS.eventPocAvtar}
                                            >
                                                <Typography className={EventsCSS.eventsPocTypo}>{getInitials(data?.poc[0]?.name)}</Typography>
                                            </Avatar>
                                        </Box>
                                    )}

                                    <Box className={EventsCSS.eventPocBox7}>
                                        <Tooltip title={data?.poc[0]?.name} >
                                            <Box className={EventsCSS.eventPocBox8}>
                                                <Typography className={EventsCSS.eventPocTypo3}>{data?.poc && data.poc[0].name}</Typography>
                                            </Box>
                                        </Tooltip>
                                        <Typography sx={{ fontWeight: '400', fontSize: '16px', color: theme.palette.success.contrastText }}>{data?.poc && data?.poc[0].phone_number}</Typography>
                                        <Box sx={{ display: 'flex', width: '100%', gap: '8px', justifyContent: 'center' }}>

                                            <Typography ref={typographyRef} sx={{ fontWeight: '400', fontSize: '16px', color: theme.palette.success.contrastText, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data?.poc && data?.poc[0].email}</Typography>
                                            <Tooltip title="Copied!" open={openTooltip} onClose={() => setOpenTooltip(false)}>
                                                <img src={CopyWhite} style={{ cursor: 'pointer' }} onClick={handleCopyClick} />
                                            </Tooltip>

                                        </Box>
                                    </Box>
                                </Box>
                            )}
                            {data && data?.poc && data?.poc?.length > 1 &&
                                <>
                                    {/* <Box sx={{ display: 'flex', textAlign: "center", justifyContent: 'center', paddingTop: '24px' }}>
                                        <Box sx={{ paddingRight: '16px' }}>
                                            <Avatar
                                                alt={data.poc[1].name}
                                                variant="rounder"
                                                src={`${FILE_HOST}${data?.poc[1]?.logo}`}
                                                sx={{ width: "60px", height: "60px" }}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography sx={{ fontWeight: '600', fontSize: '16px', wordBreak: 'break-word', color: theme.palette.success.contrastText }}>{data?.poc[1].name}</Typography>
                                            <Typography sx={{ fontWeight: '400', fontSize: '16px', color: theme.palette.success.contrastText, }}>{data?.poc[1].phone_number}</Typography>
                                            <Typography sx={{ fontWeight: '400', fontSize: '16px', color: theme.palette.success.contrastText, wordBreak: 'break-word' }}>{data?.poc[1].email}</Typography>
                                        </Box>
                                    </Box> */}
                                    <Box sx={{ display: 'flex', paddingTop: '24px' }}>
                                        {data?.poc[1]?.initials === null || data?.poc[1]?.name !== null && (data?.poc[1]?.hasOwnProperty('initials') && data?.poc[1]?.hasOwnProperty('logo')) ? (
                                            <Box sx={{ paddingRight: '16px', display: 'flex', justifyContent: 'flex-end', width: '30%', alignItems: 'center' }}>
                                                {data?.poc[1]?.initials !== null || data?.poc[1]?.logo !== null ? (
                                                    <CommonInitials
                                                        name={data?.poc[1]?.initials}
                                                        divStyle={{ padding: '6px', maxHeight: '60px', maxWidth: '60px', minHeight: '60px', minWidth: '60px', borderRadius: '50%' }}
                                                        typoStyle={{ fontSize: '24px', lineHeight: '50px' }}
                                                        bgColor={data?.poc[1]?.color_code}
                                                        avatarStyle={{ width: '60px', height: '60px' }}
                                                        src={`${data?.poc[1]?.logo}`}
                                                        companyLogo={data?.poc[1]?.logo}
                                                        avatar={true}
                                                    />
                                                ) : (
                                                    <Avatar
                                                        alt={data?.poc[1]?.name}
                                                        variant="rounder"
                                                        sx={{ width: "60px", height: "60px", backgroundColor: '#f44336' }}
                                                    >
                                                        <Typography sx={{ fontSize: '28px', color: '#F5F5F5' }}>{getInitials(data?.poc[1]?.name)}</Typography>
                                                    </Avatar>
                                                )}
                                            </Box>
                                        ) : (
                                            <Box sx={{ paddingRight: '16px', textAlign: '-webkit-right', width: '30%' }}>
                                                <Avatar
                                                    alt={data?.poc[1]?.name}
                                                    variant="rounder"
                                                    sx={{ width: "60px", height: "60px", backgroundColor: '#f44336' }}
                                                >
                                                    <Typography sx={{ fontSize: '28px', color: '#F5F5F5' }}>{getInitials(data?.poc[1]?.name)}</Typography>
                                                </Avatar>
                                            </Box>
                                        )}

                                        <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '55%', alignItems: 'center' }}>
                                            <Tooltip title={data?.poc && data.poc[1].name}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', width: '90%' }}>
                                                    <Typography sx={{ fontWeight: '600', fontSize: '16px', color: theme.palette.success.contrastText, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data?.poc && data.poc[1].name}</Typography>
                                                </Box>
                                            </Tooltip>
                                            <Typography sx={{ fontWeight: '400', fontSize: '16px', color: theme.palette.success.contrastText }}>{data?.poc && data?.poc[1].phone_number}</Typography>
                                            <Box sx={{ display: 'flex', width: '100%', gap: '8px', justifyContent: 'center' }}>
                                                <Typography ref={pocRef} sx={{ fontWeight: '400', fontSize: '16px', color: theme.palette.success.contrastText, wordBreak: 'break-word', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data?.poc && data?.poc[1].email}</Typography>
                                                <Tooltip title="Copied!" open={openTooltipPoc} onClose={() => setOpenTooltipPoc(false)}>
                                                    <img src={CopyWhite} style={{ cursor: 'pointer' }} onClick={handleCopyPoc} />
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    </Box>
                                </>
                            }
                        </Box>
                    </Box>
                </Grid>
            </Grid>

        </>
    );
};
export default PointOfContactPreview;