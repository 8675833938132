import * as React from 'react';
import { Badge, Drawer, Grid, IconButton, Popover, styled, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { useLocation, Link } from 'react-router-dom';
import Bvokal from '../../assets/images/BvokalLogo.svg';
import Dashboard from '../../assets/images/Dashboard.svg'
import rfp from '../../assets/images/rfp.svg'
import proposal from '../../assets/images/proposal.svg'
import Home from '../../assets/images/Dashboard Icons.svg'
import Volunteering from '../../assets/images/Volunteering.svg'
import MenuIcon from '@mui/icons-material/Menu';
import messageIcon from '../../assets/icons/messageIcon.svg'
import theme from '../../assets/material-ui/theme';
import notification from "../../assets/icons/notification-bing.svg";
import bussiness from '../../assets/icons/bussiness_Suit.svg';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { getBusinessSuite, getModuleType, isIndividualProfile } from '../actions';
import { useSelector } from 'react-redux';
import BusinessSuite from './BusinessSuite';
import { useDispatch } from 'react-redux';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        fontSize: '10px',
        padding: '0px',
        margin: '-1px',
    },
}));

export default function LeftHeader() {
    const location = useLocation();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [popoverOpen, setPopoverOpen] = React.useState(false);
    const [isBussiness, setIsBussiness] = React.useState(false);

    const isMobile = useMediaQuery('(max-width: 960px)');
    const { users, users: { notifications } } = useSelector((state) => state);
    const [isHovered, setIsHovered] = React.useState(false);
    const handleOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
        setPopoverOpen(true);
    };
    const handleClosePopover = () => {
        setPopoverOpen(false);
    };
    const handleDrawerOpen = () => { setIsBussiness(true); dispatch(getModuleType()); dispatch(getBusinessSuite(users?.userData?.gid)); }
    const handleDrawerClose = () => { setIsBussiness(false); }
    const isIndividual = isIndividualProfile(users.userData);
    const Role_Id = users.userData?.sub_user ? 2 : 1;
    const totalCommunity = notifications?.community_icon_count;
    // const totalProposalCount = notifications?.proposal_count + notifications?.communication_count + notifications?.feedback_counts;
    // const totalRfpCount = notifications?.new_rfp_count + notifications?.rfp_response_count;
    const totalProposalCount = notifications?.proposal_total_count;
    const totalRfpCount = notifications?.rfp_total_count;
    const findRoles = (role) => {
        const userRole = users?.userData?.data?.user_roles?.find((data) => data === role);
        return userRole;
    }
    const totalVolenteering = notifications?.volunteer_action_count + notifications?.volunteer_icon_count;

    return (
        <>
            <Grid container>
                <Grid item xs={12} display="flex" gap={4} alignItems="center" sx={{ justifyContent: { xs: 'center', sm: 'flex-start' }, }}>
                    {isMobile && (
                        <Box
                            component="a"
                            width="60px"
                            onClick={handleOpenPopover}
                            sx={{ transition: 'all 0.3s ease-out', filter: 'grayscale(1)', transition: 'all 0.3s ease', '&:hover': { filter: 'grayscale(0)', transition: 'all 0.3s ease', '& .Mui-focusVisible': { backgroundColor: 'transparent' }, backgroundColor: 'transparent' }, display: 'flex', flexDirection: 'column', gap: '1', '&:hover .dashboard': { visibility: 'visible !important' }, '&:hover .image': { width: '24px !important' }, cursor: 'pointer' }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} gap={0.5} >
                                <div
                                    className="dashboard"
                                    style={{
                                        fontSize: '12px',
                                        visibility: 'hidden',
                                        color: '#0A97D9',
                                        transition: 'visibility 0.2s ease-in',
                                    }}
                                >
                                </div>
                                {/* <img className="image" src={Menu1} alt="Menu" width="24px" /> */}
                                <MenuIcon sx={{fontSize: '36px' }} />
                            </Box>
                        </Box>
                    )}
                    <a href={`${window.location.origin}/social-media`} style={{ textDecoration: 'none', lineHeight: 0, paddingLeft: "8px" }}>
                        <img src={Bvokal} alt="Logo" style={{ height: '24.297px', Width: '100%' }} />
                    </a>
                    <Box sx={{ height: '28px', width: '1px', backgroundColor: theme.palette.divider, alignSelf: 'center', }}></Box>
                    {!isMobile && (
                        <>
                            <Box component='div' sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-evenly' }} gap={4} py={'1.5px'}>
                                <a href={`${window.location.origin}/social-media`} style={{ textDecoration: 'none' }}>
                                    <Box component='a' sx={{ filter: 'grayscale(1)', transition: 'all 0.3s ease', '&:hover': { filter: 'grayscale(0)', transition: 'all 0.3s ease', '& .Mui-focusVisible': { backgroundColor: 'transparent' }, backgroundColor: 'transparent' }, display: 'flex', flexDirection: 'column', gap: '1', '&:hover .dashboard': { visibility: 'visible !important' }, '&:hover .image': { width: '20px !important' } }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }} gap={0.8}>
                                            <img className='image' src={Home} alt="Home" width='16px' height='16px' />
                                            <div className='dashboard' style={{ fontSize: '12px', color: theme.palette.primary.main, lineHeight: 1 }}>HOME</div>
                                        </Box>
                                    </Box>
                                </a>
                                <Link to={`${window.location.origin}/social-media/community`} style={{ textDecoration: 'none' }}>
                                    <Box component='a' sx={{ display: 'flex', flexDirection: 'column', gap: '1', '&:hover .dashboard': { visibility: 'visible !important' }, '&:hover .image': { width: '20px !important' }, cursor: 'pointer' }} onMouseEnter={() => setIsHovered('community')} onMouseLeave={() => setIsHovered(false)}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }} gap={0.8}>
                                            <StyledBadge badgeContent={totalCommunity !== 0 ? totalCommunity > 99 ? `99` : totalCommunity : null} color="error">
                                                <Box sx={{
                                                    display: 'flex',
                                                    filter: isHovered === 'community' ? 'none' : 'grayscale(1)', // Apply grayscale only when not hovering
                                                    '&:hover': {
                                                        filter: 'none',
                                                        '& .Mui-focusVisible': { backgroundColor: 'transparent' },
                                                        backgroundColor: 'transparent',
                                                        color: isHovered === 'community' ? 'blue' : 'initial', // Change color to blue when hovering
                                                    },
                                                    cursor: 'pointer',
                                                }}>
                                                    <img className='image' src={Dashboard} alt="Dashboard" width='16px' height='16.574px' />
                                                </Box>
                                            </StyledBadge>
                                            <Box className='dashboard' sx={{
                                                display: 'flex',
                                                filter: isHovered === 'community' ? 'none' : 'grayscale(1)', // Apply grayscale only when not hovering
                                                '&:hover': {
                                                    filter: 'none',
                                                    '& .Mui-focusVisible': { backgroundColor: 'transparent' },
                                                    backgroundColor: 'transparent',
                                                    color: isHovered === 'community' ? 'blue' : 'initial', // Change color to blue when hovering
                                                },
                                                cursor: 'pointer',
                                            }} style={{ fontSize: '12px', color: theme.palette.primary.main, lineHeight: 1 }}>CONNECTS</Box>

                                        </Box>
                                    </Box>
                                </Link>
                                {(isIndividual && Role_Id === 1) ? null : (
                                    <>
                                        {/* {(findRoles('volunteering') || Role_Id === 1) && (
                                            <Link to='/events/listing' style={{ textDecoration: 'none' }}>
                                                <Box component='a' sx={{ isplay: 'flex', flexDirection: 'column', gap: '1', '&:hover .dashboard': { visibility: 'visible !important' }, '&:hover .image': { width: '20px !important' }, cursor: 'pointer' }} onMouseEnter={() => setIsHovered('volunteering')} onMouseLeave={() => setIsHovered(false)}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} gap={0.8}>
                                                        <StyledBadge badgeContent={totalVolenteering !== 0 ? totalVolenteering > 99 ? `99` : totalVolenteering : null} color="error">
                                                            <Box sx={{
                                                                display: 'flex',
                                                                filter: isHovered === 'volunteering' ? 'none' : 'grayscale(1)', // Apply grayscale only when not hovering
                                                                '&:hover': {
                                                                    filter: 'none',
                                                                    '& .Mui-focusVisible': { backgroundColor: 'transparent' },
                                                                    backgroundColor: 'transparent',
                                                                    color: isHovered === 'volunteering' ? 'blue' : 'initial', // Change color to blue when hovering
                                                                },
                                                                cursor: 'pointer',
                                                            }}>
                                                                <img className='image' src={Volunteering} alt="Home" width='16px' height='16px' />
                                                            </Box>
                                                        </StyledBadge>
                                                        <Box className='dashboard' sx={{
                                                            display: 'flex',
                                                            filter: isHovered === 'volunteering' ? 'none' : 'grayscale(1)', // Apply grayscale only when not hovering
                                                            '&:hover': {
                                                                filter: 'none',
                                                                '& .Mui-focusVisible': { backgroundColor: 'transparent' },
                                                                backgroundColor: 'transparent',
                                                                color: isHovered === 'volunteering' ? 'blue' : 'initial', // Change color to blue when hovering
                                                            },
                                                            cursor: 'pointer',
                                                        }} style={{ fontSize: '12px', color: theme.palette.primary.main, lineHeight: 1 }}>EVENTS</Box>

                                                    </Box>
                                                </Box>
                                            </Link>
                                        )} */}
                                        {/* {(findRoles('proposal') || Role_Id === 1) && (
                                            <Link to="/proposal/dashboard" style={{ textDecoration: 'none' }}>
                                                <Box component='a' sx={{ display: 'flex', flexDirection: 'column', gap: '', '&:hover .dashboard': { visibility: 'visible !important' }, '&:hover .image': { width: '20px !important' }, cursor: 'pointer' }} onMouseEnter={() => setIsHovered('proposal')} onMouseLeave={() => setIsHovered(false)}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }} gap={0.8}>
                                                        <StyledBadge badgeContent={totalProposalCount !== 0 ? totalProposalCount > 99 ? `99` : totalProposalCount : null} color="error">
                                                            <Box sx={{
                                                                display: 'flex',
                                                                filter: isHovered === 'proposal' ? 'none' : 'grayscale(1)', // Apply grayscale only when not hovering
                                                                '&:hover': {
                                                                    filter: 'none',
                                                                    '& .Mui-focusVisible': { backgroundColor: 'transparent' },
                                                                    backgroundColor: 'transparent',
                                                                    color: isHovered === 'proposal' ? 'blue' : 'initial', // Change color to blue when hovering
                                                                },
                                                                cursor: 'pointer',
                                                            }}>
                                                                <img className='image' src={proposal} alt="Home" width='16px' height='16px' />
                                                            </Box>
                                                        </StyledBadge>
                                                        <Box className='dashboard' sx={{
                                                            display: 'flex',
                                                            filter: isHovered === 'proposal' ? 'none' : 'grayscale(1)', // Apply grayscale only when not hovering
                                                            '&:hover': {
                                                                filter: 'none',
                                                                '& .Mui-focusVisible': { backgroundColor: 'transparent' },
                                                                backgroundColor: 'transparent',
                                                                color: isHovered === 'proposal' ? 'blue' : 'initial', // Change color to blue when hovering
                                                            },
                                                            cursor: 'pointer',
                                                        }} style={{ fontSize: '12px', color: theme.palette.primary.main, lineHeight: 1 }}>PROPOSAL</Box>

                                                    </Box>
                                                </Box>
                                            </Link>
                                        )} */}
                                        {/*  {(!isIndividual || findRoles('rfp') || Role_Id === 1) && */}
                                        {/* {(findRoles('rfp') || Role_Id === 1) && (
                                            <Link to='/rfp/listing' style={{ textDecoration: 'none' }}>
                                                <Box component='a' sx={{ display: 'flex', flexDirection: 'column', gap: '6px', '&:hover .dashboard': { visibility: 'visible !important' }, '&:hover .image': { width: '20px !important' }, cursor: 'pointer' }} onMouseEnter={() => setIsHovered('rfp')} onMouseLeave={() => setIsHovered(false)}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} gap={0.8}>
                                                        <StyledBadge badgeContent={totalRfpCount !== 0 ? totalRfpCount > 99 ? `99` : totalRfpCount : null} color="error">
                                                            <Box sx={{
                                                                display: 'flex',
                                                                filter: isHovered === 'rfp' ? 'none' : 'grayscale(1)', // Apply grayscale only when not hovering
                                                                '&:hover': {
                                                                    filter: 'none',
                                                                    '& .Mui-focusVisible': { backgroundColor: 'transparent' },
                                                                    backgroundColor: 'transparent',
                                                                    color: isHovered === 'rfp' ? 'blue' : 'initial', // Change color to blue when hovering
                                                                },
                                                                cursor: 'pointer',
                                                            }}>
                                                                <img className='image' src={rfp} alt="Home" width='16px' height='16px' />
                                                            </Box>
                                                        </StyledBadge>
                                                        <Box className='dashboard' sx={{
                                                            display: 'flex',
                                                            filter: isHovered === 'rfp' ? 'none' : 'grayscale(1)', // Apply grayscale only when not hovering
                                                            '&:hover': {
                                                                filter: 'none',
                                                                '& .Mui-focusVisible': { backgroundColor: 'transparent' },
                                                                backgroundColor: 'transparent',
                                                                color: isHovered === 'rfp' ? 'blue' : 'initial', // Change color to blue when hovering
                                                            },
                                                            cursor: 'pointer',
                                                        }} style={{ fontSize: '12px', color: theme.palette.primary.main, lineHeight: 1 }}>RFP</Box>

                                                    </Box>
                                                </Box>
                                            </Link>
                                        )} */}
                                        <Link style={{ textDecoration: 'none' }}>
                                            <Box component='a'
                                                sx={{
                                                    display: 'flex', flexDirection: 'column', gap: 1,
                                                    '&:hover .dashboard, &:hover .image': {
                                                        filter: 'none',
                                                        color: theme.palette.primary.main,
                                                    },
                                                    cursor: 'pointer',
                                                }}
                                                onClick={handleDrawerOpen}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        gap: 0.8,
                                                    }}
                                                >
                                                    <Box
                                                        className='image'
                                                        sx={{
                                                            display: 'flex',
                                                            filter: 'grayscale(1)',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <img src={bussiness} alt="Home" width='16px' height='16px' />
                                                    </Box>
                                                    <Box
                                                        className='dashboard'
                                                        sx={{
                                                            display: 'flex',
                                                            fontSize: '12px',
                                                            color: theme.palette.primary.main,
                                                            textTransform: 'uppercase',
                                                            textWrap: 'nowrap',
                                                            lineHeight: 1,
                                                            filter: 'grayscale(1)',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        Business Suite
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Link>
                                    </>
                                )}
                            </Box>
                        </>

                    )}
                    <Popover
                        open={popoverOpen}
                        anchorEl={anchorEl}
                        onClose={handleClosePopover}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        PaperProps={{
                            sx: {
                                width: '105%',
                                // maxWidth: '800vw',
                                overflowX: 'auto',
                                marginTop: '4px',
                            },
                        }}
                    >
                        <Box component='div' sx={{ display: 'flex', flexDirection:'column', alignItems:'center', gap:1}} py={2}>
                            <Box sx={{display:'flex', flexWrap:'nowrap', justifyContent:'space-evenly', flexGrow:1, width:'100%'}}>
                                <a href={`${window.location.origin}/social-media`} style={{ textDecoration: 'none' }}>
                                    <Box component='a' width='60px' sx={{ filter: 'grayscale(1)', transition: 'all 0.3s ease', '&:hover': { filter: 'grayscale(0)', transition: 'all 0.3s ease', '& .Mui-focusVisible': { backgroundColor: 'transparent' }, backgroundColor: 'transparent' }, display: 'flex', flexDirection: 'column', gap: '1', '&:hover .dashboard': { visibility: 'visible !important' }, '&:hover .image': { width: '20px !important' } }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }} gap={0.5}>
                                            <img className='image' src={Home} alt="Home" width='20px' height='20px' />
                                            <div className='dashboard' style={{ fontSize: '11px', color: theme.palette.primary.main, }}>HOME</div>
                                        </Box>
                                    </Box>
                                </a>
                                <a href={`${window.location.origin}/social-media/community`} style={{ textDecoration: 'none' }}>
                                    <Box component='a' width='60px' sx={{ filter: 'grayscale(1)', transition: 'all 0.3s ease', '&:hover': { filter: 'grayscale(0)', transition: 'all 0.3s ease', '& .Mui-focusVisible': { backgroundColor: 'transparent' }, backgroundColor: 'transparent' }, display: 'flex', flexDirection: 'column', gap: '1', '&:hover .dashboard': { visibility: 'visible !important' }, '&:hover .image': { width: '20px !important' }, cursor: 'pointer' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }} gap={0.6}>
                                            <img className='image' src={Dashboard} alt="Dashboard" width='20px' />
                                            <div className='dashboard' style={{ fontSize: '11px', color: theme.palette.primary.main, }}>CONNECTS</div>

                                        </Box>
                                    </Box>
                                </a>
                                <a style={{ textDecoration: 'none' }}>
                                    <Box component='a' width='100px' sx={{ filter: 'grayscale(1)', transition: 'all 0.3s ease', '&:hover': { filter: 'grayscale(0)', transition: 'all 0.3s ease', '& .Mui-focusVisible': { backgroundColor: 'transparent' }, backgroundColor: 'transparent' }, display: 'flex', flexDirection: 'column', gap: '1', '&:hover .dashboard': { visibility: 'visible !important' }, '&:hover .image': { width: '20px !important' }, cursor: 'pointer' }} onClick={handleDrawerOpen} >
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }} gap={0.6}>
                                            <img src={bussiness} alt="Business Suite" height='20px' />
                                            <div className='dashboard' style={{ fontSize: '11px', color: theme.palette.primary.main, }}>BUSINESS SUITE</div>
                                        </Box>
                                    </Box>
                                </a>
                            </Box>
                            
                            {/* {(!isIndividual || findRoles('volunteering') || Role_Id === 1) &&
                                <Link to='/events/listing' style={{ textDecoration: 'none' }}>
                                    <Box component='a' width='60px' sx={{ paddingLeft: '55px', filter: 'grayscale(1)', transition: 'all 0.3s ease', '&:hover': { filter: 'grayscale(0)', transition: 'all 0.3s ease', '& .Mui-focusVisible': { backgroundColor: 'transparent' }, backgroundColor: 'transparent' }, display: 'flex', flexDirection: 'column', gap: '1', '&:hover .dashboard': { visibility: 'visible !important' }, '&:hover .image': { width: '20px !important' }, cursor: 'pointer' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }} gap={0.5}>
                                            <img className='image' src={Volunteering} alt="Home" width='20px' />
                                            <div className='dashboard' style={{ fontSize: '11px', color: theme.palette.primary.main, }}>VOLUNTEERING</div>

                                        </Box>
                                    </Box>
                                </Link>} */}
                            {/* {(isIndividual && Role_Id === 1) ? null : (
                                <>
                                    {/* {(findRoles('proposal') || Role_Id === 1) && (
                                        <Link to="/proposal/dashboard" style={{ textDecoration: 'none' }}>
                                            <Box component='a' width='60px' sx={{ paddingLeft: '4.8rem', filter: 'grayscale(1)', transition: 'all 0.3s ease', '&:hover': { filter: 'grayscale(0)', transition: 'all 0.3s ease', '& .Mui-focusVisible': { backgroundColor: 'transparent' }, backgroundColor: 'transparent' }, display: 'flex', flexDirection: 'column', gap: '1', '&:hover .dashboard': { visibility: 'visible !important' }, '&:hover .image': { width: '20px !important' }, cursor: 'pointer' }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }} gap={0.5}>
                                                    <img className='image' src={proposal} alt="Home" width='20px' />
                                                    <div className='dashboard' style={{ fontSize: '11px', color: theme.palette.primary.main, }}>PROPOSAL</div>

                                                </Box>
                                            </Box>
                                        </Link>)}
                                    {(findRoles('rfp') || Role_Id === 1) && (
                                        <Link to='/rfp/listing' style={{ textDecoration: 'none' }}>
                                            <Box component='a' width='60px' sx={{ paddingLeft: '2.2rem', filter: 'grayscale(1)', transition: 'all 0.3s ease', '&:hover': { filter: 'grayscale(0)', transition: 'all 0.3s ease', '& .Mui-focusVisible': { backgroundColor: 'transparent' }, backgroundColor: 'transparent' }, display: 'flex', flexDirection: 'column', gap: '1', '&:hover .dashboard': { visibility: 'visible !important' }, '&:hover .image': { width: '20px !important' }, cursor: 'pointer' }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }} gap={0.5}>
                                                    <img className='image' src={rfp} alt="Home" width='20px' />
                                                    <div className='dashboard' style={{ fontSize: '11px', color: theme.palette.primary.main, }}>RFP</div>

                                                </Box>
                                            </Box>
                                        </Link>)} */}
                                        {/* <a href={`${window.location.origin}/social-media/community`} style={{ textDecoration: 'none' }}>
                                            <Box component='a' width='60px' sx={{ filter: 'grayscale(1)', transition: 'all 0.3s ease', '&:hover': { filter: 'grayscale(0)', transition: 'all 0.3s ease', '& .Mui-focusVisible': { backgroundColor: 'transparent' }, backgroundColor: 'transparent' }, display: 'flex', flexDirection: 'column', gap: '1', '&:hover .dashboard': { visibility: 'visible !important' }, '&:hover .image': { width: '20px !important' }, cursor: 'pointer' }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }} gap={0.6}>
                                                    <img className='image' src={Dashboard} alt="Dashboard" width='20px' />
                                                    <div className='dashboard' style={{ fontSize: '11px', color: theme.palette.primary.main, }}>CONNECTS</div>

                                                </Box>
                                            </Box>
                                        </a> */}
                                    {/*                                         
                                </>
                            )} } */}
                            <Box gap={2} display="flex" alignItems="center" justifyContent="center" sx={{display: { xs: 'flex', sm: 'none' }, width:"100%" }}>
                                {/* <IconButton size="small" aria-label="search" color="inherit" sx={{ display: { xs: 'block', sm: 'none' } }}>
                                    <img src={Search} />
                                </IconButton> */}
                                <a href={`${window.location.origin}/social-media/notifications`} style={{ textDecoration: 'none' }}>
                                    <IconButton size="small" aria-label="show 17 new notifications" color="inherit" sx={{ display: { xs: 'block', sm: 'none' } }}>
                                        <img src={notification} />
                                    </IconButton>
                                </a>
                                <a href={`${window.location.origin}/social-media/messages`} style={{ textDecoration: 'none' }}>
                                    <IconButton size="small" aria-label="show" color="inherit" sx={{ display: { xs: 'block', sm: 'none' } }}>
                                        <img src={messageIcon} />
                                    </IconButton>
                                </a>
                                <a href={`${window.location.origin}/social-media/contact-us`} style={{ textDecoration: 'none' }}>
                                    <IconButton size="small" sx={{ color: theme.palette.action.active, display: { xs: 'block', sm: 'none' } }}>
                                        <HelpOutlineOutlinedIcon sx={{ fontSize: '28px' }} />
                                    </IconButton>
                                </a>
                            </Box>
                        </Box>
                    </Popover>
                </Grid>
            </Grid>
            <Drawer
                open={isBussiness}
                anchor="left"
                sx={{ marginTop: '60px' }}
                PaperProps={{
                    sx: {
                        top: '55px'
                    }
                }}>
                <BusinessSuite handleDrawerClose={handleDrawerClose} />
            </Drawer>
        </>
    );
}