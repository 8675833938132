import React, { useEffect, useState } from "react";
import { Box, Grid, Pagination, Typography } from "@mui/material";
import CommonInitials from "../../../../../common/components/CommonInitials";
import image from "../../../../../assets/images/Health.svg";
import share from "../../../../../assets/icons/rfpShare.svg";
import { getSocialRfp } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { decodeHTMLEntities, getDate, getResponseDate, getTextFromHtml, getThematicNameById, timeAgo } from "../../../../../common/utils";
import { ShareRfp } from "../ShareRpf";


const RfpListingCard = ({ SocialMediaCSS, navigate }) => {
    const dispatch = useDispatch();
    const { socialMedia: { socialRfpList, category, sortBy }, master, form, users } = useSelector((state) => state);
    const [page, setPage] = useState(1);
    const [drawerGid, setDrawerGid] = useState(false);
    const [shareData, setShareData] = useState(false);
    const [shareDialogOpen, setShareDialogOpen] = useState(false);

    const handleShareDialogOpen = (gid, data) => {
        setShareDialogOpen(true);
        setDrawerGid(gid);
        setShareData(data)
    };

    const handleShareDialogClose = () => {
        setShareDialogOpen(false);
    };
    const handleProfileClick = (gid) => {
        navigate(`/profile/${gid}`);
    };

    const itemsPerPage = 10;
    const lastIndex = page * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;

    useEffect(() => {
        dispatch(getSocialRfp(page, category, sortBy));
    }, [dispatch, category, sortBy, page]);

    // const paginatedData = socialRfpList?.rfps?.slice(firstIndex, lastIndex);

    const handleCardClick = (gid) => {
        navigate(`/social-media/rfp/${gid}`);
    };
    const noRfps = socialRfpList?.length === 0;

    const renderBodyWithViewMore = (body) => {
        const text = getTextFromHtml(body);
        const limit = 375;

        if (text.length > limit) {
            return (
                <>
                    {text.substring(0, limit)}... <span className={SocialMediaCSS.ReadMore}>Read More</span>
                </>
            );
        }
        return text;
    };




    return (
        <>
            {noRfps ? (
                <Box className={SocialMediaCSS.noRfpbox}>
                    <Typography className={SocialMediaCSS.noRFPFound} >No RFPs found.</Typography>
                </Box>
            ) : (
                socialRfpList?.rfps?.map((data, index) => (
                    <Grid item className={SocialMediaCSS.rfpCard} key={index}>
                        <Grid item xs={12} className={SocialMediaCSS.cardPadding}>
                            <Box className={SocialMediaCSS.card1row}>
                                <Box className={SocialMediaCSS.postbygap}>
                                    <Typography className={SocialMediaCSS.postedby}>Posted by:</Typography>
                                    <Box className={SocialMediaCSS.cursor} onClick={() => handleProfileClick(data?.company_user_gid)}>
                                    <CommonInitials
                                        name={data?.initials}
                                        bgColor={data?.colour_code}
                                        divStyle={{ padding: '0px', maxHeight: '135px', maxWidth: '132px', minHeight: '32px', minWidth: '32px', borderRadius: '50%', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)", cursor: 'pointer' }}
                                        typoStyle={{ fontSize: '14px', lineHeight: '32px' }}
                                        avatarStyle={{ width: '32px', height: '32px', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)" }}
                                        src={data?.company_logo}
                                        companyLogo={data?.company_logo}
                                        avatar={true}
                                    /></Box>
                                    <Typography className={SocialMediaCSS.rfpName} onClick={() => handleProfileClick(data?.company_user_gid)}>{data.brand_name}</Typography>
                                </Box>
                                <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.ai_center}`}>
                                    <Box className={SocialMediaCSS.cardBox}>
                                        <Typography className={SocialMediaCSS.postedby}>Last Date:</Typography>
                                        <Typography className={SocialMediaCSS.LastDate}>{getResponseDate(data?.submission_date)}</Typography>
                                    </Box>
                                    <Box className={`${SocialMediaCSS.shareRfp} ${SocialMediaCSS.cursor}`} onClick={() => handleShareDialogOpen(data?.gid, data)}>
                                        <img src={share} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box className={`${SocialMediaCSS.typoBox}`} onClick={() => handleCardClick(data.gid)}>
                                <Typography className={SocialMediaCSS.rfpTitle}>{data.title}</Typography>
                            </Box>
                            <Box className={`${SocialMediaCSS.typoBox}`} onClick={() => handleCardClick(data.gid)}>
                                <Typography className={SocialMediaCSS.rfpBudget}>Indicative Budget: </Typography>&nbsp;
                                <Typography className={SocialMediaCSS.rfpBudget}>{data?.indicative_budget === null ? 'TBD' : data?.indicative_budget} |</Typography>&nbsp;
                                <Typography className={SocialMediaCSS.rfpBudget}>Tenure:</Typography>&nbsp;
                                <Typography className={SocialMediaCSS.rfpBudget}>{data?.tenure}</Typography>
                            </Box>
                            <Box className={SocialMediaCSS.cursor} onClick={() => handleCardClick(data.gid)}>
                                {/* <Typography className={SocialMediaCSS.rfpDesp} dangerouslySetInnerHTML={{ __html: decodeHTMLEntities(data?.body) }}></Typography> */}
                                {/* <Typography className={SocialMediaCSS.rfpDesp} >{getTextFromHtml(data?.body)}</Typography> */}
                                <Typography className={SocialMediaCSS.rfpDesp} >{renderBodyWithViewMore(data?.body)}</Typography>

                            </Box>
                            <Box className={SocialMediaCSS.f} gap={2} mb={2}>
                                {data?.thematic_area_ids?.map((theme, index) => (
                                    <Box className={SocialMediaCSS.rfpThematic} mt={1.6} key={index}>
                                        <Typography className={SocialMediaCSS.themeRfp}>{dispatch(getThematicNameById(theme))}</Typography>
                                    </Box>
                                ))}
                            </Box>
                            <Typography className={SocialMediaCSS.postedAt}>Posted {timeAgo(data?.created_at)}</Typography>
                        </Grid>
                    </Grid>
                ))
            )}
            {!noRfps && (
                <Grid container xs={12}>
                    <Grid item xs={12}>
                        <Box mt={1} className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_center}`}>
                            <Pagination
                                count={Math.ceil(socialRfpList?.rfp_counts / itemsPerPage)}
                                page={page}
                                onChange={(event, value) => setPage(value)}
                                variant="outlined"
                                shape="rounded"
                                sx={{
                                    "& .Mui-selected": { backgroundColor: "#0775BA !important", color: "#fff" },
                                    "& .MuiPaginationItem-root:hover": { backgroundColor: "#0775BA !important" },
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>)}
            <ShareRfp
                open={shareDialogOpen}
                onClose={handleShareDialogClose}
                Title="Share to"
                RfpId={drawerGid}
                shareData={shareData}
                master={master}
                form={form}
                user={users}
            />
        </>
    );
};

export default RfpListingCard;

