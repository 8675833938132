import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Box, useMediaQuery, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import QR from '../../../assets/images/QR.png';
import DashboardIcon from "../../../assets/icons/DashboardIcon.svg";
import CommonInput from '../../../common/components/CommonInput';
import ReduxForm from '../../../common/components/ReduxForm';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { submitCancelEvent } from '../actions';
import { useDispatch } from 'react-redux';
import * as clientValidations from '../../../common/validations';
import theme from '../../../assets/material-ui/theme';
import { reset, submit } from 'redux-form';
import { doLogin } from "../../Registration/actions";
import CommonButton from '../../../common/components/CommonButton';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { setTempUserData } from '../../../store/slices/UserSlice';

const DialogLogin = ({ open, handleClose, handleConfirm, title, registerPage }) => {
    const { users } = useSelector((state) => state);
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 960px)');
    const [isReloadOnSubmit, setIsReloadOnSubmit] = useState(false);
    const loginForm = useSelector((state) => state.form.loginForm);
    // const handleSubmit = () => {
    //         dispatch(submit('loginForm'));
    //         handleClose();
    // }
    const handleSubmit = () => {
        // if ((!loginForm?.values?.email && !loginForm?.values?.password) || loginForm?.syncErrors) {
        //    return ;
        // } else {
            dispatch(submit('loginForm'));
            // handleClose();
        // }
    };
    
    useEffect(() => {
        dispatch(reset('loginForm'));
        //  if(users?.isLoggedIn){
        //   navigate('/user-dashboard');
        //  }
    }, []);
    const dispatch = useDispatch()
    const isXsScreen = useMediaQuery('(min-width: 960px)');
    const handleClick = ()=>{
        dispatch(setTempUserData({}));
    }
    return (
        <ReduxForm
            onSubmit={(values) => {
                setIsReloadOnSubmit(true);
                dispatch(doLogin(values, navigate, handleClose, registerPage));
            }}
            initialValues={{ tncCheck: true }}
            form="loginForm"
            label="Log in to your account"
            submitButton="Login"
        >
            <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { minWidth: isXsScreen ? '500px' : '300px', padding: '10px 65px 50px 65px' } }}>
                <DialogTitle sx={{ color: '#333', textAlign: 'left', py: 1, fontSize: '24px', fontWeight: '600', pb: '5px' }}>
                    <IconButton sx={{ position: 'absolute', top: 5, right: 0, color: theme.palette.error.main }} onClick={handleClose}>
                        <CancelOutlinedIcon />
                    </IconButton>
                </DialogTitle>
                <Grid container xs={12}>
                    <Grid item xs={12} sx={{ padding: '30px 20px 8px 20px' }}>
                        <Box>
                            <Typography fontSize='24px' color='#132015' fontFamily='poppins' fontWeight={700} height='32px'>Sign In</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container xs='12' sx={{ padding: '30px 20px 8px 20px' }}>
                    <Grid item xs={12}>
                        <Box height='80px' width='370px' pl={1} >
                            <Typography pl={0} fontSize='16px' color='#333333' fontWeight={500} fontFamily='Poppins'>Email</Typography>
                            <CommonInput
                                name="email"
                                placeholder="Email Address*"
                                type="Email"
                                keyboardType="default"
                                fullWidth
                                validate={[clientValidations.EmailRequired, clientValidations.emailCheck]}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container xs='12' sx={{ padding: '0px 0px 8px 20px' }} >
                    <Grid item xs={12}>
                        <Box height='70px' width='370px' pt={0} pl={1}>
                            <Typography pt={1.5} pl={0} fontSize='16px' color='#333333' fontWeight={500} fontFamily='Poppins'>Password</Typography>
                            <CommonInput
                                name="password"
                                placeholder="Password*"
                                type="password"
                                keyboardType="default"
                                fullWidth
                                validate={[clientValidations.PasswordRequired, clientValidations.minLength8, clientValidations.maxLength16]}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Grid container xs='12' sx={{ padding: '40px 0px 8px 20px' }} >
                        <Grid item xs={11.6}>
                            <CommonButton
                                label="Log in"
                                name="Login"
                                type="submit"
                                onClick={() => handleSubmit()}
                                style={{ height: 47, background: 'linear-gradient(180deg, #0A97D9 0%, #00689D 100%)', color: 'white' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box fontSize='16px' color='#333333' fontWeight='600' pt={4} display='flex' justifyContent='center'>
                                <Typography>Don't have an account?</Typography>
                                <Link  to='/signup' underline="always" style={{ marginLeft: 8, color: "#0A97D9" }} onClick={handleClick}>Sign Up</Link>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </ReduxForm>
    );
};

export default DialogLogin;