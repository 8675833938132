import React from "react";
import PropTypes from 'prop-types';
import { MultiSelect } from "react-multi-select-component";
import { Box, FormControl } from "@mui/material";
import BENGAGECSS from "../../modules/BEngage/BEngageCSS/BEngage.module.css";
import { setSnackbar } from "../../store/slices/MessageSlice";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    multiselectContainer: {
        borderRadius: '28px !important',
    },
}));

export const RenderMultiAutocompleteSelect = ({
    options = [],
    disabled,
    placeholder,
    fullWidth,
    input,
    meta,
    showcount,
    selectTitle,
    customClasses,
    maxSelections,
    ...props
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const formattedOptions = options.map(option => ({
        label: option.value,
        value: option.id
    }));

    const selectedOptions = formattedOptions.filter(option => input.value.includes(option.value));

    const handleChange = (selected) => {
        if (maxSelections && selected.length > maxSelections) {
            dispatch(setSnackbar({ flag: true, message: "Maximum selections reached", type: "error" }));
            return;
        }

        input.onChange(selected.map(option => option.value));
    };

    // Custom value renderer to display "n selected" instead of showing all selected values
    const customValueRenderer = (selected, _options) => {
        if (selected.length === 0) {
            return placeholder || "Select...";
        }
        return `${selected.length} Thematic Area Selected`;
    };

    return (
        <FormControl fullWidth={fullWidth}>
            <MultiSelect
                options={formattedOptions}
                value={selectedOptions}
                onChange={handleChange}
                labelledBy={selectTitle || 'Conference Theme'}
                disabled={disabled}
                hasSelectAll={false}
                ClearSelectedIcon={null}
                focusSearchOnOpen={true}
                valueRenderer={customValueRenderer} // Use the custom value renderer here
                overrideStrings={{
                    selectSomeItems: placeholder || "Select...",
                    allItemsAreSelected: "",
                    selectAll: "",
                    unselectAll: "",
                    search: "Search...",
                    noOptions: "No options available",
                }}
                className={BENGAGECSS.multiSelect}
            />
            {meta.touched && meta.error && (
                <Box className='error' sx={{ color: 'red' }}>
                    {meta.error}
                </Box>
            )}
        </FormControl>
    );
};

RenderMultiAutocompleteSelect.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    maxSelections: PropTypes.number,
    placeholder: PropTypes.string,
    fullWidth: PropTypes.bool,
    customClasses: PropTypes.object,
    options: PropTypes.array.isRequired,
    className: PropTypes.string,
};
