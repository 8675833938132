import React from "react";
import { Box, Typography } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import calender from "../../../../assets/icons/calender.svg";
import social_rfp from "../../../../assets/icons/social_rfp.svg";
import time from "../../../../assets/icons/clock_Solid.svg";
import Nature from "../../../../assets/images/Bovokal.svg"
import { decodeHTMLEntities, formatTime, getDate, getResponseDate } from "../../../../common/utils";
import CommonInitials from "../../../../common/components/CommonInitials";
import { useNavigate } from "react-router";

const RfpPost = ({ post }) => {
    const navigate = useNavigate();
   // console.log("hell", post)

    const handleRfpClick = () => {
        navigate(`/social-media/rfp/${post?.gid}`);
    }
    // const decodeHTMLEntities = (text) => {
    //     const parser = new DOMParser();
    //     const decodedString = parser.parseFromString(text, 'text/html').documentElement.textContent;
    //     return decodedString;
    // };
    const decodeHTMLEntities = (text) => {
        let decodedString = text;
        const parser = new DOMParser();
        while (decodedString.includes('&')) {
            decodedString = parser.parseFromString(decodedString, 'text/html').documentElement.textContent;
        }
        return decodedString;
    };
    // Function to strip HTML tags using regular expressions
    const stripHtmlTags = (html) => {
        return html.replace(/<\/?[^>]+(>|$)/g, "");
    };
    const getTextFromHtml = (html) => {
        const decodedHtml = decodeHTMLEntities(html);
        return stripHtmlTags(decodedHtml);
    };
    return (
        <>
            <Box className={`${SocialMediaCSS.smCard} ${SocialMediaCSS.noTitleCard} ${SocialMediaCSS.EventShare} ${SocialMediaCSS.cursor}`} onClick={handleRfpClick}>
                <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.ai_center}`}>
                    <Box className={`${SocialMediaCSS.social_rfp}`}>
                        {/* <img src={Nature} className={SocialMediaCSS.social_rfpImg} /> */}
                        <CommonInitials
                            name={post?.rfp_user_details?.initials}
                            bgColor={post?.rfp_user_details?.colour_code}
                            divStyle={{ padding: '0px', maxHeight: '135px', maxWidth: '132px', minHeight: '46px', minWidth: '46px', borderRadius: '50%', cursor: 'pointer' }}
                            typoStyle={{ fontSize: '18px', lineHeight: '44px', }}
                            avatarStyle={{ width: '46px', height: '46px',  }}
                            src={post?.rfp_user_details?.image}
                            companyLogo={post?.rfp_user_details?.image}
                            avatar={true}
                        />
                    </Box>
                    <Box className={SocialMediaCSS.social_Box}>
                        <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between}`}>
                            <Typography className={SocialMediaCSS.EventTitle}>{post.title}</Typography>
                            <img src={social_rfp} />
                        </Box>
                        {/* <Typography className={SocialMediaCSS.EventTitle2} dangerouslySetInnerHTML={{ __html: decodeHTMLEntities(post?.body) }} mt={'-12px'} /> */}
                        <Typography className={SocialMediaCSS.EventTitle2}>
                            {getTextFromHtml(post?.body)}
                        </Typography>
                        <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between}`}>
                            <Box className={SocialMediaCSS.f} >
                                <img src={calender} className={SocialMediaCSS.imgPad} />
                                <Typography className={SocialMediaCSS.rfpDate}>{getResponseDate(post?.submission_date)}</Typography>
                            </Box>
                            <Box className={SocialMediaCSS.f}  >
                                <img src={time} className={SocialMediaCSS.imgPad} />
                                <Typography className={SocialMediaCSS.eventLocation}>{formatTime(post?.submission_date)}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
}
export default RfpPost;
