import { reset } from "redux-form";
import { fetchAPI, getAllCountry, getAllDistrict, getAllState, getConnectionTypes, getConnectsCount, getGrievanceTypes, getHastagList, getLanguage, getMentionsList, getNotificationCount, getPostActivity, getPostReactions, getPostTypes, getRequestTypes, getSchedule7, getSocialUserDetail, getTagMentions, resetAllForms } from "../../common/actions";
import { setSnackbar } from "../../store/slices/MessageSlice";
import { ALLOW_NOTIFICATION, BOOKMARK, SHARE_EVENT, COMMENT_LIST, COMMENT_REPLY_LIST, CONNECTION_LIST, CREATE_POST, DASHBOARD_HASHTAGS, DASHBOARD_POST, DELETE_POST, DELETE_POST_IMAGE, DISPUTES, DRAFT_POST, EVENTS_LIST, FOLLOW, FOLLOWERS_LIST, FOLLOWING_LIST, INDIAN_CSR, INDIAN_CSR_PREVIEW, MY_DATABASE, POST_ALL_REACTION, POST_COMMENT_CREATE, POST_REACTION_CREATE, REACTION_COUNT, REMOVE_NOTIFICATION, SHARE_POST, SHARE_TOTAL, SHOW_REACTION, UNBOOKMARK, UNFOLLOW, CONNECTION_REQ, SEARCH_USER, NOTIFICATION_LIST, READ_NOTIFICATION, MY_INIVITATIONS, MY_SUGGESTIONS, CONNECTION_REQ_URL, SUGGESTIONS, CONNECTION_RECEIVED, CONNECTION_SENT, VOL_INVITE, CONNECTION_USER_COUNT, VOL_INVITE_ACCEPT, VOL_INVITE_DENY, VOL_INVITE_WITHDRAW, CONNECTION_REMOVE, SUB_CONNECTION_USER, SOCIAL_RFP_LIST, DASHBOARD_RFP_LIST, RFP_SAVE, SHARE_RFP, SOCIAL_RFP_PREVIEW, CONFERENCES_LIST, DASHBOARD_LIST_MAIN, SHARE_CONFERENCES, SPEAKER_LIST, SPONSOR_LIST, BOOKMARK_SHOW, POST_SHOW, NOMINATION, MY_POSTS, My_SHARED_POST_LIST, FOLLOWHASHTAG, UNFOLLOWHASHTAG, UPDATE_USER_STATUS, } from "../../http/apiUrls";
import { httpMethod, COMMON_MSG, SOCIAL_TYPE, INVITE_TABS, VOL_INVITE_TABS, CONNECTION_TABS, } from "../../common/constants";
import { setChatMessages, addPostComment, addPostReply, setConnectionList, setCreatePost, setEventList, setFollowersList, setFollowingList, setIndianCsrList, setIndianCsrPreview, setMyDatabase, setPostAudience, setPostComment, setPostDashboard, setPostReply, setReactionList, setSelectedActivity, setSelectedPostAudience, setSelectedThematic, setUploadedFiles, updatePostComment, updatePostReplies, setReactionCount, setShareData, setDraftPost, setActiveChannel, setUserList, setTotalCount, setTotalPostCount, setNotificationList, setTotalNotificationCount, setMyInvitations, setMySuggestions, setRecommendations, setTotalRecommendations, setInvitations, setTotalInvitations, setConnectionCount, setConnectionUserList, setTotalConnectionListCount, setVolInvitationList, setTotalVolInvitationCount, setTotalRequestedCount, setTotalAcceptedCount, setSocialRfpList, setSocialDashboardList, setGlimpseFormData, setSelectedSampleQuestion, setDetails, setRfpStatus, setBEngageList, setBEngangeIsLoad, setEngageTotalCount, setBengageDashboardList, setIsEventCardLoading, setIsConnectsCardLoading, setIsConnectionListLoading, setIsChatMessagesLoading, resetPostAudience, setShowPostThematic, setTotalSpeakerCount, setTotalSponsorCount, setSpeakerList, setSponsorList, resetPostTags, resetShowPostThematics, resetPostEdit, setIsPostLoading, resetSelectedSlug, setPostCategoryEdit, setSharedList, setFollowingCount, setFollowersCount, resetSelectedHashtag, setTotalMissedCount, setTotalDeclinedCount, setPostHashtag, setHashtagList } from "../../store/slices/SocialMediaSlice";
import { getThematicAreas } from "../Profile/actions";
import { getCompanyType } from "../Registration/actions";
import { getBeneficiaryTypes } from "../Proposal/actions";
import { Navigate, useNavigate } from "react-router";
import _ from 'lodash-es';
import QRCode from 'qrcode';
import { base64ToFile } from "../../common/utils";


export const getMasterData = () => (dispatch, getState) => {
    const { users: { isLoggedIn } } = getState();
    dispatch(getAllState());
    dispatch(getAllCountry());
    dispatch(getBeneficiaryTypes());
    dispatch(getSchedule7());
    dispatch(getAllDistrict());
    dispatch(getLanguage());
    dispatch(getThematicAreas());
    dispatch(getCompanyType());
    dispatch(getPostActivity());
    isLoggedIn && dispatch(getMentionsList());
    dispatch(getHastagList());
    isLoggedIn && dispatch(getTagMentions());
    dispatch(getPostTypes());
    dispatch(getPostReactions());
    dispatch(getGrievanceTypes());
    dispatch(getConnectionTypes());
    dispatch(getRequestTypes());

}
export const getFollowingList = (page = 1, loading, setLoading) => (dispatch) => {
    loading && setLoading(true);
    let dataToPost = {
        page
    }
    dispatch(fetchAPI(FOLLOWING_LIST, httpMethod.GET, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(setFollowingList(response?.data));
                dispatch(setFollowingCount(Math.ceil(response.following_count / 10)));
                loading && setLoading(false);
            } else if (response.error) {
                loading && setLoading(false);
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}
export const getFollowersList = (page = 1, loading, setLoading) => (dispatch) => {
    loading && setLoading(true);
    let dataToPost = {
        page
    }
    dispatch(fetchAPI(FOLLOWERS_LIST, httpMethod.GET, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(setFollowersList(response?.data));
                dispatch(setFollowersCount(Math.ceil(response.followers_count / 10)));
                loading && setLoading(false);
            } else if (response.error) {
                loading && setLoading(false);
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}

export const getReactionList = (gid, tabId, commentId, replyId) => (dispatch, getState) => {
    const { socialMedia } = getState();
    console.log('reactid', replyId)
    let dataToPost = {
        // reactionable_id: gid,
        // reactionable_type: 'Post',
        reactionable_type: socialMedia.postMode,
    }

    if (tabId !== 'all' && tabId !== undefined && tabId !== socialMedia.postMode && tabId !== 'PostComment') {
        dataToPost.reaction_type_key = tabId;
    }
    if (socialMedia.postMode === 'Post' && gid !== undefined) {
        dataToPost.reactionable_id = gid;
    }
    else if (socialMedia.postMode === 'PostComment' && commentId !== undefined) {
        dataToPost.reactionable_id = commentId;
    }
    else if (socialMedia.postMode === 'CommentReply' && replyId !== undefined) {
        dataToPost.reactionable_id = replyId;
    }
    dispatch(fetchAPI(POST_ALL_REACTION, httpMethod.GET, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(setReactionList(response?.data))
                dispatch(setReactionCount(response));
            } else if (response.error) {
                //dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const getIndianCSRList = () => (dispatch) => {

    dispatch(fetchAPI(INDIAN_CSR, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setIndianCsrList(response?.data))
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}
export const getIndianCSRPreview = (slug, navigate, offset = 10, loading, setLoading) => (dispatch, getState) => {
    const { socialMedia: { selectedSlug } } = getState();
    loading && setLoading(true)
    let convertedPage = Math.ceil(offset / 10);
    let dataToSend = {
        page: convertedPage,
    }

    dispatch(fetchAPI(`${INDIAN_CSR_PREVIEW}/${selectedSlug}`, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(setIndianCsrPreview(response?.data))
                dispatch(setPostDashboard(response?.data?.posts))
            } else if (response.error) {
                if (response.error.errorCode === 422 && response.error.errorMsg === "Social Impact not found!!") {
                    navigate('/page-not-found');
                } else {
                    dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
                }
            }
        },
    );
}


export const getEventsList = (selectedSubTab) => (dispatch) => {
    // dispatch(setIsLoading(true));

    dispatch(setIsEventCardLoading(true));

    let dataToSend;
    if (selectedSubTab !== 'bVolunteer') {
        dataToSend = {
            suggested: selectedSubTab === 'Suggested' ? true : false,
        };
    } else {
        dataToSend = {
            suggested: false,
        };
    }

    // if (selectedSubTab === 'Suggested') {
    //     dataToSend.suggested = selectedSubTab;
    // }

    dispatch(fetchAPI(EVENTS_LIST, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(setEventList(response?.data))
                dispatch(setIsEventCardLoading(false));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
                dispatch(setIsEventCardLoading(false));
            }
        },
    );
}
export const getConnectionData = () => (dispatch) => {
    dispatch(setIsConnectsCardLoading(true));
    dispatch(fetchAPI(MY_DATABASE, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setMyDatabase(response?.data))
                dispatch(setIsConnectsCardLoading(false));

            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
                dispatch(setIsConnectsCardLoading(false));

            }
        },
    );
}

export const getInvitations = () => (dispatch) => {

    dispatch(fetchAPI(MY_INIVITATIONS, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setMyInvitations(response?.data?.connection_requests));
                dispatch(setTotalInvitations(response?.data?.total_count));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}

export const getSuggestions = () => (dispatch) => {

    dispatch(fetchAPI(MY_SUGGESTIONS, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setMySuggestions(response))
                dispatch(getNotificationCount());
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}

export const getAllConnection = (offset = 10, loading, setLoading, isFecthing) => (dispatch, getState) => {
    const { socialMedia: { connectionUserList, selectedConnTab } } = getState();
    loading && setLoading(true);
    let convertedPage = Math.ceil(offset / 10);
    let company_type = selectedConnTab === CONNECTION_TABS.CSR ? CONNECTION_TABS.CSR : selectedConnTab === CONNECTION_TABS.AGENCY ? CONNECTION_TABS.AGENCY : selectedConnTab === CONNECTION_TABS.ACADEMIA ? CONNECTION_TABS.ACADEMIA : selectedConnTab === CONNECTION_TABS.COMPANIES ? CONNECTION_TABS.COMPANIES : selectedConnTab === CONNECTION_TABS.NGO ? CONNECTION_TABS.NGO : '';
    let dataToSend = {
        page: convertedPage,
        company_type
    }
    dispatch(fetchAPI(CONNECTION_USER_COUNT, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                if (!_.isEmpty(response?.data)) {
                    dispatch(setConnectionCount(response?.data?.company_type_count));
                    loading ? dispatch(setConnectionUserList([...connectionUserList, ...response?.data?.connection_list])) : dispatch(setConnectionUserList(response?.data?.connection_list));
                    let total_count = response?.data?.company_type_count.find((data) => data.key === selectedConnTab || data.key === 'personal')?.count;
                    dispatch(setTotalConnectionListCount(total_count));
                    if (loading) {
                        isFecthing.current = false;
                        setLoading(false);
                    }
                } else {
                    if (loading) {
                        isFecthing.current = false;
                        setLoading(false);
                    } else {
                        dispatch(setConnectionUserList([]));
                        dispatch(setTotalConnectionListCount(0));
                    }
                }
            } else if (response.error) {
                if (loading) {
                    isFecthing.current = false;
                }
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}

export const getConnection = (offset = 10, loading, setLoading, isFecthing) => (dispatch, getState) => {
    const { socialMedia: { connectionUserList, selectedConnTab } } = getState();
    loading && setLoading(true);
    let convertedPage = Math.ceil(offset / 10);
    let company_sub_type = selectedConnTab === CONNECTION_TABS.FOUNDATION ? CONNECTION_TABS.FOUNDATION : selectedConnTab === CONNECTION_TABS.INDIPENDENT_NGO ? CONNECTION_TABS.INDIPENDENT_NGO : selectedConnTab === CONNECTION_TABS.SECTION8 ? CONNECTION_TABS.SECTION8 : selectedConnTab === CONNECTION_TABS.PRIVATE ? CONNECTION_TABS.PRIVATE : selectedConnTab === CONNECTION_TABS.PUBLIC ? CONNECTION_TABS.PUBLIC : '';
    let dataToSend = {
        page: convertedPage,
        company_sub_type
    }
    dispatch(fetchAPI(SUB_CONNECTION_USER, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                if (!_.isEmpty(response?.data)) {
                    loading ? dispatch(setConnectionUserList([...connectionUserList, ...response?.data?.connection_list])) : dispatch(setConnectionUserList(response?.data?.connection_list));
                    dispatch(setTotalConnectionListCount(response?.data?.company_type_count));
                    if (loading) {
                        isFecthing.current = false;
                        setLoading(false);
                    }
                } else {
                    if (loading) {
                        isFecthing.current = false;
                        setLoading(false);
                    } else {
                        dispatch(setConnectionUserList([]));
                        dispatch(setTotalConnectionListCount(0));
                    }
                }
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}

export const getNgoTab = (offset = 10) => (dispatch) => {
    let convertedPage = Math.ceil(offset / 10);
    let company_type = CONNECTION_TABS.NGO;
    let dataToSend = {
        page: convertedPage,
        company_type
    }
    dispatch(fetchAPI(CONNECTION_USER_COUNT, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(setConnectionUserList(response?.data));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}

export const getCompaniesTab = (offset = 10) => (dispatch) => {
    let convertedPage = Math.ceil(offset / 10);
    let company_type = CONNECTION_TABS.COMPANIES;
    let dataToSend = {
        page: convertedPage,
        company_type
    }
    dispatch(fetchAPI(CONNECTION_USER_COUNT, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(setConnectionUserList(response?.data));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}

export const getAllSuggestions = (offset = 10, loading, setLoading, isFecthing) => (dispatch, getState) => {
    const { socialMedia: { recommendationList } } = getState();
    loading && setLoading(true);
    let convertedPage = Math.ceil(offset / 10);
    let dataToSend = {
        page: convertedPage,
    }
    dispatch(fetchAPI(SUGGESTIONS, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                loading ? dispatch(setRecommendations([...recommendationList, ...response?.data?.recommendations])) : dispatch(setRecommendations(response?.data?.recommendations));
                loading && setLoading(false);
                dispatch(setTotalRecommendations(response?.data?.total_count));
                if (loading) {
                    isFecthing.current = false;
                }
            } else if (response.error) {
                if (loading) {
                    isFecthing.current = false;
                }
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}

export const getAllInvitations = (offset = 10, loading, setLoading, isFecthing) => (dispatch, getState) => {
    const { socialMedia: { invitationList, selectedConnTab } } = getState();
    let APIUrl = selectedConnTab === INVITE_TABS.RECIEVED ? CONNECTION_RECEIVED : CONNECTION_SENT;
    loading && setLoading(true);
    let convertedPage = Math.ceil(offset / 10);
    let dataToSend = {
        page: convertedPage,
    }
    dispatch(fetchAPI(APIUrl, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                loading ? selectedConnTab === INVITE_TABS.RECIEVED ? dispatch(setInvitations([...invitationList, ...response?.data?.connection_requests])) : dispatch(setInvitations([...invitationList, ...response?.data?.sent_requests])) : selectedConnTab === INVITE_TABS.RECIEVED ? dispatch(setInvitations(response?.data?.connection_requests)) : dispatch(setInvitations(response?.data?.sent_requests));
                loading && setLoading(false);
                dispatch(setTotalAcceptedCount(response?.data?.sent_count));
                dispatch(setTotalRequestedCount(response?.data?.request_count));
                dispatch(setTotalInvitations(response?.data?.total_count));
                if (loading) {
                    isFecthing.current = false;
                }
            } else if (response.error) {
                if (loading) {
                    isFecthing.current = false;
                }
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}

export const getAllVolInvitations = (offset = 10, loading, setLoading, isFecthing) => (dispatch, getState) => {
    const { socialMedia: { volInvitationList, selectedConnTab } } = getState();
    loading && setLoading(true);
    let convertedPage = Math.ceil(offset / 10);
    let dataToSend = {
        page: convertedPage,
        invitation: selectedConnTab,
    }
    dispatch(fetchAPI(VOL_INVITE, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                loading ? dispatch(setVolInvitationList([...volInvitationList, ...response?.data?.list])) : dispatch(setVolInvitationList(response?.data?.list));
                loading && setLoading(false);
                dispatch(setTotalVolInvitationCount(response?.data?.total_volunteers_count));
                dispatch(setTotalRequestedCount(response?.data?.total_request));
                dispatch(setTotalAcceptedCount(response?.data?.total_accept || 0));
                if (loading) {
                    isFecthing.current = false;
                }
            } else if (response.error) {
                if (loading) {
                    isFecthing.current = false;
                }
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}

export const getAllSpeakerInvitations = (offset = 10, loading, setLoading, isFecthing) => (dispatch, getState) => {
    const { socialMedia: { speakerList, selectedConnTab } } = getState();
    loading && setLoading(true);
    let convertedPage = Math.ceil(offset / 10);
    let dataToSend = {
        page: convertedPage,
        status: selectedConnTab.toLowerCase(),
    }
    dispatch(fetchAPI(SPEAKER_LIST, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                loading ? dispatch(setSpeakerList([...speakerList, ...response?.data])) : dispatch(setSpeakerList(response?.data));
                loading && setLoading(false);
                dispatch(setTotalSpeakerCount(response?.total_count));
                dispatch(setTotalRequestedCount(response?.total_received || 0));
                dispatch(setTotalAcceptedCount(response?.total_accepted || 0));
                dispatch(setTotalMissedCount(response?.total_missed || 0));
                dispatch(setTotalDeclinedCount(response?.total_declined || 0));
                if (loading) {
                    isFecthing.current = false;
                }
            } else if (response.error) {
                if (loading) {
                    isFecthing.current = false;
                }
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const speakerAction = (gid, id, action) => (dispatch) => {
    let dataToPost = {
        status: action,
        conference_gid: id,
    }
    const generateQRCode = async () => {
        try {
            const options = {
                width: 200,
                margin: 1
            };
            const response = await QRCode.toDataURL(`${id}-${gid}-speakers`, options)
            // console.log(response, "Speaker QR")
            const file = base64ToFile(response, 'SpeakerQR', true);
            dispatch(sendEticket(file, gid, "speaker_invitation_gid"));
        } catch (error) {
            dispatch(setSnackbar({ flag: true, message: "Error Genrating QR", type: "error" }));
        }
    }
    dispatch(fetchAPI(`${SPEAKER_LIST}/${gid}`, httpMethod.PUT, dataToPost)).then(
        response => {
            if (response.success) {
                // if (action === 'accepted') {
                //     generateQRCode();
                // }
                dispatch(getAllSpeakerInvitations());
                dispatch(getConnectsCount());
                dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getAllSponsorInvitations = (offset = 10, loading, setLoading, isFecthing) => (dispatch, getState) => {
    const { socialMedia: { sponsorList, selectedConnTab } } = getState();
    loading && setLoading(true);
    let convertedPage = Math.ceil(offset / 10);
    let dataToSend = {
        page: convertedPage,
        status: selectedConnTab.toLowerCase(),
    }
    dispatch(fetchAPI(SPONSOR_LIST, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                loading ? dispatch(setSponsorList([...sponsorList, ...response?.data])) : dispatch(setSponsorList(response?.data));
                loading && setLoading(false);
                dispatch(setTotalSponsorCount(response?.total_count));
                dispatch(setTotalRequestedCount(response?.total_received || 0));
                dispatch(setTotalAcceptedCount(response?.total_accepted || 0));
                dispatch(setTotalMissedCount(response?.total_missed || 0));
                dispatch(setTotalDeclinedCount(response?.total_declined || 0));
                if (loading) {
                    isFecthing.current = false;
                }
            } else if (response.error) {
                if (loading) {
                    isFecthing.current = false;
                }
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const sponsorAction = (gid, id, action) => (dispatch) => {
    let dataToPost = {
        status: action,
        conference_gid: id,
    }
    const generateQRCode = async () => {
        try {
            const options = {
                width: 200,
                margin: 1
            };
            const response = await QRCode.toDataURL(`${id}-${gid}-sponsors`, options)
            // console.log(response, "Sponsor QR")
            const file = base64ToFile(response, 'SpeakerQR', true);
            dispatch(sendEticket(file, gid, "sponsor_invitation_gid"));
        } catch (error) {
            dispatch(setSnackbar({ flag: true, message: "Error Genrating QR", type: "error" }));
        }
    }
    dispatch(fetchAPI(`${SPONSOR_LIST}/${gid}`, httpMethod.PUT, dataToPost)).then(
        response => {
            if (response.success) {
                // if (action === 'accepted') {
                //     generateQRCode();
                // }
                dispatch(getAllSponsorInvitations());
                dispatch(getConnectsCount());
                dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const invitationAction = (gid, action, invitation) => (dispatch) => {
    let dataToPost = {
        connection_request: {
            status: action,
        }
    }
    dispatch(fetchAPI(`${CONNECTION_REQ_URL}/${gid}`, httpMethod.PUT, dataToPost)).then(
        response => {
            if (response.success) {
                invitation ? dispatch(getAllInvitations()) : dispatch(getInvitations());
                dispatch(getConnectsCount());
                dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const invitationAccept = (gid) => (dispatch) => {
    let dataToPost = {
        gid
    }
    dispatch(fetchAPI(VOL_INVITE_ACCEPT, httpMethod.PUT, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(getAllVolInvitations());
                dispatch(getConnectsCount());
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const invitationDeny = (gid) => (dispatch) => {
    let dataToPost = {
        gid
    }
    dispatch(fetchAPI(VOL_INVITE_DENY, httpMethod.PUT, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(getAllVolInvitations());
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const invitationWithdraw = (gid) => (dispatch) => {
    let dataToPost = {
        gid,
        withdraw: true
    }
    dispatch(fetchAPI(VOL_INVITE_WITHDRAW, httpMethod.PUT, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(getAllVolInvitations());
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const connectionRemove = (gid) => (dispatch, getState) => {
    const { socialMedia: { selectedConnTab } } = getState();
    dispatch(fetchAPI(`${CONNECTION_REMOVE}${gid}`, httpMethod.DELETE)).then(
        response => {
            if (response.success) {
                selectedConnTab === CONNECTION_TABS.NGO ? dispatch(getNgoTab()) : selectedConnTab === CONNECTION_TABS.COMPANIES ? dispatch(getCompaniesTab()) : dispatch(getAllConnection());
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const readAllNotifications = () => (dispatch) => {

    dispatch(fetchAPI(READ_NOTIFICATION, httpMethod.PUT)).then(
        response => {
            if (response.success) {
                // dispatch(setMyDatabase(response?.data))
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}

export const getNotificationData = (offset = 10, loading, setLoading) => (dispatch, getState) => {
    const { socialMedia: { notificationList } } = getState();
    loading && setLoading(true);
    let convertedPage = Math.ceil(offset / 10);
    let dataToSend = {
        page: convertedPage,
    }
    dispatch(fetchAPI(NOTIFICATION_LIST, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                loading ? dispatch(setNotificationList([...notificationList, ...response?.data?.notifications])) : dispatch(setNotificationList(response?.data?.notifications));
                loading && setLoading(false);
                dispatch(setTotalNotificationCount(response?.data?.total_count));
                dispatch(getNotificationCount());
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}
export const getDashboardPost = (offset = 10, loading, setLoading, isFecthing, navigate) => (dispatch, getState) => {
    const { socialMedia: { postDashboard, bookmarkType, notificationPostType, notificationPostGid, myFeed }, users: { isLoggedIn } } = getState();
    //dispatch(setIsPostLoading(true));
    if (loading) {
        setLoading(true);
        isFecthing.current = true;
    }
    let convertedPage = Math.ceil(offset / 10);
    let dataToSend = {
        page: convertedPage,
    }
    if (bookmarkType === "Post" || bookmarkType === "SocialEvent") {
        dataToSend.bookmarkable_type = bookmarkType;
    }
    if (notificationPostType === "posts") {
        // apiEndpoint = BOOKMARK_SHOW;
        dataToSend = {};
    }
    let ShowPostApiUrl = `${POST_SHOW}/${notificationPostGid}`
    let apiEndpoint = bookmarkType === "Post" || bookmarkType === "SocialEvent" ? BOOKMARK_SHOW : notificationPostType === "posts" ? ShowPostApiUrl : myFeed === "myPosts" ? MY_POSTS : DASHBOARD_POST;
    dispatch(fetchAPI(apiEndpoint, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(setIsPostLoading(false));
                loading ? dispatch(setPostDashboard([...postDashboard, ...response.data])) : dispatch(setPostDashboard(response?.data));
                dispatch(setTotalPostCount(response.total_post_count))
                isLoggedIn && dispatch(getConnectionData())
                if (loading) {
                    setLoading(false);
                    isFecthing.current = false;
                }
            } else if (response.error) {
                if (loading) {
                    setLoading(false);
                    isFecthing.current = false;
                }
                const errorMessage = response?.error?.errorMsg || response?.message;
                if (response.error.errorCode === 422 && response.error.errorMsg === "Post not found!!!") {
                    navigate('/page-not-found');
                } else {
                    dispatch(setSnackbar({ flag: true, message: errorMessage, type: "error" }));
                }
            }
        },
    );
}
// export const getBookMarkPost = (offset = 10, loading, setLoading) => (dispatch, getState) => {
//     const { socialMedia: { postDashboard ,bookmarkType } } = getState();
//     loading && setLoading(true)
//     let convertedPage = Math.ceil(offset / 10);
//     let dataToSend = {
//         page: convertedPage,
//         bookmarkable_type:bookmarkType,

//     }
//     dispatch(fetchAPI(BOOKMARK_SHOW, httpMethod.GET, dataToSend)).then(
//         response => {
//             if (response.success) {
//                 loading ? dispatch(setPostDashboard([...postDashboard, ...response.data])) : dispatch(setPostDashboard(response?.data));
//                 dispatch(setTotalPostCount(response.total_post_count))
//             } else if (response.error) {
//                 dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
//             }
//         },
//     );
// }
export const submitPostReaction = (postId, reactionId, type, commentId, poss, parentComment, replyId) => (dispatch, getState) => {
    const { socialMedia: { selectedSlug, selectedHashtag } } = getState();
    const dataToPost = {
        reactionable_type: type,
        reactionable_id: postId,
        reaction_type_id: JSON.parse(reactionId),
    };
    //console.log("freee", commentId)
    if (type === "PostComment") {
        dataToPost.reactionable_id = commentId;
    }
    if (type === "CommentReply") {
        // dataToPost.reactionable_id = parentComment; replyId
        dataToPost.reactionable_id = replyId;
    }
    console.log(selectedHashtag,"red")
    dispatch(fetchAPI(POST_REACTION_CREATE, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                // dispatch(());
                if (type === "PostComment") {
                    dispatch(fetchPostComments(poss));
                }
                if (type === "CommentReply") {
                    dispatch(fetchPostReplies(parentComment));
                } else {
                    if (!selectedSlug && !selectedHashtag) {
                        dispatch(getDashboardPost());
                    }
                    if (selectedSlug) {
                        dispatch(getIndianCSRPreview());
                    }
                    if (selectedHashtag) {
                        dispatch(getHashtagData(selectedHashtag))
                    }

                }
            } else if (response.error) {
                //dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const postCommentSubmit = (newMessage, gid, postId, editFlag, setIsEditing, setClearInput) => (dispatch, getState) => {
    let APIUrl = POST_COMMENT_CREATE;
    let method = httpMethod.POST;

    let formData = new FormData();

    const hashtagsMatches = newMessage?.match(/#\[(\w+)\]|#(\w+)/g) || [];
    const hashtags = hashtagsMatches.map(tag => `#${tag.replace(/#\[(\w+)\]|#(\w+)/, "$1$2")}`);

    const userIdRegex = /@\[([^\]]+)\]\(([^)]+)\)/g;
    const userIds = [];
    let match;

    while ((match = userIdRegex.exec(newMessage)) !== null) {
        userIds.push(match[2]);
    }

    let dataToPost;
    if (editFlag) {
        APIUrl = `${POST_COMMENT_CREATE}/${gid}`;
        method = httpMethod.PUT;
        dataToPost = {
            body: newMessage,
        };

    } else {
        dataToPost = {
            post_gid: gid,
            body: newMessage,
        };
    }

    for (const key in dataToPost) {
        formData.append(key, (dataToPost[key]));
    }
    if (userIds) {
        formData.append('tagged_user_ids', JSON.stringify(userIds));
    }
    if (hashtags) {
        formData.append('hashtags', JSON.stringify(hashtags));
    }


    dispatch(fetchAPI(APIUrl, method, formData, '', true)).then(
        response => {
            if (response.success) {
                if (editFlag) {
                    dispatch(setPostComment({ data: response.data, postId: postId, id: gid, editFlag: true }));
                    dispatch(fetchPostComments(postId))
                    setIsEditing(false);
                    dispatch(reset('commentform'));
                    setClearInput(true);
                } else {
                    dispatch(addPostComment({ data: response.data, postId: gid, editFlag: false }));
                    dispatch(getDashboardPost());
                    dispatch(reset('commentform'));
                    setClearInput(true)
                }
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const postRepliesSubmit = (newMessage, gid, commentId, editFlag, setIsEditing, setClearInput) => (dispatch) => {
    let APIUrl = COMMENT_REPLY_LIST;
    let method = httpMethod.POST;
    let formData = new FormData();

    const hashtagsMatches = newMessage?.match(/#\[(\w+)\]|#(\w+)/g) || [];
    const hashtags = hashtagsMatches.map(tag => `#${tag.replace(/#\[(\w+)\]|#(\w+)/, "$1$2")}`);

    const userIdRegex = /@\[([^\]]+)\]\(([^)]+)\)/g;
    const userIds = [];
    let match;
    while ((match = userIdRegex.exec(newMessage)) !== null) {
        userIds.push(match[2]);
    }
    let dataToPost;
    if (editFlag) {
        APIUrl = `${COMMENT_REPLY_LIST}/${gid}`;
        method = httpMethod.PUT;
        dataToPost = {
            body: newMessage,
        };

    } else {
        dataToPost = {
            // comment_reply: {
            body: newMessage,      //Mandatory || String || Comment reply
            post_comment_gid: gid      //Mandatory || Integer || Post comment id
            // }
        };
    }
    for (const key in dataToPost) {
        formData.append(key, (dataToPost[key]));
    }
    if (userIds) {
        formData.append('tagged_user_ids', JSON.stringify(userIds));
    }
    if (hashtags) {
        formData.append('hashtags', JSON.stringify(hashtags));
    }

    dispatch(fetchAPI(APIUrl, method, formData, '', true)).then(
        response => {
            if (response.success) {
                if (editFlag) {
                    dispatch(setPostReply({ data: response.data, commentId: commentId, id: gid, editFlag: true }));
                    setIsEditing(false);
                    dispatch(reset('commentform'));
                    setClearInput(true)
                } else {
                    dispatch(addPostReply({ data: response.data, commentId: gid }));
                    dispatch(reset('commentform'));
                    setClearInput(true)
                }
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const createPost = (publish) => (dispatch, getState) => {
    const { socialMedia, form, master } = getState();
    let formData = new FormData();

    // to extract hastags
    const postDescription = form?.CreatePostform?.values?.post_description;
    // const hashtagsMatches = postDescription?.match(/#\[(\S+?)\]/g) || [];
    // const hashtags = hashtagsMatches.map(tag => tag.replace(/#\[(\S+?)\]/, "$1"));
    const hashtagsMatches = postDescription?.match(/#\[(\w+)\]|#(\w+)/g) || [];
    const hashtags = hashtagsMatches.map(tag => `#${tag.replace(/#\[(\w+)\]|#(\w+)/, "$1$2")}`);
    // const hashtags = hashtagsMatches.map(tag => tag.replace(/#\[(\w+)\]|#(\w+)/, "$1$2"));

    // to activity id
    const selectedActivityValue = socialMedia.selectedActivity;
    const selectedActivityObject = master.postActivity.find(activity => activity.title === selectedActivityValue);
    const selectedActivityId = selectedActivityObject ? selectedActivityObject.id : null;

    // to Company_type id
    const selectedAudienceValue = socialMedia.selectedPostAudience;
    const selectedAudienceObject = master.postTypes.find(audience => audience.value === selectedAudienceValue);
    const selectedAudienceId = selectedAudienceObject ? selectedAudienceObject.id : null;

    // to extract Mentions
    const userIdRegex = /@\[([^\]]+)\]\(([^)]+)\)/g;
    const userIds = [];
    let match;
    while ((match = userIdRegex.exec(postDescription)) !== null) {
        userIds.push(match[2]);
    }
    const formattedUserIds = `[${userIds.join(', ')}]`;

    let dataToSend = {
        // body: postDescription,
        company_type_id: selectedAudienceId,
        // status: socialMedia.postStatus,
        status: publish ? 'publish' : 'draft',
        article: socialMedia.articleCreation,
        //thematic_area_ids: socialMedia.selectedThematic,
        //hashtags: hashtags,
        // tagged_user_ids: userIds,
    }
    if (selectedActivityId !== null) {
        dataToSend.activity_type_id = selectedActivityId;
    }
    if (postDescription) {
        dataToSend.body = postDescription;
    }

    for (const key in dataToSend) {
        // formData.append(key, JSON.stringify(dataToSend[key]));
        formData.append(key, (dataToSend[key]));
    }
    formData.append('thematic_area_ids', JSON.stringify(socialMedia.selectedThematic));
    formData.append('tagged_user_ids', JSON.stringify(userIds));
    formData.append('hashtags', JSON.stringify(hashtags));

    if (Array.isArray(socialMedia.uploadedFiles)) {
        socialMedia.uploadedFiles.forEach((file, index) => {
            if (file.type.startsWith('image')) {
                formData.append(`images[]`, file);
            } else if (file.type.startsWith('video')) {
                formData.append(`video`, file);
            }
        });
    }
    dispatch(fetchAPI(CREATE_POST, httpMethod.POST, formData, '', true)).then(
        (response) => {
            if (response.success) {
                dispatch(reset('CreatePostform'));
                dispatch(setUploadedFiles([]));
                dispatch(setSelectedThematic([]));
                dispatch(setSelectedActivity(""));
                dispatch(setCreatePost(false));
                dispatch(setSelectedPostAudience("Public"));
                dispatch(setShowPostThematic(false));
                dispatch(getDashboardPost());
                dispatch(resetPostAudience());
                dispatch(resetPostTags());
                dispatch(setSnackbar({ flag: true, message: response.data.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: 'error' }));
            }
        },
    );
};
export const deletePost = (gid) => (dispatch, getState) => {
    const { socialMedia: { selectedSlug } } = getState();
    dispatch(fetchAPI(`${DELETE_POST}/${gid}`, httpMethod.DELETE)).then(
        response => {
            if (response.success) {
                if (!selectedSlug) {
                    dispatch(getDashboardPost());
                }
                if (selectedSlug) {
                    dispatch(getIndianCSRPreview());
                }
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.error?.errorMsg, type: "error" }));
            }
        },
    );
}
export const unfollow = (gid) => (dispatch, getState) => {
    const { socialMedia: { selectedSlug } } = getState();
    let dataToSend = {
        gid: gid,
    }
    dispatch(fetchAPI(UNFOLLOW, httpMethod.PUT, dataToSend)).then(
        response => {
            if (response.success) {
                if (!selectedSlug) {
                    dispatch(getDashboardPost());
                }
                if (selectedSlug) {
                    dispatch(getIndianCSRPreview());
                }
                dispatch(setSnackbar({ flag: true, message: response.data.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}
export const Follow = (gid) => (dispatch, getState) => {
    const { socialMedia: { selectedSlug } } = getState();
    let dataToSend = {
        gid: gid,
    }
    dispatch(fetchAPI(FOLLOW, httpMethod.PUT, dataToSend)).then(
        response => {
            if (response.success) {
                if (!selectedSlug) {
                    dispatch(getDashboardPost());
                }
                if (selectedSlug) {
                    dispatch(getIndianCSRPreview());
                }
                dispatch(setSnackbar({ flag: true, message: response.data.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const postBookMark = (gid) => (dispatch, getState) => {
    const { socialMedia: { selectedSlug } } = getState();
    let dataToSend = {
        bookmarkable_type: "Post",
        bookmarkable_id: gid,
    }
    // const formData = new FormData();
    // formData.append("bookmarkable_type", "Post");
    // formData.append("bookmarkable_id", gid);
    dispatch(fetchAPI(BOOKMARK, httpMethod.POST, dataToSend)).then(
        response => {
            if (response.success) {
                if (!selectedSlug) {
                    dispatch(getDashboardPost());
                }
                if (selectedSlug) {
                    dispatch(getIndianCSRPreview());
                }
                dispatch(setSnackbar({ flag: true, message: "BookMark Created Successfully", type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}
export const postRemoveBookMark = (gid) => (dispatch, getState) => {
    const { socialMedia: { selectedSlug } } = getState();
    dispatch(fetchAPI(`${UNBOOKMARK}/${gid}`, httpMethod.DELETE)).then(
        response => {
            if (response.success) {
                if (!selectedSlug) {
                    dispatch(getDashboardPost());
                }
                if (selectedSlug) {
                    dispatch(getIndianCSRPreview());
                }
                dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error, type: "error" }));
            }
        },
    );
}
export const allowNotification = (gid) => (dispatch, getState) => {
    const { socialMedia: { selectedSlug } } = getState();
    let dataToSend = {
        post_gid: gid,
    }
    dispatch(fetchAPI(ALLOW_NOTIFICATION, httpMethod.POST, dataToSend)).then(
        response => {
            if (response.success) {
                if (!selectedSlug) {
                    dispatch(getDashboardPost());
                }
                if (selectedSlug) {
                    dispatch(getIndianCSRPreview());
                }
                dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.error?.errorMsg, type: "error" }));
            }
        },
    );
}
export const removeNotification = (gid) => (dispatch, getState) => {
    const { socialMedia: { selectedSlug } } = getState();
    dispatch(fetchAPI(`${REMOVE_NOTIFICATION}/${gid}`, httpMethod.DELETE)).then(
        response => {
            if (response.success) {
                if (!selectedSlug) {
                    dispatch(getDashboardPost());
                }
                if (selectedSlug) {
                    dispatch(getIndianCSRPreview());
                }
                dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.error?.errorMsg, type: "error" }));
            }
        },
    );
}

export const getStateName = (stateIds) => (dispatch, getState) => {
    const { master: { state } } = getState();

    if (stateIds?.length > 1 && Array?.isArray(stateIds)) {
        const stateList = stateIds?.map((id) => {
            const stateObj = state?.find((data) => data?.id == id);
            if (stateObj) {
                return stateObj.name;
            }
            return '';
        });
        return stateList.join(', ');
    } else {
        const stateObj = state?.find((data) => data?.id == stateIds);
        return stateObj ? stateObj.name : '';
    }
};

export const getStateNameById = (stateId) => (dispatch, getState) => {
    const { master: { state } } = getState();
    const stateList = state?.find((state) => state?.id == stateId);
    return stateList?.value;
};


export const getDistrictNameById = (id) => (dispatch, getState) => {
    const { master: { district } } = getState();
    const districtList = district?.find((data) => data?.id === id);
    return districtList?.value;
};


export const getDistrictNameByIds = (districtIds) => (dispatch, getState) => {
    const { master: { district } } = getState();
    if (districtIds?.length > 1 && Array.isArray(districtIds)) {
        const districtList = districtIds && districtIds.map((id) => {
            const filterList = district.find((data) => data.id == id);
            if (filterList) {
                return filterList.value;
            }
        });
        const districtString = districtList.join(', ');
        return districtString.toString();
    } else {
        const filterList = district.find((data) => data.id == districtIds);
        if (filterList) {
            return filterList.value;
        }
    }
};

export const disputes = (gid, selectedArray, type) => (dispatch) => {
    let dataToSend = {
        dispute_id: gid,
        type: type, //this will be conditional for  rfp and comment
        name: selectedArray,
    }
    dispatch(fetchAPI(DISPUTES, httpMethod.POST, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(getDashboardPost());
                dispatch(setSnackbar({ flag: true, message: "Reported successfully", type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message || response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const fetchPostComments = (gid) => (dispatch) => {
    let dataToSend = {
        post_gid: gid,
    }
    dispatch(fetchAPI(COMMENT_LIST, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(setPostComment({ data: response.data, postId: gid }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}

export const fetchPostReplies = (gid) => (dispatch) => {
    let dataToSend = {
        post_comment_gid: gid,
    }
    dispatch(fetchAPI(COMMENT_REPLY_LIST, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(setPostReply({ data: response.data, commentId: gid }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}

export const deletePostComment = (id, gid) => (dispatch) => {
    dispatch(fetchAPI(`${COMMENT_LIST}/${id}`, httpMethod.DELETE)).then(
        response => {
            if (response.success) {
                dispatch(updatePostComment({ id: id, postId: gid }));
                dispatch(decreaseCommentCount(gid));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}
export const decreaseCommentCount = (postId) => (dispatch, getState) => {
    const { socialMedia: { postDashboard } } = getState();

    const updatedPosts = postDashboard.map(post => {
        if (post.gid === postId) {
            const updatedCommentCount = post.primary_post
                ? { ...post.primary_post, comment_count: post.primary_post.comment_count - 1 }
                : { ...post, comment_count: post.comment_count - 1 };

            return post.primary_post ? { ...post, primary_post: updatedCommentCount } : updatedCommentCount;
        }
        return post;
    });
    dispatch(setPostDashboard(updatedPosts));
};
export const decreaseCommentReplyCount = (commentId) => (dispatch, getState) => {
    const { socialMedia: { comments } } = getState();
    const updatedComments = comments?.map(comment => {
        if (comment.gid === commentId) {
            return {
                ...comment,
                comment_count: comment.comment_count > 0 ? comment.comment_count - 1 : 0
            };
        }
        return comment;
    });

    dispatch(setPostComment([updatedComments]));
};


export const deletePostReply = (id, gid) => (dispatch) => {
    // let dataToSend = {
    //     post_comment_id: gid,
    // }
    dispatch(fetchAPI(`${COMMENT_REPLY_LIST}/${id}`, httpMethod.DELETE)).then(
        response => {
            if (response.success) {
                dispatch(updatePostReplies({ id: id, commentId: gid }));
                // dispatch(decreaseCommentReplyCount(gid))
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.error?.errorMsg, type: "error" }));
            }
        },
    );
}

export const invitePartner = (values, setError) => (dispatch) => {
    let dataToSend = {
        connection_request: {
            requested_user_email: values.invite,          //Mandatory || String ||  User email to whom request to be send
        }
    }
    dispatch(fetchAPI(CONNECTION_REQ, httpMethod.POST, dataToSend)).then(
        response => {
            if (response.success) {
                setError('');
                dispatch(reset('Inviteform'));
                dispatch(setSnackbar({ flag: true, message: response.data.message, type: "success" }));
            } else if (response.error) {
                // setError(response.error.message)
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const searchUser = (query) => (dispatch) => {
    console.log("reps", query)
    // let encodedQuery = encodeURIComponent(`#${query}`);
    let encodedQuery = query.startsWith('#') ? encodeURIComponent(query) : query;
    let dataToSend = {
        name: encodedQuery,             //Optional || String || Search User by Name
        page: 1,                //Optional || Integer || Page no for which you want data
    }
    console.log("dataToSend", dataToSend);
    dispatch(fetchAPI(SEARCH_USER, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(setUserList(response.data?.users_list));
                dispatch(setHashtagList(response?.data?.hashtag_list));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getConnectionList = (user_types, name) => (dispatch) => {
    dispatch(setIsConnectionListLoading(true));
    const dataToPost = {}
    if (user_types) {
        dataToPost.user_types = user_types === "My Connections" ? "" : user_types;
    }
    if (name) {
        dataToPost.name = name;
    }
    dispatch(fetchAPI(CONNECTION_LIST, httpMethod.GET, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(setConnectionList(response.data));
                dispatch(setIsConnectionListLoading(false));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
                dispatch(setIsConnectionListLoading(false));
            }
        },
    );
}

export const getChatMessages = (gid, setBrandData) => (dispatch, getState) => {
    const { socialMedia: { chatMessages } } = getState();
    dispatch(setIsChatMessagesLoading(true));

    dispatch(fetchAPI(`${CONNECTION_LIST}/${gid}`, httpMethod.GET, {})).then(
        response => {
            if (response.success) {
                // const updatedMessages = [...chatMessages, ...response.data.messages];
                // dispatch(setChatMessages(updatedMessages));
                dispatch(setChatMessages(response.data.messages));
                dispatch(setActiveChannel(response.data.active_chat_channel));
                dispatch(setIsChatMessagesLoading(false));
                dispatch(getNotificationCount());
                if(setBrandData){
                    setBrandData(response.data.connection);
                }
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
                dispatch(setIsChatMessagesLoading(false));
            }
        },
    );
}


export const sendChatMessages = (message, receiver_id) => (dispatch, getState) => {
    const { socialMedia: { chatMessages }, users: { userData: { gid } } } = getState();
    const dataToPost = {
        sender_gid: gid,
        receiver_gid: receiver_id,
        message: message
    }
    dispatch(fetchAPI(CONNECTION_LIST, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                const newMessages = [...chatMessages, response.data.message]
                dispatch(setChatMessages(newMessages));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const shareEvent = (postId, selectedPostTypeId, onClose, navigate) => (dispatch, getState) => {
    const { socialMedia, form, master } = getState();
    let formData = new FormData();
    // to extract hastags
    const postDescription = form?.postReshareform?.values?.description;
    const hashtagsMatches = postDescription?.match(/#\[(\w+)\]|#(\w+)/g) || [];
    const hashtags = hashtagsMatches.map(tag => `#${tag.replace(/#\[(\w+)\]|#(\w+)/, "$1$2")}`);
    // const hashtagsMatches = postDescription.match(/#\[(\S+?)\]/g) || [];
    // const hashtags = hashtagsMatches.map(tag => tag.replace(/#\[(\S+?)\]/, "$1"));

    // to extract Mentions
    const userIdRegex = /@\[([^\]]+)\]\(([^)]+)\)/g;
    const userIds = [];
    let match;
    while ((match = userIdRegex.exec(postDescription)) !== null) {
        userIds.push(match[2]);
    }
    const formattedUserIds = `[${userIds.join(', ')}]`;
    const article = "false";
    const dataToSend = {
        shareable_id: postId,
        company_type_id: selectedPostTypeId,
        shareable_type: 'SocialEvent',
        // status: 'Publish',
        // article: article.replace(/^"|"$/g, ''),
        body: postDescription,
        tagged_user_ids: JSON.stringify(userIds),
        hashtags: JSON.stringify(hashtags),
    }
    for (const key in dataToSend) {
        formData.append(key, (dataToSend[key]));
    }
    dispatch(fetchAPI(SHARE_EVENT, httpMethod.POST, formData, "", true)).then(
        response => {
            if (response.success) {
                onClose();
                dispatch(reset('postReshareform'));
                navigate('/social-media');
                dispatch(getDashboardPost());
                dispatch(setSnackbar({ flag: true, message: response.data.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const shareBEngage = (postId, selectedPostTypeId, onClose, navigate) => (dispatch, getState) => {
    const { socialMedia, form, master } = getState();
    let formData = new FormData();
    // to extract hastags
    const postDescription = form?.postReshareform?.values?.description;
    // const hashtagsMatches = postDescription.match(/#\[(\S+?)\]/g) || [];
    // const hashtags = hashtagsMatches.map(tag => tag.replace(/#\[(\S+?)\]/, "$1"));
    const hashtagsMatches = postDescription?.match(/#\[(\w+)\]|#(\w+)/g) || [];
    const hashtags = hashtagsMatches.map(tag => `#${tag.replace(/#\[(\w+)\]|#(\w+)/, "$1$2")}`);


    // to extract Mentions
    const userIdRegex = /@\[([^\]]+)\]\(([^)]+)\)/g;
    const userIds = [];
    let match;
    while ((match = userIdRegex.exec(postDescription)) !== null) {
        userIds.push(match[2]);
    }
    const formattedUserIds = `[${userIds.join(', ')}]`;
    const article = "false";
    const dataToSend = {
        shareable_id: postId,
        company_type_id: selectedPostTypeId,
        // shareable_type: 'SocialEvent',
        // status: 'Publish',
        // article: article.replace(/^"|"$/g, ''),
        body: postDescription,
        tagged_user_ids: JSON.stringify(userIds),
        hashtags: JSON.stringify(hashtags),
    }
    for (const key in dataToSend) {
        formData.append(key, (dataToSend[key]));
    }
    dispatch(fetchAPI(SHARE_CONFERENCES, httpMethod.POST, formData, "", true)).then(
        response => {
            if (response.success) {
                onClose();
                dispatch(reset('postReshareform'));
                navigate('/social-media');
                dispatch(getDashboardPost());
                dispatch(setSnackbar({ flag: true, message: response.data.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const sharePost = (postId, onClose, navigate) => (dispatch, getState) => {
    const { socialMedia, form, master } = getState();
    let formData = new FormData();
    // to extract hastags
    const postDescription = form?.postReshareform?.values?.description;
    // const hashtagsMatches = postDescription.match(/#\[(\S+?)\]/g) || [];
    // const hashtags = hashtagsMatches.map(tag => tag.replace(/#\[(\S+?)\]/, "$1"));
    const hashtagsMatches = postDescription?.match(/#\[(\w+)\]|#(\w+)/g) || [];
    const hashtags = hashtagsMatches.map(tag => `#${tag.replace(/#\[(\w+)\]|#(\w+)/, "$1$2")}`);

    // to activity id
    const selectedActivityValue = socialMedia.selectedActivity;
    const selectedActivityObject = master.postActivity.find(activity => activity.title === selectedActivityValue);
    const selectedActivityId = selectedActivityObject ? selectedActivityObject.id : null;

    // to Company_type id
    const selectedAudienceValue = socialMedia.selectedPostAudience;
    const selectedAudienceObject = master.postTypes.find(audience => audience.value === selectedAudienceValue);
    const selectedAudienceId = selectedAudienceObject ? selectedAudienceObject.id : null;

    // to extract Mentions
    const userIdRegex = /@\[([^\]]+)\]\(([^)]+)\)/g;
    const userIds = [];
    let match;
    while ((match = userIdRegex.exec(postDescription)) !== null) {
        userIds.push(match[2]);
    }
    const formattedUserIds = `[${userIds.join(', ')}]`;
    const article = "false";
    const dataToSend = {
        shareable_id: postId,
        // company_type_id: selectedPostTypeId,
        company_type_id: selectedAudienceId,
        status: 'Publish',
        body: postDescription,
        // tagged_user_ids: userIds,
        // thematic_area_ids: socialMedia.selectedThematic,
        // hashtags: hashtags,
        shared_with_thoughts: "true"
    }
    if (selectedActivityId !== null) {
        dataToSend.activity_type_id = selectedActivityId;
    }
    for (const key in dataToSend) {
        formData.append(key, (dataToSend[key]));
    }
    formData.append('hashtags', JSON.stringify(hashtags));
    formData.append('tagged_user_ids', JSON.stringify(userIds));
    //formData.append('thematic_area_ids', JSON.stringify(socialMedia.selectedThematic));
    dispatch(fetchAPI(SHARE_POST, httpMethod.POST, formData, "", true)).then(
        response => {
            if (response.success) {
                onClose();
                dispatch(reset('postReshareform'));
                dispatch(setSelectedThematic([]));
                dispatch(setSelectedActivity(""));
                dispatch(setCreatePost(false));
                dispatch(setPostCategoryEdit(false));

                if (socialMedia?.selectedSlug) {
                    navigate(`/social-media`);
                    window.location.reload();
                }
                dispatch(getDashboardPost());
                setTimeout(() => {
                    window.scrollTo(0, 0);
                }, 500);
                // dispatch(setSnackbar({ flag: true, message: response.data.message, type: "success" }));
                dispatch(setSnackbar({ flag: true, message: "Post shared successfully!!", type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const reshareWithoutTextPost = (postId, navigate) => (dispatch, getState) => {
    const { socialMedia, form, master } = getState();
    const dataToSend = {
        post_gid: postId,
        shared_with_thoughts: "false"
    }
    dispatch(fetchAPI(SHARE_POST, httpMethod.POST, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(setSelectedThematic([]));
                dispatch(setSelectedActivity(""));
                dispatch(setCreatePost(false));

                dispatch(setPostCategoryEdit(false));
                if (socialMedia?.selectedSlug) {
                    navigate(`/social-media`);
                    window.location.reload();
                }
                dispatch(getDashboardPost());
                // window.scrollTo({ top: 0, behavior: 'smooth' })
                window.scrollTo(0, 0);
                // dispatch(setSnackbar({ flag: true, message: response.data.message, type: "success" }));
                dispatch(setSnackbar({ flag: true, message: "Post shared successfully.", type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const editPost = (gid, onClose, navigate) => (dispatch, getState) => {
    const { socialMedia, form, master, selectedSlug } = getState();
    let formData = new FormData();

    // to extract hastags
    const postDescription = form?.CreatePostform?.values?.post_description;
    const formattedPostDescription = postDescription
        .replace(/\r\n/g, '\n');
    const hashtagsMatches = postDescription?.match(/#\[(\w+)\]|#(\w+)/g) || [];
    const hashtags = hashtagsMatches.map(tag => `#${tag.replace(/#\[(\w+)\]|#(\w+)/, "$1$2")}`);
    // const hashtagsMatches = postDescription?.match(/#\[(\S+?)\]/g) || [];
    // const hashtags = hashtagsMatches.map(tag => tag.replace(/#\[(\S+?)\]/, "$1"));

    // to activity id
    const selectedActivityValue = socialMedia.selectedActivity;
    const selectedActivityObject = master.postActivity.find(activity => activity.title === selectedActivityValue);
    const selectedActivityId = selectedActivityObject ? selectedActivityObject.id : null;

    // to Company_type id
    const selectedAudienceValue = socialMedia.selectedPostAudience;
    const selectedAudienceObject = master.postTypes.find(audience => audience.value === selectedAudienceValue);
    const selectedAudienceId = selectedAudienceObject ? selectedAudienceObject.id : null;

    // to extract Mentions
    const userIdRegex = /@\[([^\]]+)\]\(([^)]+)\)/g;
    const userIds = [];
    let match;
    while ((match = userIdRegex.exec(postDescription)) !== null) {
        userIds.push(match[2]);
    }
    const formattedUserIds = `[${userIds.join(', ')}]`;
    let dataToSend = {
        // body: postDescription,
        body: formattedPostDescription,
        company_type_id: selectedAudienceId,
        // status: socialMedia.postStatus,
        // article: socialMedia.articleCreation,
        thematic_area_ids: socialMedia.selectedThematic,
        hashtags: hashtags,
        tagged_user_ids: userIds,
    }
    if (selectedActivityId !== null) {
        dataToSend.activity_type_id = selectedActivityId;
    }
    // socialMedia.selectedThematic.forEach(thematicId => {
    //     formData.append('thematic_area_ids[]', thematicId);
    // });
    for (const key in dataToSend) {
        formData.append(key, JSON.stringify(dataToSend[key]));
    }
    if (Array.isArray(socialMedia.uploadedFiles)) {
        socialMedia.uploadedFiles.forEach((file, index) => {

            if (file.type.startsWith('image')) {
                formData.append(`images[]`, file);
            } else if (file.type.startsWith('video')) {
                formData.append(`video`, file);
            }
        });
    }
    dispatch(fetchAPI(`${CREATE_POST}/${gid}`, httpMethod.PUT, formData, '', true)).then(
        (response) => {
            if (response.success) {
                dispatch(reset('CreatePostform'));
                dispatch(setUploadedFiles([]));
                dispatch(setSelectedThematic([]));
                dispatch(setSelectedActivity(""));
                dispatch(setCreatePost(false));
                dispatch(setSelectedPostAudience("Public"));
                // dispatch(getDashboardPost());
                if (!socialMedia?.selectedSlug) {
                    dispatch(getDashboardPost());
                }
                if (socialMedia?.selectedSlug) {
                    dispatch(getIndianCSRPreview());
                }
                if (socialMedia?.selectedHashtag) {
                    navigate('/social-media')
                    dispatch(resetSelectedHashtag());
                }

                dispatch(resetShowPostThematics())
                dispatch(resetPostEdit())
                onClose();
                dispatch(setSnackbar({ flag: true, message: response.data.message, type: 'success' }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: 'error' }));
            }
        },
    );
};

export const shareCertificatePost = (gid, onClose, navigate) => (dispatch, getState) => {
    const { socialMedia, form, master } = getState();
    let formData = new FormData();

    // to extract hastags
    const postDescription = form?.CreatePostform?.values?.post_description;
    const hashtagsMatches = postDescription.match(/#\[(\S+?)\]/g) || [];
    const hashtags = hashtagsMatches.map(tag => tag.replace(/#\[(\S+?)\]/, "$1"));

    // to activity id
    const selectedActivityValue = socialMedia.selectedActivity;
    const selectedActivityObject = master.postActivity.find(activity => activity.title === selectedActivityValue);
    const selectedActivityId = selectedActivityObject ? selectedActivityObject.id : null;

    // to Company_type id
    const selectedAudienceValue = socialMedia.selectedPostAudience;
    const selectedAudienceObject = master.postTypes.find(audience => audience.value === selectedAudienceValue);
    const selectedAudienceId = selectedAudienceObject ? selectedAudienceObject.id : null;

    // to extract Mentions
    const userIdRegex = /@\[([^\]]+)\]\(([^)]+)\)/g;
    const userIds = [];
    let match;
    while ((match = userIdRegex.exec(postDescription)) !== null) {
        userIds.push(match[2]);
    }
    const formattedUserIds = `[${userIds.join(', ')}]`;
    let dataToSend = {
        body: postDescription,
        company_type_id: selectedAudienceId,
        shareable_type: 'EventCertificate',
        // status: socialMedia.postStatus,
        // article: socialMedia.articleCreation,
        thematic_area_ids: JSON.stringify(socialMedia.selectedThematic),
        hashtags: JSON.stringify(hashtags),
        tagged_user_ids: JSON.stringify(userIds),
    }
    if (selectedActivityId !== null) {
        dataToSend.activity_type_id = selectedActivityId;
    }
    dataToSend.shareable_id = gid;
    // socialMedia.selectedThematic.forEach(thematicId => {
    //     formData.append('thematic_area_ids[]', thematicId);
    // });
    for (const key in dataToSend) {
        formData.append(key, (dataToSend[key]));
    }
    // if (Array.isArray(socialMedia.uploadedFiles)) {
    //     socialMedia.uploadedFiles.forEach((file, index) => {

    //         if (file.type.startsWith('image')) {
    //             formData.append(`images[]`, file);
    //         } else if (file.type.startsWith('video')) {
    //             formData.append(`video`, file);
    //         }
    //     });
    // }
    dispatch(fetchAPI(SHARE_EVENT, httpMethod.POST, formData, '', true)).then(
        (response) => {
            if (response.success) {
                dispatch(reset('CreatePostform'));
                dispatch(setUploadedFiles([]));
                dispatch(setSelectedThematic([]));
                dispatch(setSelectedActivity(""));
                dispatch(setCreatePost(false));
                dispatch(setSelectedPostAudience("Public"));
                navigate('/social-media');
                dispatch(getDashboardPost());
                onClose();
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: 'error' }));
            }
        },
    );
};
export const deletePostImages = (gid, postId) => (dispatch) => {

    const dataToPost = {
        gid: postId,
        attachment_gid: gid,
    }
    dispatch(fetchAPI(DELETE_POST_IMAGE, httpMethod.DELETE, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(getDraftPost())
                dispatch(getDashboardPost());
                dispatch(setSnackbar({ flag: true, message: response.message.data, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}
export const getDraftPost = () => (dispatch) => {
    dispatch(fetchAPI(DRAFT_POST, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setDraftPost(response?.data));
                //dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
            } else if (response.error) {
                // dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}

export const getHashtagData = (hashtag, navigate, offset = 10, loading, setLoading, isFecthing) => (dispatch, getState) => {
    const { socialMedia: { postDashboard, bookmarkType, notificationPostType, notificationPostGid, myFeed, selectedHashtag }, users: { isLoggedIn } } = getState();
    let convertedPage = Math.ceil(offset / 10);
    if (loading) {
        setLoading(true);
        isFecthing.current = true;
    }
    const formattedHashtag = `#${hashtag}`;
    console.log('Formatted hashtag:', hashtag);
    const encodedHashtag = encodeURIComponent(formattedHashtag);
    const dataToPost = {
        page: convertedPage,
        hashtag: encodedHashtag,
    }
    dispatch(fetchAPI(DASHBOARD_HASHTAGS, httpMethod.GET, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(setIsPostLoading(false));
                dispatch(setPostHashtag(response?.hashtag_data))
                // if (response?.hashtag_data?.hashtag_follow === true) {
                loading ? dispatch(setPostDashboard([...postDashboard, ...response.data])) : dispatch(setPostDashboard(response?.data));
                // } else {
                //     dispatch(setPostDashboard([]));
                // }
                // dispatch(setPostDashboard(response.data));
                dispatch(setTotalPostCount(response.total_post_count))
                if (loading) {
                    setLoading(false);
                    isFecthing.current = false;
                }
                // dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
            } else if (response.error) {
                if (loading) {
                    setLoading(false);
                    isFecthing.current = false;
                }
                if (response.error.errorCode === 422 && response.error.errorMsg === "Post not found wiht this hashtag") {
                    navigate('/page-not-found');
                } else {
                    dispatch(setSnackbar({ flag: true, message: response?.error?.errorMsg, type: "error" }));
                }
            }
        },
    );
}
export const getShareData = (gid) => (dispatch) => {
    const dataToSend = {
        gid: gid,
    }

    dispatch(fetchAPI(`${SHARE_TOTAL}`, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(setShareData(response.data));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const deleteReaction = (Id) => (dispatch,getState) => {
    const { socialMedia: { selectedSlug, selectedHashtag } } = getState();
    if (!Id) {
        return;
    }
    const deleteId = Id;
    dispatch(fetchAPI(`${POST_ALL_REACTION}/${deleteId}`, httpMethod.DELETE)).then(
        response => {
            if (response.success) {
                // dispatch(getDashboardPost());
                if (!selectedSlug && !selectedHashtag) {
                    dispatch(getDashboardPost());
                }
                if (selectedSlug) {
                    dispatch(getIndianCSRPreview());
                }
                if (selectedHashtag) {
                    dispatch(getHashtagData(selectedHashtag))
                }
                dispatch(setSnackbar({ flag: true, message: response.message.data, type: "success" }));
            } else if (response.error) {
                // dispatch(setSnackbar({ flag: true, message: response.error, type: "error" }));
            }
        },
    );
}
export const deleteReactionComment = (type, comsId, PostCommentGId, Variant) => (dispatch) => {
    const deleteId = comsId;
    dispatch(fetchAPI(`${POST_ALL_REACTION}/${deleteId}`, httpMethod.DELETE)).then(
        response => {
            if (response.success) {
                dispatch(getDashboardPost());
                if (Variant === "PostComment") {
                    dispatch(fetchPostComments(PostCommentGId));
                }
                dispatch(setSnackbar({ flag: true, message: response.message.data, type: "success" }));
            } else if (response.error) {
                //dispatch(setSnackbar({ flag: true, message: response.error, type: "error" }));
            }
        },
    );
}
export const deleteReactionReply = (type, replyId, PostReplyGId, Variant) => (dispatch) => {
    const deleteId = replyId;
    dispatch(fetchAPI(`${POST_ALL_REACTION}/${deleteId}`, httpMethod.DELETE)).then(
        response => {
            if (response.success) {
                dispatch(getDashboardPost());
                if (Variant === "CommentReply") {
                    dispatch(fetchPostReplies(PostReplyGId));
                }
                dispatch(setSnackbar({ flag: true, message: response.message.data, type: "success" }));
            } else if (response.error) {
                //dispatch(setSnackbar({ flag: true, message: response.error, type: "error" }));
            }
        },
    );
}

export const getSocialRfp = (page, category, sortBy) => (dispatch) => {
    const dataToPost = {
        page: page,
        ...(category && { categories: category }),
        // categories: category, 
        ...(sortBy && { sort_by: sortBy, })

    }
    dispatch(fetchAPI(SOCIAL_RFP_LIST, httpMethod.GET, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(setSocialRfpList(response?.data));
                // dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
            } else if (response.error) {
                // dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}
export const getDashboardRfp = () => (dispatch) => {

    dispatch(fetchAPI(DASHBOARD_RFP_LIST, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setSocialDashboardList(response?.data));
                // dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
            } else if (response.error) {
                // dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}
export const getSocialRfpPreview = (gid, navigate) => (dispatch) => {
    let apiUrl = `${SOCIAL_RFP_PREVIEW}/${gid}`
    dispatch(fetchAPI(apiUrl, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setDataToStore(response.data));
                // dispatch(setSocialDashboardList(response?.data));
                // dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
            } else if (response.error) {
                if (response.error.errorCode === 422 && response.error.errorMsg === "No RFP is associated with this gid!") {
                    navigate('/page-not-found');
                } else {
                    // dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
                }
            }
        },
    );
}
export const getRFPSave = (gid) => (dispatch) => {
    const dataToPost = {
        gid: gid,
    }

    dispatch(fetchAPI(RFP_SAVE, httpMethod.PUT, dataToPost)).then(
        response => {
            if (response.success) {
                //dispatch(setSocialDashboardList(response?.data));
                dispatch(setSnackbar({ flag: true, message: "Rfp saved successfully.", type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.error?.errorMsg, type: "error" }));
            }
        },
    );
}
export const shareRfpPost = (gid, selectedPostTypeId, onClose, navigate) => (dispatch, getState) => {
    const { form, } = getState();
    let formData = new FormData();
    // to extract hastags
    const postDescription = form?.postReshareform?.values?.description;
    // const hashtagsMatches = postDescription.match(/#\[(\S+?)\]/g) || [];
    // const hashtags = hashtagsMatches.map(tag => tag.replace(/#\[(\S+?)\]/, "$1"));
    const hashtagsMatches = postDescription?.match(/#\[(\w+)\]|#(\w+)/g) || [];
    const hashtags = hashtagsMatches.map(tag => `#${tag.replace(/#\[(\w+)\]|#(\w+)/, "$1$2")}`);

    // to extract Mentions
    const userIdRegex = /@\[([^\]]+)\]\(([^)]+)\)/g;
    const userIds = [];
    let match;
    while ((match = userIdRegex.exec(postDescription)) !== null) {
        userIds.push(match[2]);
    }
    const formattedUserIds = `[${userIds.join(', ')}]`;
    const dataToSend = {
        shareable_id: gid,
        company_type_id: selectedPostTypeId,
        body: postDescription,
        tagged_user_ids: userIds,
        // hashtags: hashtags,
    }
    for (const key in dataToSend) {
        formData.append(key, (dataToSend[key]));
    }
    formData.append('hashtags', JSON.stringify(hashtags));
    // for (const key in dataToSend) {
    //     formData.append(key, JSON.stringify(dataToSend[key]));
    // }

    dispatch(fetchAPI(SHARE_RFP, httpMethod.POST, formData, "", true)).then(
        response => {
            if (response.success) {
                // dispatch(getDashboardPost());
                dispatch(reset('CreatePostform'));
                navigate(`/social-media`)
                onClose();
                // dispatch(setSnackbar({ flag: true, message: response.data.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const setDataToStore = (data) => (dispatch, getState) => {
    if (data) {
        const {
            indicative_budget,
            submission_date,
            tenureMonths,
            title,
            tenure,
            gid,
            body,
            partner_experience,
            rfp_thematic_areas,
            rfpResponse,
            created_at,
            prepared_by_name,
            prepared_by_address,
            tenure_type,
            show_budget,
            rfp_geographic_locations,
            rfp_queries,
            save_to_rfp,
            self_rfp,
            responded_at,
            rfp_preselected_queries,
        } = data?.rfp;
        //const showBudgetValue = show_budget === 1 ? "Yes" : "No";
        // console.log('red',showBudgetValue)
        const extractedQueries = [];
        const extractedPredefineQueries = [];
        if (rfp_queries && Array.isArray(rfp_queries)) {
            rfp_queries.forEach((queryItem) => {
                const { query_gid, query } = queryItem;
                const { question, type, options } = query;
                extractedQueries.push({
                    query_gid,
                    question,
                    type,
                    options: Array.isArray(options) ? options : [],
                });
            });
        }

        if (rfp_preselected_queries && Array.isArray(rfp_preselected_queries)) {
            rfp_preselected_queries.forEach((queryItem) => {
                const { query_gid, query, rfp_questionnaire_gid } = queryItem;
                const parsedQuery = JSON?.parse(query);
                const { question, type, options } = parsedQuery;
                extractedPredefineQueries.push({
                    gid: rfp_questionnaire_gid,
                    query_gid,
                    question,
                    type,
                    options: Array.isArray(options) ? options : [],
                });
            });
        }
        const thematicAreaIds = rfp_thematic_areas.map((area) => area.thematic_area_id);
        dispatch(
            setGlimpseFormData({
                thematicArea: thematicAreaIds,
                budget: indicative_budget,
                submissionDate: submission_date,
                project_Tenure: tenure,
                title: title,
                gid: gid,
                project_Tenure_dropdown: tenure_type,
                partnerExp: partner_experience,
                // show_budget: show_budget?.toString(),
                budget_private: show_budget,
                customQuestion: extractedQueries,
                geoLocation: rfp_geographic_locations,
                prepared_by: prepared_by_name,
                self_rfp: self_rfp,
                responded_at: responded_at,
                prepared_by_address: prepared_by_address,
            })
        );

        dispatch(setDetails({ description: body, }));
        dispatch(setRfpStatus(save_to_rfp))
        //   //dispatch(updateLocation(rfp_geographic_locations || []));
        dispatch(setSelectedSampleQuestion(extractedPredefineQueries));
    }
};
export const getBengageList = (offset = 10, loading, setLoading) => (dispatch, getState) => {
    const { socialMedia: { bEngageList } } = getState();
    dispatch(setBEngangeIsLoad(true));
    loading && setLoading(true)
    // Logic to convert multiples of 10 to 1, 2, 3, ...
    let convertedPage = Math.ceil(offset / 10);
    let newData = {
        page: convertedPage,
    };

    return dispatch(fetchAPI(CONFERENCES_LIST, httpMethod.GET, newData)).then(
        response => {
            if (response.success) {
                loading ? dispatch(setBEngageList([...bEngageList, ...response.data])) : dispatch(setBEngageList(response?.data));
                // dispatch(setBEngageList(response?.data))
                dispatch(setEngageTotalCount(response?.total_count));
                dispatch(setBEngangeIsLoad(false));
                return response.data;
            } else if (response?.error) {
                dispatch(setBEngangeIsLoad(false));
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        }
    );
};
export const getDasboardList = () => (dispatch, getState) => {

    return dispatch(fetchAPI(DASHBOARD_LIST_MAIN, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setBengageDashboardList(response.data))
                // return response.data;
            } else if (response?.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        }
    );
};

export const sendEticket = (file, gid, type) => (dispatch, getState) => {
    const formData = new FormData();
    formData.append('qr_code', file);
    formData.append(type, gid);

    return dispatch(fetchAPI(`${NOMINATION}/e_ticket_qr_code_save`, httpMethod.PUT, formData, '', true)).then(
        response => {
            if (response.success) {
                // dispatch(setBengageDashboardList(response.data))
                // return response.data;
            } else if (response?.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        }
    );
};
export const getSharedPostList = (gid) => (dispatch) => {
    const dataToPost = {
        post_gid: gid,
    }
    dispatch(fetchAPI(My_SHARED_POST_LIST, httpMethod.GET, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(setSharedList(response?.data));
                //dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}
export const followHashtag = (hashtag, navigate) => (dispatch) => {
    console.log("hashtag", hashtag)
    const dataToPost = {
        name: `#${hashtag}`,
    }

    dispatch(fetchAPI(FOLLOWHASHTAG, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(getHashtagData(hashtag, navigate))
                //dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.error?.errorMsg, type: "error" }));
            }
        },
    );
}
export const unFollowHashtag = (hashtag, navigate) => (dispatch) => {
    // const hash = `#${hashtag}`
    const hash = encodeURIComponent(`#${hashtag}`);
    const apiUrl = `${UNFOLLOWHASHTAG}/${hash}`
    dispatch(fetchAPI(apiUrl, httpMethod.DELETE)).then(
        response => {
            if (response.success) {
                dispatch(getHashtagData(hashtag, navigate))
                //dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}

export const disputesHashtag = (gid, selectedArray, type, hash) => (dispatch) => {
    let dataToSend = {
        hashtag_name: `#${hash}`,
        type: type,
        name: selectedArray,
    }
    dispatch(fetchAPI(DISPUTES, httpMethod.POST, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(setSnackbar({ flag: true, message: "Reported successfully", type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message || response.error.errorMsg, type: "error" }));
            }
        },
    );
}


export const setUserChatStatus = (status) => (dispatch) => {
    let dataToSend = {
       self_update: true,
       status:status
    }
    dispatch(fetchAPI(UPDATE_USER_STATUS, httpMethod.POST, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(setSnackbar({ flag: true, message: "Status updated successfully.", type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}