import React, { useEffect, useState } from 'react';
import { Box, Dialog, Divider, Grid, IconButton, Paper, Popover, Tooltip, tooltipClasses, useMediaQuery } from '@mui/material';
import theme from '../../../../../assets/material-ui/theme';
import SocialMediaCSS from "../../../SocialMediaCSS/SocialMedia.module.css";
import { Typography } from '@material-ui/core';
import CloseIcon from "../../../../../assets/icons/closeDialogue.svg"
import CommonInitials from '../../../../../common/components/CommonInitials';
import ReduxForm from '../../../../../common/components/ReduxForm';
import CommonInput from '../../../../../common/components/CommonInput';
import CommonButton2 from '../../../../../common/components/CommonButton2';
import share from "../../../../../assets/icons/shareWhite.svg";
import { convertHashTagToLink, convertMentionsToLinks, formatDateString2, getCompanyTypeByName, getThematicNameById, isJSON } from '../../../../../common/utils';
import DownArrow from "../../../../../assets/icons/DownArrow.svg";
import PostMedia from '../../Posts/PostMedia';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/styles';
import tag from "../../../../../assets/icons/tag.svg";
import activity from "../../../../../assets/icons/activity.svg";
import { getDistrictNameById, shareBEngage, shareEvent, sharePost } from '../../../actions';
import { getDistrictNameByIds, getStateNameById } from '../../../../Profile/actions';
import { useNavigate } from 'react-router';
import { parseEmptySpaces } from "../../../../../common/utils";
import { resetDraftAndActivity, resetPostAudience, resetPostTags, resetSelectedPostAudience, resetSelectedSlug, setPostActivityEdit, setPostAudienceEdit, setPostCategoryEdit, setPostTagPeopleEdit, setSelectedActivity, setSelectedPostAudience } from '../../../../../store/slices/SocialMediaSlice';
import { change, reset } from 'redux-form';
import Grateful from "../../../../../assets/icons/Grateful.svg";
import Attend from "../../../../../assets/icons/Attending.svg";
import support from "../../../../../assets/icons/support.svg";
import Celeberating from "../../../../../assets/icons/celebrating.svg";
import Participate from "../../../../../assets/icons/Participate.svg";
import global from "../../../../../assets/icons/global.svg";
import ceo from "../../../../../assets/icons/ceo 1.svg";
import ngo from "../../../../../assets/icons/non-profit-organization 2.svg";
import vlog from "../../../../../assets/icons/vlog 2.svg";
import employee from "../../../../../assets/icons/employee 1.svg";
import csr from "../../../../../assets/icons/CSR consulting.svg";
import DiscardAlert from '../DiscardAlert';


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.mainPalette.grey.white,
        color: theme.mainPalette.grey.lightText,
        boxShadow: theme.shadow.softShadow3,
        padding: '8px'
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.mainPalette.grey.white,
    },
}));
export const ResharePost = ({ open, onClose, Title, postId, master, user, post, form, isEvent, eventData, bEngageData, isBEngage, socialMedia, usersImg, eventGid, selectedActivity, setSelectedPostTitle, setSelectedActivity1, selectedTag, selectedPostTitle, eventThematic, unSelectedTag, setSelectedTag }) => {
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleDlgClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }
        onClose();
    }
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [postDiscardOpen, setPostDiscardOpen] = useState(false);
    const [textExceeded, setTextExceeded] = useState(false);
    const [selectedPostType, setSelectedPostType] = useState(master?.postTypes?.[0] || {});
    const opens = Boolean(anchorEl);
    const postMorePopover = opens ? 'simple-popover' : undefined;
    const postBody = post?.body && convertHashTagToLink(convertMentionsToLinks(isJSON(post?.body) ? JSON.parse((post?.body)) : post?.body, SocialMediaCSS.contentHashtag), SocialMediaCSS.contentHashtag);
    const tags = master?.hashtags?.hashtags;
    const users = master?.mentions;

    const handleDiscardDialogClose = () => {
        setPostDiscardOpen(false);
    };
    const handleDiscardDialogOpen = () => {
        setPostDiscardOpen(true);
    };

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handlePostTypeSelect = (postType) => {
        setSelectedPostType(postType);
        handlePopoverClose();
    };
    const handleResharePost = () => {
        const selectedPostTypeId = selectedPostType.id
        if (isEvent) {
            dispatch(shareEvent(postId, selectedPostTypeId, onClose, navigate));
        } else if (isBEngage) {
            dispatch(shareBEngage(postId, selectedPostTypeId, onClose, navigate));
        } else {
            dispatch(sharePost(postId, selectedPostTypeId, onClose, navigate));
        }
    };
    const handleReshare = () => {
        dispatch(setSelectedActivity(selectedActivity));
        dispatch(setSelectedPostAudience(selectedPostTitle));
        dispatch(sharePost(postId, onClose, navigate));
    };
    const handleExceedLimit = (exceeded) => {
        if (exceeded) {
            setTextExceeded(true)
        } else {
            setTextExceeded(false)
        }
    };
    const handleActivityClick = () => {
        dispatch(setPostActivityEdit(true))
    };
    const handleTagClick = () => {
        dispatch(setPostTagPeopleEdit(true))

    };
    const handlePostAudience = () => {
        dispatch(setPostAudienceEdit(true))
    }
    const handleCancelClick = () => {
        dispatch(reset('postReshareform'));
        setSelectedTag('')
        handleDiscardDialogClose();
        onClose();
        dispatch(resetPostTags());
        dispatch(resetDraftAndActivity());
        dispatch(resetSelectedPostAudience());
        setSelectedPostTitle("Public")
        setSelectedActivity1('')

    }
    const handleNextClick = () => {
        dispatch(setSelectedActivity(selectedActivity));
        dispatch(setSelectedPostAudience(selectedPostTitle));
        dispatch(setPostCategoryEdit(true))
    }
    const activityImages = { "Supporting": support, "Attending": Attend, "Grateful": Grateful, "Celebrating": Celeberating, "Participating": Participate };
    const PostImages = { "Public": global, "Govt. Agency": employee, "Non Profit | NGO": ngo, "Corporate/Company": ceo, "CSR | ESG Expert": csr, "Employees | Volunteer | Individual": vlog, };

    const selectedImage = activityImages[selectedActivity];
    // const selectedPostImage = PostImages[selectedPostTitle];
    const selectedAudienceObject = master?.postTypes?.find(audience => audience?.value === selectedPostTitle);
    const selectedAudienceImage = selectedAudienceObject ? selectedAudienceObject.image : null;
    //console.log("reel",post)
    const postTypeName = post?.primary_post?.post_type_name;
    const postTypeObj = master?.postTypes?.find(type => type.key === postTypeName);
    const postTypeImage = postTypeObj ? postTypeObj.image : '';
    const postValueObj = master?.postTypes?.find(type => type.key === postTypeName);
    const postTypeTitle = postValueObj ? postValueObj.value : '';
    //console.log(post?.shared_with_thoughts, "rft")
    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleDlgClose}
                aria-labelledby="Share Post"
                PaperProps={{ classes: { root: SocialMediaCSS.sharePopup } }}
            >
                <Box display={'flex'} flexDirection={'column'} rowGap={1}>
                    <Box className={SocialMediaCSS.postHeader}>
                        <Box className={SocialMediaCSS.postHeaderLogo}>
                            <CommonInitials
                                name={user?.userData?.initials}
                                bgColor={user?.userData?.color_code}
                                divStyle={{ padding: '0px', maxHeight: '135px', maxWidth: '132px', minHeight: '46px', minWidth: '46px', borderRadius: '50%', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)", cursor: 'pointer' }}
                                typoStyle={{ fontSize: '17px', lineHeight: '45px', }}
                                avatarStyle={{ width: '46px', height: '46px', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)" }}
                                src={user?.userData?.profile_pic}
                                companyLogo={user?.userData?.profile_pic}
                                avatar={true}
                            />
                        </Box>
                        {/* <Box className={SocialMediaCSS.postHeaderInfo}>
                        <Typography className={SocialMediaCSS.postReshareTitle} >
                            {user?.userData?.name}
                        </Typography>
                        <Box className={`${SocialMediaCSS.cursor} ${SocialMediaCSS.f} ${SocialMediaCSS.ai_center}`} gap={1} aria-describedby={postMorePopover} onClick={handlePopoverOpen}>
                            <img src={selectedPostType.image} className={SocialMediaCSS.headAudi} />
                            <Typography className={SocialMediaCSS.postTypeReshare} >
                                {selectedPostType.value}
                            </Typography>
                            <img src={DownArrow} />
                        </Box>
                    </Box> */}
                        <Box className={SocialMediaCSS.postHeaderInfo}>
                            <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.ai_center}`}>
                                <Typography className={SocialMediaCSS.organisaion}>
                                    {usersImg?.userData?.name}
                                </Typography>&nbsp;
                                {selectedActivity &&
                                    <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.ai_center}`} sx={{ gap: '5px' }}>
                                        <Typography className={SocialMediaCSS.organisaion}> -</Typography>
                                        <img src={selectedImage} className={SocialMediaCSS.createImgActivity} />
                                        <Typography className={SocialMediaCSS.organisaion}>{selectedActivity}</Typography>
                                    </Box>}
                            </Box>
                            <Box className={`${SocialMediaCSS.createBox}`} onClick={handlePostAudience}>
                                {selectedPostTitle ? (
                                    <img src={selectedAudienceImage} className={SocialMediaCSS.headAudi} />
                                ) : (
                                    <img src={global} className={SocialMediaCSS.headAudi} />
                                )}
                                <Typography className={SocialMediaCSS.postTypeReshare}>
                                    {selectedPostTitle}
                                </Typography>
                                <img src={DownArrow} />
                            </Box>
                        </Box>
                        <IconButton onClick={() => handleDiscardDialogOpen()} sx={{ alignSelf: 'start' }}>
                            <img src={CloseIcon} alt='Close Dialog' />
                        </IconButton>
                    </Box>
                    <Box pt={2.4}>
                        <ReduxForm
                            form="postReshareform"
                            label="postReshare"
                            submitButton="Next">
                            <Grid container>
                                <Grid item xs={12} mt={-1.2}>
                                    <CommonInput
                                        name="description"
                                        placeholder="Write about this post"
                                        type="socialInput"
                                        users={users}
                                        tags={tags}
                                        maxLength={'5000'}
                                        showCount={true}
                                        countFontSize="12px"
                                        rows="6"
                                        onExceedLimit={handleExceedLimit}
                                        // normalize={parseEmptySpaces}
                                        selectedTags={selectedTag}
                                        unSelectedTag={unSelectedTag}
                                    />
                                </Grid>
                            </Grid>
                        </ReduxForm>
                    </Box>
                    <Box className={`${SocialMediaCSS.postReshareCard} ${SocialMediaCSS.scrollable}`}>
                        <Box className={SocialMediaCSS.postHeader}>
                            <Box className={SocialMediaCSS.postHeaderLogo}>
                                <CommonInitials
                                    name={post?.primary_post?.post_user_details?.initials || eventData?.created_by_initials || bEngageData?.created_by?.initials}
                                    bgColor={post?.primary_post?.post_user_details?.colour_code || eventData?.created_by_colour_code || bEngageData?.created_by?.colour_code}
                                    divStyle={{ padding: '0px', maxHeight: '135px', maxWidth: '132px', minHeight: '46px', minWidth: '46px', borderRadius: '50%', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)", cursor: 'pointer' }}
                                    typoStyle={{ fontSize: '16px', lineHeight: '45px', }}
                                    avatarStyle={{ width: '46px', height: '46px', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)" }}
                                    src={post?.primary_post?.post_user_details?.image?.url || eventData?.created_by_logo || bEngageData?.created_by?.profile_image}
                                    companyLogo={post?.primary_post?.post_user_details?.image?.url || eventData?.created_by_logo || bEngageData?.created_by?.profile_image}
                                    avatar={true}
                                />
                            </Box>
                            <Box className={SocialMediaCSS.postHeaderInfo}>
                                <Typography className={SocialMediaCSS.postReshareTitle} >{post?.primary_post?.post_user_details?.name || eventData?.created_by || bEngageData?.created_by?.name}</Typography>
                                <Box className={SocialMediaCSS.f} gap={2}>
                                    <Typography className={SocialMediaCSS.postTypeReshare}> {post?.primary_post?.profile_type && post.primary_post.user_personal_type || dispatch(getCompanyTypeByName(bEngageData?.created_by?.user_type)) || dispatch(getCompanyTypeByName(eventData?.company_details?.company_type))}</Typography>
                                    {/* <Typography className={SocialMediaCSS.postTypeReshare}> {post?.primary_post?.profile_type && post.primary_post.profile_type.charAt(0).toUpperCase() + post.primary_post.profile_type.slice(1) || dispatch(getCompanyTypeByName(bEngageData?.created_by?.user_type))}</Typography>  */}
                                    <Box className={SocialMediaCSS.f} gap={1}>
                                        {/* <img src={PublicIcon} alt='Connections' /> */}
                                        {!isEvent && !isBEngage && <LightTooltip title={postTypeTitle} placement='top' arrow>
                                            <img src={postTypeImage} alt='Connections' className={SocialMediaCSS.ProfileTypePost} />
                                        </LightTooltip>}
                                        <Typography className={SocialMediaCSS.postTypeReshare}>{post?.primary_post?.post_user_details?.connections || bEngageData?.created_by?.connection_count || eventData?.company_details?.connections} Connections</Typography>
                                    </Box></Box>
                            </Box>
                        </Box>
                        {!post?.shared_with_thoughts &&
                            <PostMedia post={post} eventData={eventData} isEvent={isEvent} bEngageData={bEngageData} isBEngage={isBEngage} />}
                        <Box className={SocialMediaCSS.postContentBox}>
                            {!isEvent && !isBEngage ? <Typography variant='body2' className={SocialMediaCSS.textContent}>
                                <p dangerouslySetInnerHTML={{ __html: postBody }} />
                            </Typography> :
                                isBEngage ? <Box p={3} sx={{ width: '-webkit-fill-available' }}>
                                    <Box class={SocialMediaCSS.eventShareBox}>
                                        <span class={SocialMediaCSS.eventShareThematicTypo}>{bEngageData?.thematic_area_id ? dispatch(getThematicNameById(bEngageData?.thematic_area_id)) : 'N/A'}</span>
                                        <span class={SocialMediaCSS.eventShareDateTypo}>{formatDateString2(bEngageData?.start_date)}</span>
                                    </Box>
                                    <h5 class={SocialMediaCSS.eventShareTitle}>
                                        {bEngageData?.title}
                                    </h5>
                                    <p class={SocialMediaCSS.eventSharedisc}>
                                        {bEngageData?.body}
                                    </p>
                                    <Box class={SocialMediaCSS.eventShareAddressBox}>
                                        <Box class={SocialMediaCSS.eventShareAddress}>
                                            {bEngageData?.event_mode === "In-Person" ?
                                                <>
                                                    <span>
                                                        In-Person
                                                    </span>
                                                    <span>
                                                        • {`${bEngageData?.conference_address_details?.address}, ${dispatch(getDistrictNameByIds(bEngageData?.conference_address_details?.district_id))}, ${dispatch(getStateNameById(bEngageData?.conference_address_details?.state_id))} - ${bEngageData?.conference_address_details?.pincode}`}
                                                    </span>
                                                </> :
                                                <span>
                                                    Online
                                                </span>
                                            }
                                            {/* <span>
                                            • {bEngageData?.actual_volunteer} Attandance
                                        </span> */}
                                        </Box>
                                    </Box>
                                </Box> :
                                    <Box p={3} sx={{ width: '-webkit-fill-available' }}>
                                        <Box class={SocialMediaCSS.eventShareBox}>
                                            <span class={SocialMediaCSS.eventShareThematicTypo}>{eventData?.thematic_area_id ? dispatch(getThematicNameById(eventData?.thematic_area_id)) : 'N/A'}</span>
                                            <span class={SocialMediaCSS.eventShareDateTypo}>{formatDateString2(eventData?.start_date)}</span>
                                        </Box>
                                        <h5 class={SocialMediaCSS.eventShareTitle}>
                                            {eventData?.name}
                                        </h5>
                                        <p class={SocialMediaCSS.eventSharedisc}>
                                            {eventData?.description}
                                        </p>
                                        <Box class={SocialMediaCSS.eventShareAddressBox}>
                                            <Box class={SocialMediaCSS.eventShareAddress}>
                                                {eventData?.event_mode_id === 1 ?
                                                    <>
                                                        <span>
                                                            In-Person
                                                        </span>
                                                        <span>
                                                            • {`${eventData.address}, ${dispatch(getDistrictNameByIds(eventData?.district_id))}, ${dispatch(getStateNameById(eventData?.state_id))} - ${eventData.pin_code}`}
                                                        </span>
                                                    </> :
                                                    <span>
                                                        Online
                                                    </span>
                                                }
                                                <span>
                                                    • {eventData?.actual_volunteer} Attandance
                                                </span>
                                            </Box>
                                        </Box>
                                    </Box>
                            }
                        </Box>
                    </Box>
                    <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between} ${SocialMediaCSS.ai_center}`} pt={2}>
                        <Box className={`${SocialMediaCSS.createOption} ${SocialMediaCSS.ai_center}`}>
                            <Tooltip title="Tag" arrow placement="top"><img src={tag} className={SocialMediaCSS.cursor} style={{ verticalAlign: 'middle' }} onClick={handleTagClick} /></Tooltip>
                            <Tooltip title="Activity" arrow placement="top"><img src={activity} className={SocialMediaCSS.cursor} onClick={handleActivityClick} /></Tooltip>
                        </Box>
                        < CommonButton2
                            label="| Reshare"
                            name="reshare"
                            variant="contained"
                            startIcon={<img src={share} />}
                            type="button"
                            onClick={() => handleReshare(post?.gid)}
                            // onClick={() => handleResharePost(post?.gid || eventData?.gid)}
                            // disabled={!form?.postReshareform?.values || !form?.postReshareform?.values?.description}
                            disabled={ master.pendingCall || (!form?.postReshareform?.values || !form?.postReshareform?.values?.description|| textExceeded )}
                            style={{
                                padding: '6px 16px', fontSize: '14px', height: '32px', fontWeight: '600', boxShadow: 'none',
                                background: 'var(--Button-BG-Blue, linear-gradient(180deg, #0A97D9 0%, #00689D 100%', color: '#FFF',
                                opacity: !form?.postReshareform?.values ||textExceeded|| !form?.postReshareform?.values?.description ? 0.5 : 1
                            }}
                            loading={master.pendingCall}
                        />
                        {/* <Box >
                            <CommonButton2
                                label="Next"
                                name="Next"
                                variant="outlined"
                                type="button"
                                onClick={() => handleNextClick()}
                                style={{ padding: '6px 32px', fontSize: '14px', height: '32px', fontWeight: '600', boxShadow: 'none' }}
                                disabled={textExceeded || (!form?.postReshareform?.values || !form?.postReshareform?.values?.description)}
                            />
                        </Box> */}
                    </Box>
                </Box>

                <Popover
                    id={postMorePopover}
                    open={opens}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    className={SocialMediaCSS.popoverPostMore}
                >
                    <Box className={SocialMediaCSS.postMoreOptionBox} onClick={handlePopoverClose}>
                        {master?.postTypes?.map((data, index) => (
                            <Box className={SocialMediaCSS.postMoreOptionItem} onClick={() => handlePostTypeSelect(data)}>
                                <img src={data.image} className={SocialMediaCSS.h14} />
                                <Box className={SocialMediaCSS.postMoreOptionItemDetail}>
                                    <Typography variant='body2' className={SocialMediaCSS.postMoreOptionItemName}>
                                        {data.value}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Popover>
            </Dialog>

            {postDiscardOpen &&
                <DiscardAlert
                    open={postDiscardOpen}
                    onClose={handleDiscardDialogClose}
                    handleCancel={handleCancelClick}
                    title="Discard draft"
                    Content1="Your post isn't finished yet. Are you sure you want to exit and discard your draft?"
                    ButtonText2="Discard"
                    ButtonText1="Continue"
                />}
        </>
    );
};