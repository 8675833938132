import React, { useEffect, useRef, useState } from 'react';
import SocialMediaCSS from '../../../SocialMediaCSS/SocialMedia.module.css';
import SocialMediaEventCSS from '../../../SocialMediaCSS/SocialMediaEvent.module.css';
import { Box, CircularProgress, Fade, Grid, IconButton, Tooltip, Typography } from '@mui/material';

import InpersonIcon from '../../../../../assets/icons/inPerson.svg';
import OnlineIcon from '../../../../../assets/icons/online.svg';
import ThematicIcon from '../../../../../assets/icons/Thematic.svg';


// import EventLisitingActionBtn from './EventLisitingActionBtn';
// import EventLisitingLocationIcon from './EventLisitingLocationIcon';
import { getDayFromDate, getMonthFromDate, getStateNameById } from '../../../../../common/utils';
import { STATUS_CODE } from '../../../../../common/constants';
import CommonInitials from '../../../../../common/components/CommonInitials';

import _ from 'lodash-es';
import { getCategoryEventsList, getSortByList } from '../../Events/actions';
import EventTypeLabel from '../../Events/Subcomponents/EventTypeLabel';
import { getBengageList } from '../../../actions';
import { getDistrictNameById } from '../../../../Profile/actions';
import EventLisitingLocationIcon from '../../Events/Subcomponents/EventLisitingLocationIcon';
import CheckIcon from '../../../../../assets/icons/check.svg';

const BengageListCard = ({ bEngageList, handleEventDrawerOpen, engageTotalCount, eventIsLoad, totalEventCount, dispatch, eventSortBy, eventCategory, offset }) => {
    const [loading, setLoading] = useState(false);
    const filterValues = useRef([]);
    const categoryValues = useRef(null);
    let totalCount = useRef(0);
    let totalEvents = useRef(0);
    let isFecthing = false;

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        if (bEngageList) {
            totalCount.current = bEngageList.length;
            totalEvents.current = engageTotalCount;
        }
    }, [bEngageList, engageTotalCount])

    // useEffect(() => {
    //     if (eventSortBy) {
    //         filterValues.current = eventSortBy;
    //         offset.current = 10;
    //     }
    // }, [eventSortBy])
    // useEffect(() => {
    //     if (eventCategory) {
    //         categoryValues.current = eventCategory;
    //         offset.current = 10;
    //     }
    // }, [eventCategory])


    const handleScroll = () => {
        // Detect if user has scrolled to the bottom of the page
        if (isFecthing) return;
        if (totalCount.current < totalEvents.current) {
            console.log(totalCount.current, 'running')
            if (Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.scrollHeight) {
                // Fetch more data when user scrolls to the bottom
                try {
                    isFecthing = true;
                    offset.current = offset.current + 10;
                    if (totalCount.current) {
                        dispatch(getBengageList(offset.current, true, setLoading));
                    }
                } catch (error) {
                    isFecthing = false;
                }
                setTimeout(() => {
                    isFecthing = false;
                }, 2000);
            }
        } else {
            setLoading(false);
        }
    };
    return (
        <>
            {!eventIsLoad && !_.isEmpty(bEngageList) && bEngageList.map((event) => {
                const day = getDayFromDate(event?.created_at) || getDayFromDate(event?.start_date);
                const month = getMonthFromDate(event?.created_at) || getMonthFromDate(event?.start_date);
                const status = Object.values(STATUS_CODE).find(status => status.CODE === event?.status_code)?.STATUS_TEXT;
                return (
                    <Grid item xs={12} key={event.id}>
                        <Box className={SocialMediaCSS.EventListCard} onClick={() => handleEventDrawerOpen(event)}>
                            <Grid container>
                                <Grid item xs={12} md='auto'>
                                    <Box className={SocialMediaCSS.leftEventDate}>
                                        <Box className={SocialMediaCSS.leftEvDetails}>
                                            <Typography variant="h6" className={SocialMediaCSS.evDate}>{day}</Typography>
                                            <Typography variant="body1" className={SocialMediaCSS.evMonth}>{month}</Typography>
                                        </Box>
                                        <Box className={SocialMediaCSS.leftEvlabel}>
                                            <Typography variant='body4' className={SocialMediaCSS.leftEvlabelText}>{event?.status}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md className={SocialMediaCSS.eventListR} zeroMinWidth>
                                    <Grid container spacing={2}>
                                        <Grid item md={12} lg zeroMinWidth>
                                            <Box className={SocialMediaCSS.eventListRL}>
                                                <Box className={SocialMediaCSS.eventListMidRLT}>
                                                    <CommonInitials name={event?.created_by?.initials} bgColor={event?.created_by?.colour_code} divStyle={{ padding: '3px', maxHeight: '32px', maxWidth: '32px', minHeight: '32px', minWidth: '32px', borderRadius: '50%', boxShadow: '5px 5px 10px 0px rgba(170, 170, 204, 0.5), -5px -5px 10px 0px rgba(255, 255, 255, 1)', }} typoStyle={{ fontSize: '12px', lineHeight: '25px', }} avatarStyle={{ width: '32px', height: '32px', boxShadow: '5px 5px 10px 0px rgba(170, 170, 204, 0.5), -5px -5px 10px 0px rgba(255, 255, 255, 1)' }} src={`${event?.created_by?.profile_image}`} companyLogo={event?.created_by?.profile_image} avatar={true} />
                                                    {/* <Box className={SocialMediaCSS.eventCreatorLogo}>
                                                        <img src={event.companyLogo} alt='Event Creator Logo' className={SocialMediaCSS.h32} />
                                                    </Box> */}
                                                    <Typography variant="h6" className={SocialMediaCSS.eventListTitle}>
                                                        {event.title}
                                                    </Typography>
                                                </Box>
                                                <Box className={SocialMediaCSS.eventListMidRLB}>
                                                    <img src={event.event_mode === "Online" ? OnlineIcon : InpersonIcon} alt='Event Type' className={SocialMediaCSS.h20} />
                                                    <Typography variant="body2" className={SocialMediaCSS.eventType}>{event.event_mode}</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item md={12} lg='auto' className={SocialMediaCSS.eventListRR}>
                                            <Box className={SocialMediaCSS.eventListingOther}>
                                                <Box className={SocialMediaCSS.eventListingIconText}>
                                                    <img src={ThematicIcon} alt='Thematic Area' className={SocialMediaCSS.h16} />
                                                    {/* <Typography variant="body2" className={SocialMediaCSS.eventListingThematicText}>
                                                        {event.thematic_area}
                                                    </Typography> */}

                                                    <Tooltip
                                                        title={
                                                            <Box>
                                                                {event.thematic_area.map((name, index) => (
                                                                    <Box key={index} display="flex" alignItems="center">
                                                                        <img src={ThematicIcon} alt="Thematic" style={{ width: '13px', height: '13px', marginRight: '5px' }} />
                                                                        <Typography component="span" sx={{ marginLeft: '5px', fontSize: "11px" }}>
                                                                            {name}
                                                                        </Typography>
                                                                    </Box>
                                                                ))}
                                                            </Box>
                                                        }
                                                        placement="top"
                                                        arrow
                                                    >
                                                        <Box component="span">
                                                            <Typography variant="body2" className={SocialMediaCSS.eventListingThematicText} sx={{ display: "flex" }}>
                                                                {Array.isArray(event.thematic_area) ? (
                                                                    <>
                                                                        {/* Display the first thematic area */}
                                                                        {event.thematic_area[0]}

                                                                        {/* Show +N for the remaining items, if there are more than 1 */}
                                                                        {event.thematic_area.length > 1 && (
                                                                            <Box className={SocialMediaCSS.plustheme} sx={{ marginLeft: '8px' }}>
                                                                                <Typography className={SocialMediaCSS.plusText}>
                                                                                    +{event.thematic_area.length - 1}
                                                                                </Typography>
                                                                            </Box>

                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    event.thematic_area || 'N/A'
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    </Tooltip>
                                                </Box>
                                                <Box className={SocialMediaCSS.eventListingIconText}>
                                                    <EventLisitingLocationIcon view='listView' eventType={event.event_mode} location={event.conference_type} />
                                                    <Typography variant="body2" className={SocialMediaCSS.eventListingLocationText}>
                                                        {event?.event_mode === 'Online' ? event?.conference_type?.title : `${dispatch(getStateNameById(event?.conference_address_details?.state_id))}, ${dispatch(getDistrictNameById(event?.conference_address_details?.district_id))}`}
                                                    </Typography>
                                                </Box>

                                            </Box>
                                            <Box className={SocialMediaCSS.eventListingActionBox}>
                                                {event?.registered && <IconButton variant="contained" className={SocialMediaCSS.eventListingListAction} disableRipple disabled>
                                                    <img src={CheckIcon} alt='Download' className={SocialMediaCSS.h24} />
                                                </IconButton>}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                );
            })}
            {eventIsLoad && <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: '100%' }}>
                <Fade
                    in={eventIsLoad}
                    style={{
                        transitionDelay: eventIsLoad ? '100ms' : '0ms',
                    }}
                    unmountOnExit
                >
                    <Box className={SocialMediaEventCSS.loadingBox}>
                        <CircularProgress />
                        <Typography className={SocialMediaEventCSS.loadingText}>Loading...</Typography>
                    </Box>
                </Fade>
            </Box>}
            {loading && <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: '100%' }}>
                <Fade
                    in={loading}
                    style={{
                        transitionDelay: loading ? '100ms' : '0ms',
                    }}
                    unmountOnExit
                >
                    <Box className={SocialMediaEventCSS.loadingBox}>
                        <CircularProgress />
                        <Typography className={SocialMediaEventCSS.loadingText}>Loading...</Typography>
                    </Box>
                </Fade>
            </Box>}
            {_.isEmpty(bEngageList) && !eventIsLoad && <Box className={`${SocialMediaEventCSS.f} ${SocialMediaEventCSS.jc_center}`} sx={{ m: '230px 0px', width: '100%' }}>
                <Typography sx={{ lineHeight: '36px', fontSize: '30px', fontWeight: 500, color: '#666666' }}>No Conference yet, all conferences will be shown here.</Typography>
            </Box>
            }
        </>
    )
}

export default BengageListCard
