import React, { useEffect, useRef, useState } from 'react';
import SocialMediaCSS from '../../../SocialMediaCSS/SocialMedia.module.css';
import SocialMediaEventCSS from '../../../SocialMediaCSS/SocialMediaEvent.module.css';
import { Box, CircularProgress, Fade, Grid, Typography } from '@mui/material';
import EventTypeLabel from './EventTypeLabel';
import EventLisitingActionBtn from './EventLisitingActionBtn';

import ThematicIcon from '../../../../../assets/icons/Thematic.svg';
import CalendarIcon from '../../../../../assets/icons/calender.svg';
import InpersonIcon from '../../../../../assets/icons/inPerson.svg';
import OnlineIcon from '../../../../../assets/icons/online.svg';
import EventLisitingLocationIcon from './EventLisitingLocationIcon';
import _ from 'lodash-es';
import { formatDateMonth, getResponseDate } from '../../../../../common/utils';
import { STATUS_CODE } from '../../../../../common/constants';
import CommonInitials from '../../../../../common/components/CommonInitials';
import { getCategoryEventsList, getSortByList } from '../actions';

const EventGridCard = ({ eventList, handleEventDrawerOpen, eventIsLoad, totalEventCount, dispatch, eventSortBy, eventCategory, offset }) => {
    const [loading, setLoading] = useState(false);
    const filterValues = useRef([]);
    const categoryValues = useRef(null);
    let totalCount = useRef(0);
    let totalEvents = useRef(0);
    let isFecthing = false;

    useEffect(() => {
        // Add event listener when component mounts to detect scroll
        window.addEventListener('scroll', handleScroll);

        return () => {
            // Remove event listener when component unmounts
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        if (eventList) {
            totalCount.current = eventList.length;
            totalEvents.current = totalEventCount;
        }
    }, [eventList, totalEventCount])
    useEffect(() => {
        if (eventSortBy) {
            filterValues.current = eventSortBy;
            offset.current = 10;
        }
    }, [eventSortBy])
    useEffect(() => {
        if (eventCategory) {
            categoryValues.current = eventCategory;
            offset.current = 10;
        }
    }, [eventCategory])


    const handleScroll = () => {
        // Detect if user has scrolled to the bottom of the page
        if (isFecthing) return;
        if (totalCount.current < totalEvents.current) {
            // console.log(totalCount.current, 'running')
            if (Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.scrollHeight) {
                // Fetch more data when user scrolls to the bottom
                try {
                    isFecthing = true;
                    offset.current = offset.current + 10;
                    if (!_.isEmpty(categoryValues.current) && !_.isEmpty(filterValues.current)) {
                        dispatch(getSortByList(offset.current, true, setLoading, filterValues.current, categoryValues.current));
                    } else if (!_.isEmpty(filterValues.current)) {
                        dispatch(getSortByList(offset.current, true, setLoading, filterValues.current));
                    } else if (!_.isEmpty(categoryValues.current)) {
                        dispatch(getCategoryEventsList(offset.current, true, setLoading, categoryValues.current));
                    }
                } catch (error) {
                    isFecthing = false;
                }
                setTimeout(() => {
                    isFecthing = false;
                }, 2000);
            }
        } else {
            setLoading(false);
        }
    };
    return (
        <>
            {!eventIsLoad && !_.isEmpty(eventList) && eventList.map((event) => {
                const date = getResponseDate(event.start_date);
                const status = Object.values(STATUS_CODE).find(status => status?.CODE === event?.status_code)?.STATUS_TEXT
                return (
                    <>
                        <Grid item xs={12} sm={6} lg={4} xl={3} zIndex={1}>
                            <Box className={SocialMediaCSS.eventGridCard} onClick={() => handleEventDrawerOpen(event?.gid)}>
                                <Box className={SocialMediaCSS.eventGridCardTop}>
                                    <Box className={SocialMediaCSS.evFeatureImgBox}>
                                        <img src={event.cover_photo} alt='Event Feature Image' className={SocialMediaCSS.evFeatureImg} />
                                    </Box>
                                    <EventTypeLabel type={event.event_type} />
                                    <Box className={SocialMediaCSS.eventListingGridActionBox}>
                                        <EventLisitingActionBtn view='gridView' status={event.event_status} certificateIssued={event.certificate_issued} />
                                    </Box>
                                </Box>
                                <Box className={SocialMediaCSS.eventGridCardBottom}>
                                    <Box className={SocialMediaCSS.eventGridRowTop}>
                                        <CommonInitials name={event?.initials} bgColor={event?.colour_code} divStyle={{ padding: '3px', maxHeight: '32px', maxWidth: '32px', minHeight: '32px', minWidth: '32px', borderRadius: '50%', boxShadow: '5px 5px 10px 0px rgba(170, 170, 204, 0.5), -5px -5px 10px 0px rgba(255, 255, 255, 1)', }} typoStyle={{ fontSize: '12px', lineHeight: '25px', }} avatarStyle={{ width: '32px', height: '32px', boxShadow: '5px 5px 10px 0px rgba(170, 170, 204, 0.5), -5px -5px 10px 0px rgba(255, 255, 255, 1)' }} src={`${event?.logo}`} companyLogo={event?.logo} avatar={true} />
                                        {/* <Box className={SocialMediaCSS.eventCreatorLogo}>
                                            <img src={event.companyLogo} alt='Event Creator Logo' className={SocialMediaCSS.h32} />
                                        </Box> */}
                                        <Typography variant="body1" className={SocialMediaCSS.eventGridTitle}>
                                            {event.event_name}
                                        </Typography>
                                    </Box>
                                    <Box className={SocialMediaCSS.eventGridRowMiddle}>
                                        <img src={ThematicIcon} alt='Thematic Area' className={SocialMediaCSS.h20} />
                                        <Typography variant="body2" className={SocialMediaCSS.eventListingThematicText}>
                                            {event.thematic_area}
                                        </Typography>
                                    </Box>
                                    <Box className={SocialMediaCSS.eventGridRowMiddle}>
                                        <img src={CalendarIcon} alt='Calender' className={SocialMediaCSS.h20} />
                                        <Typography variant="body2" className={SocialMediaCSS.eventListingLocationText}>
                                            {date}
                                        </Typography>
                                    </Box>
                                    <Box className={SocialMediaCSS.eventGridRowMiddle}>
                                        <EventLisitingLocationIcon view='gridView' eventType={event.event_mode} location={event?.conference_platform} />
                                        <Typography variant="body2" className={SocialMediaCSS.eventListingLocationText}>
                                            {event?.conference_platform?.title || `${event.district}, ${event.state}`}
                                        </Typography>
                                    </Box>
                                    <Box className={SocialMediaCSS.eventGridRowBottom}>
                                        <Box className={SocialMediaCSS.eventModeType}>
                                            <img src={event.event_mode === "Online" ? OnlineIcon : InpersonIcon} alt='Event Type' className={SocialMediaCSS.h20} />
                                            <Typography variant="body2" className={SocialMediaCSS.eventType}>{event.event_mode}</Typography>
                                        </Box>
                                        <Box className={SocialMediaCSS.eventGridStatus}>
                                            <Typography variant='body4' className={SocialMediaCSS.eventGridStatusText} sx={{ textTransform: 'capitalize' }}>{event?.event_status}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className={SocialMediaCSS.bottomBoxShadow}></Box>
                                </Box>
                            </Box>
                        </Grid>
                    </>
                );
            })}
            {eventIsLoad && <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: '100%' }}>
                <Fade
                    in={eventIsLoad}
                    style={{
                        transitionDelay: eventIsLoad ? '100ms' : '0ms',
                    }}
                    unmountOnExit
                >
                    <Box className={SocialMediaEventCSS.loadingBox}>
                        <CircularProgress />
                        <Typography className={SocialMediaEventCSS.loadingText}>Loading...</Typography>
                    </Box>
                </Fade>
            </Box>}
            {loading && <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: '100%' }}>
                <Fade
                    in={loading}
                    style={{
                        transitionDelay: loading ? '100ms' : '0ms',
                    }}
                    unmountOnExit
                >
                    <Box className={SocialMediaEventCSS.loadingBox}>
                        <CircularProgress />
                        <Typography className={SocialMediaEventCSS.loadingText}>Loading...</Typography>
                    </Box>
                </Fade>
            </Box>}
            {_.isEmpty(eventList) && !eventIsLoad && <Box className={`${SocialMediaEventCSS.f} ${SocialMediaEventCSS.jc_center}`} sx={{ m: '230px 0px', width: '100%' }}>
                <Typography sx={{ lineHeight: '36px', fontSize: '30px', fontWeight: 500, color: '#666666' }}>No Events yet, all Events will be shown here.</Typography>
            </Box>
            }
        </>
    )
}

export default EventGridCard
