const domain = process.env.REACT_APP_RAILSURL;
const apiDomain = process.env.REACT_APP_BASEURL;
const apiVersion = "v1";
const registrationURL = `${apiDomain}/api/${apiVersion}/auth/`;
const registrationNewURL = `${domain}/api/web/registrations/`;
const loginNewURL = `${domain}/api/web/sessions/`;
const masterURL = `${domain}/api/masters/`;
const commonURL = `${domain}/api/web/`
const profileURL = `${domain}/api/web/profiles/`;
const eventURL = `${domain}/api/web/social_events/`;
const volunteerURL = `${domain}/social-media/api/volunteer/`;
const proposalURL = `${apiDomain}/api/${apiVersion}/proposal/`;
const rfpURL = `${apiDomain}/api/${apiVersion}/rfp/`;
const managementurl = `${domain}/social-media/api/volunteer/`;
const assigneeURL = `${apiDomain}/api/${apiVersion}/`;
const socialMediaURL = `${domain}/api/web`;

/* new apis for registration flow */
export const REGISTRATION_TEMP = `${registrationNewURL}new`;
export const RESEND_OTP = `${registrationNewURL}resend_otp`;
export const SIGNUP_URL = `${registrationNewURL}verification`;
export const DEMO = `${registrationNewURL}/demo_request`;
export const LOGIN_URL = `${loginNewURL}login`;
export const FORGOT_PASSWORD = `${loginNewURL}forget_password`;
export const RESET_PASSWORD = `${loginNewURL}reset_password`;
export const TOKEN_DATA = `${loginNewURL}token_data`;
// export const ACTIVATE_USER = `${volunteerURL}user_managements/activate_user`;
export const UPGRADE_ACCOUNT = `${commonURL}subscriptions/upgrade_account`;
export const SIGNOUT_SESSION = `${commonURL}sessions/sign_out`;

// master Urls
export const GET_COMPANY_TYPES = `${masterURL}company_types`;
export const GET_PROFILE_TYPES = `${masterURL}profile_types`;
export const BRAND_LIST = `${masterURL}partner_company`;
export const STATE_URL = `${masterURL}states`;
export const COUNTRY_URL = `${masterURL}country`;
export const ANNEXURE_URL = `${masterURL}annexures_types`;
export const THEMATIC_AREAS = `${masterURL}thematic_areas`;
export const SCHEDULE7 = `${masterURL}schedule_seven`;
export const DISTRICT_URL = `${masterURL}districts`;
export const LANGUAGE = `${masterURL}language_type`;
export const ADDRESS_TYPE = `${masterURL}address_type`;
export const REDEEM_CODE = `${registrationURL}redeem-code`;
export const BENEFICIARY_TYPES = `${masterURL}beneficiary_types`;
export const SDGGUIDELINES_URL = `${masterURL}sdg_guideline`;
export const SDGGUIDELINESMAPPING = `${masterURL}get-sdg-guidelines-mapping`;
export const MASTER_URL = `${masterURL}get-master-data`;
export const MODULE_TYPE_URL = `${masterURL}module_type`;
export const EVENT_MODE = `${masterURL}get-event-mode`;
export const EVENT_TYPE = `${masterURL}get-event-type`;
export const EVENT_AREA = `${masterURL}get-event-area`;
export const ORG_EXPERIENCE = `${masterURL}get-org-experiences`
export const EVENT_BENEFICIARY = `${masterURL}beneficiary_types`;
export const EVENT_TRANSPORTATION_MODE = `${masterURL}transportation_mode`;
export const MANAGEMENT_TYPE = `${masterURL}management_type`;
export const NOTIFICATION = `${commonURL}dashboard/notification_count`;
export const RESET_NOTIFICATION = `${volunteerURL}/dashboard/notification_reset`
export const MESSAGE = `${volunteerURL}dashboard/message_count`
export const BUSINESS_SUIT_LIST = `${commonURL}subscriptions`
export const SUPPORT_REQ = `${commonURL}support_requests`
export const RFP_QUESTIONS = `${masterURL}rfp_questionnaire_list`;
export const NOMINATION_TYPE = `${masterURL}nomination_type`;

//Profile API URLs
export const PROFILE_PHOTO = `${profileURL}update_photo`;
export const ABOUTUS_URL = `${profileURL}`;
export const ANALYTICS_URL = `${profileURL}/analytics`;
export const SUGGESTED_USERS_URL = `${profileURL}/user_recommendation`;
export const PREVIEW_URL = `${profileURL}preview`;
export const WORKS_IN_URL = `${commonURL}profile_works`;
export const ADDRESS_URL = `${commonURL}company_addresses`;
export const CONNECTION_REQ_URL = `${commonURL}connection_requests`;
export const SEND_FEEDBACK_URL = `${commonURL}company_projects/feedback`;
export const FOLLOW_URL = `${commonURL}following/follow_user`;
export const UNFOLLOW_URL = `${commonURL}following/unfollow_user`;
export const ADDRESS_PRIVACY = `${commonURL}company_addresses/update_privacy`;
export const DELETE_WORKS_IN = `${commonURL}profile_works/delete`;
export const VOLUNTEER_INTEREST_URL = `${profileURL}update_interest`;
export const PROJECT_URL = `${commonURL}company_projects`;
export const PROJECT_DOCUMENT_DOWNLOAD_URL = `${commonURL}company_projects/download_documents`;
export const DELETE_FILE = `${profileURL}deletefile`;
export const ANNEXURE_UPLOAD = `${commonURL}annexures`;
export const CATCHMENT_AREA = `${commonURL}catchment_areas`;
export const DELETE_ANNEXURE = `${profileURL}deletecomannexure`;
export const COMPLIANCE_URL = `${commonURL}compliances`;
export const DELETE_ADDRESS = `${profileURL}deleteaddress`;
export const DELETE_PROJECT = `${profileURL}deletepastproject`;
export const COMPLETE_PROFILE = `${profileURL}getcompanyprofiles`;
export const SEND_CHANGE_OTP = `${profileURL}send_otp`;
export const CHANGE_PASSWORD = `${profileURL}change_password`;
// export const UPGRADE_ACCOUNT = `${profileURL}changeUserType`;

//Event URLs
export const CREATE_EVENT = eventURL;
export const VOLUNTEER_COUNT = `${commonURL}volunteer_managements/volunteers_count`
export const UPDATE_EVENT = `${eventURL}`;
// export const BRAND_LIST = `${eventURL}/brand_list`;
export const DELETE_EVENT = `${eventURL}`; //`${eventURL}delete_event`;
export const EVENT_UPLOAD = `${eventURL}fileupload`;
export const DELETE_UPLOAD = `${eventURL}deletefile`;
export const CANCEL_EVENT = `${eventURL}cancel?`;
export const PUBLISH_EVENT = `${eventURL}changestatus`;
export const EVENT_STATUS = `${eventURL}change_status?`;
export const EVENT_MARK_FAVOURITE = `${eventURL}mark_fav?`;
export const UPDATE_OUTCOME = `${commonURL}event_outcomes`;
export const EVENT_PREVIEW = `${eventURL}`;
export const EVENT_LIST = `${eventURL}`;
export const VOLUNTEER_ACCEPTED_LIST = `${commonURL}event_volunteers`;
export const RATINGS = `${commonURL}ratings`;
export const INSIGHT = `${eventURL}dashboard`;
export const MY_EVENT_LIST = `${eventURL}my-eventlist`;
export const JOIN_EVENT = `${commonURL}event_volunteers/join`;
export const ACCEPT_DECLINE = `${commonURL}event_volunteers/accept_decline`;
export const OPT_OUT_EVENT = `${commonURL}event_volunteers/optout`;
export const EVENT_LIKE = `${commonURL}event_volunteers/like`;
export const EVENT_DELETE_LIKE = `${commonURL}event_volunteers/unlike`;
export const EVENT_BOOKMARK = `${commonURL}bookmarks`;
export const EVENT_DELETE_BOOKMARK = `${commonURL}bookmarks`;
export const SHARE_EVENT = `${commonURL}event_volunteers/share_event`;
export const SHARE_RATING = `${commonURL}ratings`;
export const VOLUNTEER_LIST = `${commonURL}event_volunteers`;
export const MANUALATTENDANCE = `${commonURL}event_volunteers/mark_attendence`;
export const RESEND_VOLUNTEER_EMAIL = `${commonURL}/volunteer_managements/resend_mail`;
export const SOCIAL_OUTCOME = `${eventURL}social-outcome-event`;
export const ISSUE_EVENT_CERTIFICATE = `${eventURL}issue-certificate`;
export const ADD_VOLUNTEER = `${commonURL}volunteer_managements`;
export const UPLOAD_CSV = `${commonURL}volunteer_managements/import_csv`;
export const USER_DETAIL = `${volunteerURL}volunteer_managements/volunteer_list`;
export const GET_MY_EVENTS = `${eventURL}my-eventlist`;
export const GET_VOLUNTEERS = `${commonURL}volunteer_managements`;
export const DELETE_VOLUNTEER = `${commonURL}volunteer_managements/delete_volunteer`;
export const VOLUNTEER_ACCEPT_DENY = `${volunteerURL}volunteer_managements/accept_deny`;
export const CERTIFICATE = `${commonURL}event_certificates`;
export const CERTIFICATE_POST = `${eventURL}save_certificates`;
export const ISSUE_CERTIFICATE = `${commonURL}event_certificates`;
export const WITHDRAW_INVITE = `${volunteerURL}/volunteer_managements/withdraw`;
export const DOWNLOAD_CERTIFICATE = `${commonURL}event_certificates/download`;
export const ASSIGN_EVENT_USER = `${commonURL}event_assignments`;
export const UNASSIGN_USER_COMMENTS = `${commonURL}event_comments`;
export const UNASSIGN_EVENT_USER = `${commonURL}event_assignments/unassign`;
export const EVENT_LEADERBOARD = `${eventURL}leaderboard`;
export const CANCEL_EVENT_FEEDBACK_LIST = `${commonURL}event_comments`;
export const CANCEL_EVENT_FEEDBACK = `${commonURL}social_events/feedback`;
export const CANCEL_EVENT_REQUEST = `${volunteerURL}social_events/event_cancel_request`;
export const APPROVAL_REQUEST = `${commonURL}event_publish_requests`;
export const PUBLISH_MESSAGE = `${commonURL}event_publish_requests`;
export const EVENT_ASSIGNEE_LIST = `${commonURL}event_assignments/event_assignee`;

//proposal urls
export const CREATE_PROPOSAL = `${proposalURL}createproposal`;
export const UPDATE_PROPOSAL = `${proposalURL}updateproposal`;
export const ADD_SECTION = `${proposalURL}addsection`;
export const DELETE_SECTION = `${proposalURL}deletesection`;
export const ADD_COVER_LETTER = `${proposalURL}coverletter`;
export const ADD_TABLE = `${proposalURL}addtabledata`;
export const UPDATE_TABLE = `${proposalURL}updatetabledata`;
export const DELETE_TABLE = `${proposalURL}deletetabledata`;
export const UPLOAD_PROPOSAL = `${proposalURL}fileupload`;
export const DELETE_PROPOSAL_FILE = `${proposalURL}deletefile`;
export const SUGGESTED_BY = `${proposalURL}suggestedby`;
export const ADD_SIDEBAR = `${proposalURL}addcolumns`;
export const MY_NETWORKS = `${proposalURL}mynetwork`;
export const REMARK = `${proposalURL}update-Remark`;
export const SEND_PROPOSAL = `${proposalURL}sendproposal`;
export const PROPOSAL_LIST = `${proposalURL}getproposallist`;
export const PROPOSAL_PREVIEW = `${proposalURL}proposalpreview`;
export const PROPOSAL_RESPONSE = `${proposalURL}rating-comment`;
export const PROPOSAL_RESPONSE_LIST = `${proposalURL}get-one-proposalResponse`;
export const MARK_FAVOURITE = `${proposalURL}markfavourite`;
export const MARK_READ = `${proposalURL}markread`;
export const PROPOSAL_INSIGHTS = `${proposalURL}insight`;
export const PROPOSAL_SENT_INSIGHTS = `${proposalURL}proposal-insightData`;
export const DELETE_PROPOSAL = `${proposalURL}delete-proposal`;
export const GET_PROPOSAL_MENU = `${proposalURL}getallcolumns`;
export const SEND_MESSAGES = `${proposalURL}send-messages`;
export const CHAT_BRAND_LIST = `${proposalURL}dropdownProject`;
export const FETCH_CHAT_MSG = `${proposalURL}chatMessages`;
export const FETCH_ASSIGNEE_LIST = `${assigneeURL}profile/assigneelist`;
export const ASSIGN_USER = `${proposalURL}assign-user`;
export const UNASSIGN_USER = `${proposalURL}unassign-user`;
export const GET_UNASSIGN_USER_COMMENTS = `${proposalURL}get-unassigned-users`;
export const ASSIGN_TO_ME = `${proposalURL}assign-to-me`;

//RFP api URLs
export const CREATE_FRP = `${rfpURL}createrfp`;
export const UPDATE_FRP = `${rfpURL}updaterfp`;
export const ADD_DETAILS_FRP = `${rfpURL}updaterfpdetails`;
export const GET_SCREENING_QUESTIONS = `${masterURL}get-screening-question`;
export const SUBMIT_FILTER_PREFERENCE = `${rfpURL}rfpfilter`;
export const UPLOAD_RFP_FILE = `${rfpURL}fileupload`;
export const SEND_RFP = `${rfpURL}sendrfp`;
export const DELETE_RFP = `${rfpURL}delete-rfp`;
export const DELETE_SAVED_RFP = `${rfpURL}deleteSavedRfp`;
export const PUBLISH_RFP = `${rfpURL}update-sentRfpflag`;
export const RFP_RESPONSE_BY_ID = `${rfpURL}rfpresponses`;
export const RFP_RATING_COMMENT = `${rfpURL}rating-comment`;
export const RFP_MARK_READ = `${rfpURL}markread`;
export const RFP_MAKE_FAVROUTE = `${rfpURL}markfavourite`;
export const RFP_INSIGHT = `${rfpURL}insight`;
export const GET_RFP_LIST = `${rfpURL}getrfplist`;
export const GET_RFP_PREVIEW_BY_ID = `${rfpURL}rfp-preview/`;
export const MAKE_FAVROUTE_RFP = `${rfpURL}markfavourite`;
export const DELETE_FILE_RFP = `${rfpURL}deletefile`;
export const RATING_AND_COMMENT = `${rfpURL}rating-comment`;
export const MARK_READ_RFP = `${rfpURL}markread`;
export const SAVE_RFP_RESPONSE = `${rfpURL}save-rfp-response`;
export const RFP_DATE_UPDATE = `${rfpURL}updaterfpdate`;
export const RFP_RESPONSE_LIST = `${rfpURL}responses-list`;
export const RFP_RESPONSE_DETAILS = `${rfpURL}response-details`;
export const RFP_NETWORKS = `${rfpURL}mynetwork`;
export const REQUEST_PROPOSAL = `${rfpURL}update-ProposalRequested`;
export const GET_ASSIGNEE_LIST = `${assigneeURL}profile/assigneelist`;
export const RFP_ASSIGN_USER = `${rfpURL}assign-user`;
export const RFP_UNASSIGN_USER = `${rfpURL}unassign-user`;
export const RFP_ASSIGN_TO_ME = `${rfpURL}assign-to-me`;
export const RFP_UNASSIGN_USER_COMMENTS = `${rfpURL}get-unassigned-users`;
// RFP new Urls
export const RFP_GLIMPSE = `${commonURL}proposal_requests`;
export const RFP_DASHBOARD_LIST = `${commonURL}proposal_requests`;
export const RFP_PREVIEW_SHOW = `${commonURL}proposal_requests`;
export const RFP_ASSIGNEE_LIST_NEW = `${commonURL}rfp_assignments/assignee_list`;
export const ASSIGN_USER_NEW = `${commonURL}rfp_assignments/assign_user`;
export const UNASSIGN_USER_NEW = `${commonURL}rfp_assignments/unassign_user`;
export const ASSIGN_TO_ME_NEW = `${commonURL}rfp_assignments/assign_to_self`;
export const ASSIGNEE_COMMENTS = `${commonURL}rfp_assignments/proposal_request_comments`;
export const MARK_FAVOURITE_NEW = `${commonURL}proposal_requests`;
export const RFP_COMMON_URL = `${commonURL}proposal_requests`;
export const RFP_DASHBOARD_INSIGHTS = `${commonURL}proposal_requests/dashboard`;
export const RESPONSE_PREVIEW = `${commonURL}rfp_responses/preview_response`;
export const RFP_CHILD_RESPONSES = `${commonURL}rfp_responses`;


// User Management
export const GET_USERS = `${commonURL}user_managements`;
export const CREATE_USERS = `${commonURL}user_managements`;
export const SUSPEND_USERS = `${commonURL}user_managements/suspend`;
export const PERMISSIONS = `${commonURL}user_managements/module_permission`;
export const RESEND_INVITE = `${commonURL}user_managements/resend_invite`;
export const ACTIVATE_USER = `${commonURL}user_managements/activate_user`;


//Social Media API URLs
export const CONNECTION_TYPES = `${masterURL}connection_types`;
export const REQUEST_TYPES = `${masterURL}request_type`;
export const GRIEVANCE = `${masterURL}dispute_type`;
export const POST_REACTION = `${masterURL}reaction_types`;
export const POST_ACTIVITY = `${masterURL}activity_type`;
export const MENTIONS = `${masterURL}mentions`;
export const HASHTAGS = `${masterURL}hashtags`;
export const POST_TYPES = `${masterURL}post_type`;
export const TAG_MENTIONS = `${masterURL}tag_peoples`;
export const CONNECTION_REQ = `${commonURL}connection_requests`;
export const INDIAN_CSR = `${commonURL}social_impacts`;
export const INDIAN_CSR_PREVIEW = `${commonURL}social_impacts`;
export const EVENTS_LIST = `${commonURL}dashboard/event_list`;
export const MY_DATABASE = `${commonURL}community/community_database_count`;
export const COMMUNITY_COUNT = `${commonURL}community/community_count`;
export const MY_INIVITATIONS = `${commonURL}community/manage_invitations`;
export const MY_SUGGESTIONS = `${commonURL}community/recommendations`;
export const SUGGESTIONS = `${commonURL}recommendations`;
export const CONNECTION_USER_COUNT = `${commonURL}connections/connection_user_category`;
export const SUB_CONNECTION_USER = `${commonURL}connections/sub_type_connections`;
export const CONNECTION_RECEIVED = `${commonURL}connection_requests/received_requests`;
export const CONNECTION_SENT = `${commonURL}connection_requests/sent_requests`;
export const CONNECTION_REMOVE = `${commonURL}connections/`;
export const VOL_INVITE = `${commonURL}volunteer_invitations`;
export const VOL_INVITE_ACCEPT = `${commonURL}volunteer_invitations/accept`;
export const VOL_INVITE_DENY = `${commonURL}volunteer_invitations/deny`;
export const SPEAKER_LIST = `${commonURL}social_media/speaker_invitations`;
export const SPONSOR_LIST = `${commonURL}social_media/sponsor_invitations`;
export const VOL_INVITE_WITHDRAW = `${commonURL}volunteer_invitations/withdraw`;
export const NOTIFICATION_LIST = `${commonURL}dashboard/notifications`;
export const READ_NOTIFICATION = `${commonURL}dashboard/read_notifications`;
export const DASHBOARD_POST = `${commonURL}dashboard`;
export const SEARCH_USER = `${commonURL}dashboard/search_user`;
export const CREATE_POST = `${commonURL}posts`;
export const FOLLOWING_LIST = `${commonURL}following/following`;
export const FOLLOWERS_LIST = `${commonURL}following/followers`;
export const POST_REACTION_CREATE = `${commonURL}reactions`;
export const POST_COMMENT_CREATE = `${commonURL}comments`;
export const FOLLOW = `${commonURL}following/follow_user`;
export const UNFOLLOW = `${commonURL}following/unfollow_user`;
export const BOOKMARK = `${commonURL}bookmarks`;
export const UNBOOKMARK = `${commonURL}bookmarks`;
export const ALLOW_NOTIFICATION = `${commonURL}notification_subscriptions`;
export const REMOVE_NOTIFICATION = `${commonURL}notification_subscriptions`;
export const DISPUTES = `${commonURL}disputes`;
export const POST_GRIEVANCES = `${commonURL}post_grievances`;
export const DELETE_POST = `${commonURL}posts`;
export const COMMENT_LIST = `${commonURL}comments`;
export const COMMENT_REPLY_LIST = `${commonURL}comment_replies`;
export const CONNECTION_LIST = `${commonURL}messages`;
export const SHARE_POST = `${commonURL}posts/share_post`;
export const POST_ALL_REACTION = `${commonURL}reactions`;
export const SOCIAL_EVENT_LIST = `${commonURL}social_media/social_events`;
export const SOCIAL_EVENT_PREVIEW = `${commonURL}social_media/social_events/`;
export const DELETE_POST_IMAGE = `${commonURL}posts/delete_image`;
export const DRAFT_POST = `${commonURL}posts/draft_post`;
export const SHARE_TOTAL = `${commonURL}posts/total_shares`;
export const DASHBOARD_HASHTAGS = `${commonURL}dashboard/hashtags`;
export const SOCIAL_USER_DETAIL = `${commonURL}following/user_details`;
export const SOCIAL_RFP_LIST = `${commonURL}social_media/proposal_requests`;
export const DASHBOARD_RFP_LIST = `${commonURL}dashboard/rfp_list`;
export const SOCIAL_RFP_PREVIEW = `${commonURL}social_media/proposal_requests`;
export const RFP_SAVE = `${commonURL}social_media/proposal_requests/save_to_rfp`;
export const SHARE_RFP = `${commonURL}social_media/proposal_requests/share_rfp`;
export const DASHBOARD_LIST_MAIN = `${commonURL}social_media/conferences/dashboard_list`;
export const PUBLIC_CONFERENCE = `${commonURL}social_media/conferences`;
export const BOOKMARK_SHOW = `${commonURL}/bookmarks`;
export const POST_SHOW = `${commonURL}posts`;
export const MY_POSTS = `${commonURL}dashboard/my_posts`;
export const My_SHARED_POST_LIST = `${commonURL}dashboard/users_list`;
export const FOLLOWHASHTAG = `${commonURL}social_media/hashtag_followers`;
export const UNFOLLOWHASHTAG = `${commonURL}social_media/hashtag_followers`;
/* bEngage API URLs */
export const CONFERENCES = `${commonURL}conferences`;
export const SHARE_CONFERENCES = `${commonURL}conferences/share_conference`;
export const CONFERENCES_LIST = `${commonURL}social_media/conferences`;
export const CONFERENCES_DASHBOARD = `${commonURL}social_media/conferences/dashboard`;
export const CONFERENCES_PREVIEW_SOCIAL = `${commonURL}social_media/conferences`;
export const NOMINATION = `${commonURL}social_media/conferences`;
export const SEARCH_EMAIL = `${commonURL}users/search_user_by_email`;
export const CONFERENCE_PARTICIPANTS = `${commonURL}social_media/conferences`;
export const CONFERENCE_DETAILS = `${commonURL}social_media/conferences`;
export const INVITATION = `${commonURL}conferences`;
export const UPDATE_USER_STATUS = `${commonURL}users/update_availability_status`;
